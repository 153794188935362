import { createSelector } from 'reselect'

export const selectDrill = (state) => state.drill

export const selectCategories = createSelector(
  [selectDrill],
  drill => drill.categories
)

export const selectDifficulty = createSelector(
  [selectDrill],
  drill => drill.difficulty
)

export const selectEquipment = createSelector(
  [selectDrill],
  drill => drill.equipment
)

export const selectSpace = createSelector(
  [selectDrill],
  drill => drill.space
)
