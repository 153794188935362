import React from 'react';
import '../../sass/main.scss';

const TabBar = ({selected,onSelect}) =>{
  return (
    <ul className='drills-tab-bar'>
      <li onClick={()=>onSelect(0)}className={selected == 0 && 'drill-tab-active'}>
        My Coach
      </li>
      <li onClick={()=>onSelect(1)}className={selected == 1 && 'drill-tab-active'}>
        Favorites
      </li>
        {
          /*
          <li onClick={()=>onSelect(2)}className={selected == 2 && 'drill-tab-active'}>
            My Drills
          </li>
          */
        }

    </ul>
  )
}

export default TabBar
