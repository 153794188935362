import React from 'react'
import '../../sass/main.scss'
import Moment from 'moment';

const DateCard = ({onClick,active,startDate,endDate}) =>{
  return (
    <div onClick={onClick} className={active ? 'card card-active' : 'card'}>
      <section className='card-bottom'>
        <p className='body1-black bold'>
          {Moment(startDate).format('MMMM D')} -
          {Moment(endDate).format('MMMM D')}
        </p>
      </section>
    </div>
  )
}

export default DateCard
