import React from 'react'
import '../../sass/main.scss'
import { FaVideo } from "react-icons/fa";


const VideoCard = ({url,onClick,title}) =>{
  if(url){
    return (
      <video onClick={onClick} key={url} className='video-card' controls >
         <source src={url} type="video/mp4"/>
      </video>
    )
  }else{
    return (
      <div onClick={onClick} className='video-card'>
        <div>
          <FaVideo size={30}/>
        </div>
        <h1 className='bold'>{title}</h1>
      </div>
    )
  }
}

export default VideoCard
