import React,{useEffect,useState} from 'react';
import '../../sass/main.scss';
import { useParams } from 'react-router-dom'
import { getDrill } from '../../services/drills'
import { createFavorite,deleteFavorite } from '../../services/favorites'
import Nav from '../../components/drills/navbar'
import Instruction from '../../components/drills/instruction'


const Drill = ({drillId}) =>{
  //===============================
  //STATE
  //===============================
  const [drill,setDrill] = useState({
    title:'',
    url:'',
    category:'',
    difficulty:'',
    instructions:[],
    setUpInstructions:[]
  })
  const [isFavorite,setIsFavorite] = useState(false)
  const [loading,setLoading] = useState(true)
  //===============================
  //HOOKS
  //===============================
  // const { drillId } = useParams()
  useEffect(()=>{
    const fetchData = async() =>{
      const response = await getDrill(drillId)
      setDrill(response.drill)
      setIsFavorite(response.isFavorite)
      setLoading(false)
    }
    fetchData()
  },[])

  //===============================
  //HANDLERS
  //===============================
  const onFavorite = async() =>{
    if(isFavorite){
      await deleteFavorite(drillId,'drill')
      setIsFavorite(false)
    }else{
      await createFavorite(drillId,'drill')
      setIsFavorite(true)
    }
  }

  const { title,url,category,equipment,difficulty,instructions,setUpInstructions,diagram } = drill
  return (
    <main className='section-container'>
      <Nav
        title={title}
        isFavorite={isFavorite}
        onFavorite={onFavorite}
        onBack={'/drills'}/>
      {
        !loading
          &&
          <section className='drill-container'>
            <div></div>
            <div style={{backgroundColor:'white',borderBottomRightRadius:10,borderBottomLeftRadius:10}}>
              <video style={{borderTopRightRadius:10,borderTopLeftRadius:10}} width="100%" controls >
                 <source src={url} type="video/mp4"/>
              </video>
              <div style={{padding:15}}>
                <h1 className='h1-black bold'>{title}</h1>
                <div className='tags u-margin-bottom-40'>
                  <span className='tag tag-lrg tag-green'>{difficulty}</span>
                  <span className='tag tag-lrg tag-purple'>{category}</span>
                </div>
                <h3 className='h3-black bold u-margin-bottom-10'>Instructions</h3>
                {
                  instructions.map((instruction,index)=>(
                    <Instruction key={index} number={index+1} text={instruction}/>
                  ))
                }
                <h3 className='h3-black bold u-margin-bottom-10 u-margin-top-40'>Equipment</h3>
                <div className='tags'>
                  {
                    equipment.map((item)=>(
                      <span key={item} className='tag tag-lrg btn-border'>{item}</span>
                    ))
                  }
                  <h3 className='h3-black bold u-margin-bottom-10 u-margin-top-40'>Set Up Instructions</h3>
                  {
                    setUpInstructions.map((instruction,index)=>(
                      <Instruction key={index} number={index+1} text={instruction}/>
                    ))
                  }
                </div>
                <h3 className='h3-black bold u-margin-bottom-10 u-margin-top-40'>Diagram</h3>
                <img style={{borderRadius:10}} src={diagram} width='100%'/>
              </div>
            </div>
            <div></div>
          </section>

      }
    </main>
  )
}

export default Drill
