import React,{useState,useEffect} from 'react'
import '../../sass/main.scss';
import Header from './header'
import Footer from './footer'
import Button from '../common/button'
import { toast } from 'react-toastify';
import Input from '../common/form-input'
import TextArea from '../common/form-textarea'

const AutomatedMessages = ({messages,onChange,onSave,onCancel}) =>{

  return(
    <div className='modal-large'>
      <Header
        title={'Automated Messages'}
        onClick={onCancel}/>
      <div className='modal-content modal-content-scrollable'>
        <TextArea
          rows={3}
          className='group u-margin-bottom-10'
          label={'When a user completes there initial onboarding questions'}
          type={'text'}
          placeholder={''}
          name={'onboarding'}
          value={messages.onboarding}
          handleChange={(event) => onChange(event.target.value,'onboarding')}/>
      </div>
      <Footer
        onSubmit={onSave}
        onCancel={onCancel}/>
    </div>
  )
}

export default AutomatedMessages
