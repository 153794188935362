import React, {useState} from 'react'
import '../../sass/main.scss'
import Header from './header'
import Footer from './footer-questions'
import GenerateSpaceQuestion from '../sessions/generate-space-question'
import GenerateCategoryQuestion from '../sessions/generate-category-question'
import GenerateDifficultyQuestion from '../sessions/generate-difficulty-question'
import GenerateDurationQuestion from '../sessions/generate-duration-question'

const GenerateSession = ({onSave,onCancel}) =>{
  const [space,setSpace] = useState(-1)
  const [equipment,setEquipment] = useState('')
  const [categories,setCategories] = useState([])
  const [duration,setDuration] = useState('')
  const [difficulty,setDifficulty] = useState('')
  const [step,setStep] = useState(1)

  const renderSection = () =>{
    if(step === 1){
      return (
        <GenerateSpaceQuestion
          selected={space}
          onSelect={(value)=>setSpace(value)}/>
      )
    }else if(step === 2){
      return (
        <GenerateCategoryQuestion
          space={space}
          selected={categories}
          onSelect={(value)=>selectCategories(value)}/>
      )
    }else if(step === 3){
      return (
        <GenerateDifficultyQuestion
          selected={difficulty}
          onSelect={(value)=>setDifficulty(value)}/>
      )
    }else if(step === 4){
      return (
        <GenerateDurationQuestion
          categories={categories.length}
          selected={duration}
          onSelect={(value)=>setDuration(value)}/>
      )
    }
  }

  const renderTitle = () =>{
    switch (step) {
      case 1:
        return 'Select a space'
      case 2:
        return 'Select up to 3 categories'
      case 3:
        return 'Select a Skill Level'
      case 4:
        return 'Select a duration'
      default:
        return 'Generte Workout'
    }
  }

  const onNext = () =>{
    if(step === 1 && space >= 0){
      setStep(2)
    }else if(step === 2 && categories.length > 0){
      setStep(3)
    }else if(step === 3 && difficulty){
      setStep(4)
    }else if(step === 4 && duration){
      onSave({space,categories,difficulty,duration})
    }
  }

  const selectCategories = (value) =>{
    let index = categories.indexOf(value)
    if(index === -1){
      if(categories.length < 3) setCategories([...categories,value])
    }else{
      let temp = [...categories]
      temp.splice(index,1)
      setCategories(temp)
    }
  }

  const onPrevious = () =>{
    if(step > 1){
      setStep(step - 1)
    }
  }

  return (
    <div className='modal-small'>
      <Header
        title={renderTitle()}
        onClick={onCancel}/>
      <div className='modal-content'>
        <div style={{display:'flex',marginBottom:20}}>
          <div style={{flex:1,height:12,borderRadius:30,backgroundColor:step > 0 ? '#0DCE7C': '#ededed',marginRight:7}}></div>
          <div style={{flex:1,height:12,borderRadius:30,backgroundColor:step > 1 ? '#0DCE7C': '#ededed',marginRight:7}}></div>
          <div style={{flex:1,height:12,borderRadius:30,backgroundColor:step > 2 ? '#0DCE7C': '#ededed',marginRight:7}}></div>
          <div style={{flex:1,height:12,borderRadius:30,backgroundColor:step > 3 ? '#0DCE7C': '#ededed'}}></div>
        </div>
        {renderSection()}
      </div>
      <Footer
        save={step === 4 ? 'Create Workout' : 'Next'}
        onSubmit={()=>onNext()}
        onPrevious={onPrevious}/>
    </div>
  )
}

export default GenerateSession
