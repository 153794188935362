import React from 'react'
import '../../sass/main.scss'
import Button from '../common/button'

const Header = ({title,onCreate,onDelete,canDelete}) =>{
  return (
    <div className='all-drills-header'>
      <div className='clear-fix'>
        <h1 className='h1-black bold'>{title}</h1>
        <div className='clear-fix-right'>
          <Button onClick={onCreate} text={'+ Create Workout'} className={'btn btn-purple u-margin-right-5'}/>
          <Button onClick={canDelete && onDelete} text={'Delete Workouts'} className={canDelete ? 'btn btn-light-red' : 'btn btn-disabled'}/>
        </div>
      </div>
    </div>
  )
}

export default Header
