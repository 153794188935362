import React,{useEffect,useRef,useState} from 'react'
import '../../sass/main.scss'
import Message from './message'
import Moment from 'moment';

const ChatList = ({dates,sender}) =>{
  const scrollRef = useRef('')
  useEffect(()=>{
    scrollRef.current.scrollIntoView({ behavior: "smooth" });
  },[dates])
  return (
    <section className='chat-list'>
      {
        dates.map((date,index)=>(
          <div key={date._id}>
            <p className='chat-list-date'>{Moment(new Date(date._id)).utc().format('MMM D, YYYY')}</p>
            {
              date.messages.map((message,index)=>(
                <Message
                  key={index}
                  message={message}
                  current={sender === message.sender}/>
              ))
            }
          </div>
        ))
      }
      <div style={{ float:"left", clear: "both" }}
             ref={scrollRef}>
        </div>
    </section>
  )
}

export default ChatList
