import React from 'react';
import '../../sass/main.scss';

const TabBar = ({selected,onSelect}) =>{
  return (
    <ul className='drills-tab-bar'>
      <li onClick={()=>onSelect(0)}className={selected == 0 && 'drill-tab-active'}>
        Images
      </li>
      <li onClick={()=>onSelect(1)}className={selected == 1 && 'drill-tab-active'}>
        Videos
      </li>
    </ul>
  )
}

export default TabBar
