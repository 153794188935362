import React from 'react'
import '../../sass/main.scss'

const GenerateQuestion = ({onClick}) =>{
  return (
    <div className='card' style={{textAlign:'center',marginTop:20,padding:20}}>
      <h3 className='h3-black bold'>Generate Workout?</h3>
      <p className='body1-grey bold u-margin-bottom-10'>Would you like My Coach to help you create a session?</p>
      <div>
        <a onClick={()=>onClick(true)} className='btn btn-green u-margin-right-5'>Yes</a>
        <a onClick={()=>onClick(false)}className='btn btn-border-green'>No</a>
      </div>
    </div>
  )
}

export default GenerateQuestion
