import { DrillActionTypes } from '../../utils/enums'

const INITIAL_STATE = {
  categories:[],
  difficulty:[],
  equipment:[],
  space:[]
}

export const DrillReducer = (state = INITIAL_STATE, action) =>{
  switch (action.type) {
    case DrillActionTypes.UPDATE_CATEGORIES:
      return {...state,categories:action.payload}
    case DrillActionTypes.UPDATE_DIFFICULTY:
      return {...state,difficulty:action.payload}
    case DrillActionTypes.UPDATE_EQUIPMENT:
      return {...state,equipment:action.payload}
    case DrillActionTypes.UPDATE_SPACE:
      return {...state,space:action.payload}
    default:
      return state
  }
}

export default DrillReducer;
