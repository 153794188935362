import React,{useState,useEffect} from 'react';
import '../sass/main.scss';
import { editCoach } from '../services/coaches'
import { getAddedPlayers, getSubscribedPlayers, invitePlayer } from '../services/players'
import { activatePlayer, deactivatePlayer,updateSubscriptionPrice,deletePlayer } from '../services/subscriptions'
import { authorizeStripeConnect,getStripeDashboardLink,getStripeCustomerSetup,getStripeCustomerPortal,getStripeCustomer,getStripeCustomerPaymentMethods } from '../services/stripe'
import { getCoach } from '../services/coaches'
import Header from '../components/new-dashboard/header'
import LineTabBar from '../components/new-dashboard/line-tab-bar'
import Table from '../components/common/table'
import DeleteModal from "../components/modals/delete";
import InvitePlayerModal from "../components/modals/invite-player";
import Modal from "../components/modals/modal";
import Spinner from '../components/common/spinner'
import PayPerUser from '../components/new-dashboard/pay-per-user'
import SubscribedPlayers from '../components/new-dashboard/subscribed-players'
import AddedPlayers from '../components/new-dashboard/new-added-players'
import PricingInProgress from '../components/new-dashboard/pricing-in-progress'
import ConnectToStripe from '../components/new-dashboard/connect-to-stripe'
import SubscriptionPricing from '../components/new-dashboard/set-subscription-price'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'

const Dashboard = ({}) =>{
  //=============================
  //STATE
  //=============================
  const [loading,setLoading] = useState(true)
  const [tab,setTab] = useState(1)
  const [addedPlayers,setAddedPlayers] = useState([])
  const [estCost,setEstCost] = useState(0)
  const [credits,setCredits] = useState(0)
  const [subscribedPlayers,setSubscribedPlayers] = useState([])
  const [coach,setCoach] = useState({})
  const [isActive,setIsActive] = useState(0)
  const [deleteModalVisible, setDeleteModalVisible] = useState({visible:false,index:null,type:null});
  const [activateModalVisible, setActivateModalVisible] = useState({visible:false,index:null});
  const [inviteModalVisible,setInviteModalVisible] = useState(false)
  const [hasPaymentMethods,setHasPaymentMehtods] = useState(false)

  //=============================
  //HOOKS
  //=============================
  const navigate = useNavigate()

  useEffect(()=>{
    setLoading(true)
    const fetchData = async() =>{
      let addedPlayersResponse = await getAddedPlayers()
      let subscribedPlayersResponse =  await getSubscribedPlayers()
      let coachResponse = await getCoach()
      let hasPaymentMethods = await getStripeCustomerPaymentMethods()
      setHasPaymentMehtods(hasPaymentMethods)
      setAddedPlayers(addedPlayersResponse.players)
      setEstCost(addedPlayersResponse.estCost)
      setCredits(addedPlayersResponse.credits)
      setSubscribedPlayers(subscribedPlayersResponse)
      setCoach(coachResponse)
      setLoading(false)
    }
    fetchData()
  },[])
  useEffect(() => {
     // Getting the current URL
     let url = window.location.href;
     // If URL has parameters, we read them
     if(url.includes('?')){
         // We transform parameters to object
         let res = transformURLparametersToObject(url);
         if(res.code){
             // We complete the Stripe connection
             completeStripeConnection(res.code);
         }
     }
   }, []);


  //=============================
  //HANDLERS
  //=============================
  const onDeactivate = async() =>{
    const player = addedPlayers.active[deleteModalVisible.index]
    if(player){
      await deactivatePlayer(player.subscription_id)
      let temp = [...addedPlayers.active]
      temp.splice(deleteModalVisible.index,1)
      setAddedPlayers({inactive:[...addedPlayers.inactive,player],active:temp})
      toast.success("Player has succesfully been deactivated")
    }
  }

  const onActivate = async() =>{
    const player = addedPlayers.inactive[activateModalVisible.index]
    if(player){
      const response = await activatePlayer(player.subscription_id)
      if(response.success){
        let temp = [...addedPlayers.inactive]
        temp.splice(activateModalVisible.index,1)
        setAddedPlayers({active:[...addedPlayers.active,player],inactive:temp})
        toast.success("Player has succesfully been activated")
      }else{
        toast.error("You can't activate this player until the start of next month.")
      }
    }
  }

  const onDeletePlayer = async() =>{
    const player = addedPlayers[deleteModalVisible.index]
    if(player){
      let temp = [...addedPlayers]
      if(deleteModalVisible.type === 'delete'){
        await deletePlayer(player.subscription_id)
        temp[deleteModalVisible.index].pending_delete = true
        toast.success("Player has been scheduled to be deleted")
      }else if(deleteModalVisible.type === 'cancel'){
        await activatePlayer(player.subscription_id)
        temp[deleteModalVisible.index].pending_delete = false
        toast.success("Player has been reactivated")
      }
      setAddedPlayers(temp)
    }
  }

  const sendInvite = async(email,fname) =>{
    setInviteModalVisible(false)
    await invitePlayer({email,fname})
  }

  const copyLink = () =>{
    navigator.clipboard.writeText(coach.invite_link)
    toast.success('Copied to clipboard')
  }

  const completeStripeConnection = async (authorizationCode) => {
     await authorizeStripeConnect({authorizationCode})
     window.analytics.track('Stripe Connection Completed');
     window.window.location.href = 'https://mycoachapp.co/dashboard'

   }

   const transformURLparametersToObject = (url) => {
     // Getting the string after the quotation mark
     let str = url.split('?')[1];
     // Splitting if we hit a &
     let arr = str.split('&');
     // Resplitting again
     let entries = arr.map(str => [str.split('=')[0], str.split('=')[1]]);
     // Transforming into object
     let res = Object.fromEntries(entries);
     return res;
   }

  const connectToStripe = () =>{
      // URL to authorize OAuth
      let url = 'https://connect.stripe.com/express/oauth/authorize';
      //test mode
      // let clientID = 'ca_LqE5FnyfMja4KNUlZ6zBYpCIQUUyuMWq';
      //live mode
      let clientID = 'ca_LqE5WFUyefAGWQdaNUu5ntpRJnV2N7MP'
      // Redirecting the user to desired URL
      window.location.href = `${url}?response_type=code&client_id=${clientID}&scope=read_write&redirect_uri=https://mycoachapp.co/dashboard`;
      window.analytics.track('Stripe Connecting Started');
  }

  const redirectToStripeDashboard = async() =>{
    const link = await getStripeDashboardLink()
    window.open(link);
  }

  const redirectToStripeCustomerPortal = async() =>{
    if(coach.customer_id){
      const link = await getStripeCustomerPortal(coach.customer_id)
      window.open(link);
    }
  }

  const redirectStripeCustomerSetup = async() =>{
    const response = await getStripeCustomerSetup()
    window.open(response.url);
  }

  const onSubmitPricing = async(select,trial,price) =>{
    let subscription_price = {
      price,
      label:select.label,
      trial: trial.value,
      status:'In Review',
      set:1
    }
    await updateSubscriptionPrice({subscription_price})
    setCoach({...coach,subscription_price:{...coach.subscription_price,set:1}})
    toast.success("Subscription pricing was succesfully submitted.")
  }

  if(!loading) {
    return (
      <main className='section-container'>
        <Header
          tab={tab}
          onTabChange={(tab) => setTab(tab)}
          publicAcct={coach.stripe_user_id ? true : false}
          academy={coach.academy}/>
            <section className='week-container'>
              {
                tab === 0
                  ? coach.customer_id && hasPaymentMethods
                   ?<AddedPlayers
                     credits={credits}
                     estCost={estCost}
                     goToDeletedPlayers={()=>navigate('/deleted-players')}
                     deletePlayer={(index,type)=>setDeleteModalVisible({visible:true,index,type})}
                     question={()=>navigate('/billing-information')}
                     onBilling={redirectToStripeCustomerPortal}
                     onInvitePlayer={()=>setInviteModalVisible(true)}
                     players={addedPlayers}/>
                   :<PayPerUser
                      onLearnMore={()=>navigate('/billing-information')}
                      onAddPaymentMethod={redirectStripeCustomerSetup}/>
                  :coach.stripe_user_id
                   ? coach.subscription_price.set === 2
                      ? <SubscribedPlayers
                           nameId={coach.name_id}
                           question={()=>navigate('/billing-information')}
                           onStripeDashboard={redirectToStripeDashboard}
                           onPricing={()=>navigate('/subscription-pricing')}
                           players={subscribedPlayers}/>
                      : coach.subscription_price.set === 1
                        ?<PricingInProgress />
                        :<SubscriptionPricing onSubmit = {(select,trial,price) => onSubmitPricing(select,trial)} />
                   : <ConnectToStripe
                        onLearnMore={()=>navigate('/billing-information')}
                        onConnectToStripe={connectToStripe}/>
              }
            </section>

        <Modal visible={deleteModalVisible.visible}>
          <DeleteModal
            title={deleteModalVisible.type === 'delete' ? "Delete Player?" : "Cancel Delete"}
            text={
              deleteModalVisible.type === 'delete'
                ? "Are you sure you want to delete this player? They will be permantly deleted at the end of this month."
                : "Are you sure you want to cancel the deletion of this player?"
            }
            deleteText={deleteModalVisible.type === 'delete' ? 'Delete' : 'Cancel Delete'}
            onDelete={onDeletePlayer}
            onCancel={() => setDeleteModalVisible({visible:false,index:null})}
          />
        </Modal>
        <Modal visible={activateModalVisible.visible}>
          <DeleteModal
            title={"Activate Player?"}
            text={
              "Are you sure you want to activate this player? They will count towards your billing."
            }
            deleteText={'Activate'}
            onDelete={onActivate}
            onCancel={() => setActivateModalVisible({visible:false,index:null})}
          />
        </Modal>
        <Modal visible={inviteModalVisible}>
          <InvitePlayerModal
            onSave={(email,fname) => sendInvite(email,fname)}
            onCancel={() => setInviteModalVisible(false)}
          />
        </Modal>
      </main>
    )
  }else{
    return (
      <main className='section-container'>
        <div style={{marginTop:200}}>
          <Spinner/>
        </div>
      </main>
    )
  }

}

export default Dashboard
