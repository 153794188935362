import React,{useRef} from 'react'
import '../../sass/main.scss'
import { FiSend } from "react-icons/fi";
import { FaRegFileImage,FaFileVideo } from "react-icons/fa";

const Input = ({value,handleChange,onSend,onImage,onVideo}) =>{
  const imageFile = useRef(null)
  const videoFile = useRef(null)
  return (
    <div className='message-input'>
      <div className='send'>
        <FaRegFileImage onClick={()=>imageFile.current.click()} color='#007AFF'size={27}/>
          <input
            accept="image/*"
            onInput={onImage}
            type='file'
            name={'image'}
            ref={imageFile}
            style={{display: 'none'}}/>
      </div>
      <div className='send' onClick={()=>videoFile.current.click()}>
        <FaFileVideo onClick={onSend} color='#007AFF'size={27}/>
          <input
            accept="video/*"
            onInput={onVideo}
            type='file'
            name={'video'}
            ref={videoFile}
            style={{display: 'none'}}/>
      </div>
      <div style={{flex:1,marginRight:10,marginLeft:10}}>
        <input
          type={'text'}
          className={'message-input-container'}
          placeholder={'Type a message ...'}
          onChange={handleChange}
          name={'message'}
          value={value}/>
      </div>
      <div className='send'>
        <FiSend onClick={onSend} color='#007AFF'size={27}/>
      </div>
    </div>
  )
}

export default Input
