import React,{useState} from 'react';
import '../../sass/main.scss';
import Input from '../../components/common/form-input'
import Button from '../../components/common/button'
import { forgotPassword,resetPassword } from '../../services/login'
import { toast } from 'react-toastify';
import NavBar from '../../components/onboarding/navbar'
import { useNavigate } from 'react-router-dom'

const ForgotPassword = ({}) =>{
  //===============================
  //STATE
  //===============================
  const [step,setStep] = useState(1)
  const [username,setUsername] = useState('')
  const [token,setToken] = useState('')
  const [password,setPassword] = useState('')
  const [passwordConfirmation,setPasswordConfirmation] = useState('')
  const navigate = useNavigate()
  //===============================
  //HANDLERS
  //===============================
  const handleForgotPassword = async() =>{
    await forgotPassword({username,account_type:'Coach'})
    setStep(2)
  }

  const handleResetPassword = async() =>{
    if(!token){
      toast.error('Enter verification code')
    }else if(!password){
      toast.error('Enter a password')
    }else if(password.length < 6){
      toast.error('Password must contain at least 7 characters');
    }else if((password !== passwordConfirmation)){
      toast.error('Passwords do not match')
    }else{
      const response = await resetPassword({username,password,token,account_type:'Coach'})
      if(response.success){
        if (typeof window !== 'undefined') {
             window.location.href = "https://coach.mycoachapp.co/login";
        }
      }else{
        toast.error('Invalid token')
      }
    }

  }

  return(
    <section className='onboarding-container'>
      {
        step === 1
          ?<section>
            <h2 className='h2-black bold u-margin-bottom-20'style={{textAlign:'center'}}>Enter the email address you used to sign up.</h2>
            <Input
              type={'text'}
              placeholder={'Email Address'}
              name={'username'}
              value={username}
              handleChange={(event) => setUsername(event.target.value)}/>
              <Button
                onClick={ username && handleForgotPassword}
                text={'Send Verification Code'}
                className='btn btn-lrg btn-block btn-green u-margin-top-10'
                />
          </section>
          :<section>
            <h2 className='h2-black bold'style={{textAlign:'center'}}>Check your email for the verification code.</h2>
            <p className='body1-grey bold u-margin-bottom-20' style={{textAlign:'center'}}>Make sure to check your spam folder.</p>
            <Input
              className='group u-margin-bottom-20'
              type={'text'}
              placeholder={'Verification Code'}
              name={'token'}
              value={token}
              handleChange={(event) => setToken(event.target.value)}/>
              <Input
                className='group u-margin-bottom-20'
                type={'password'}
                placeholder={'Password'}
                name={'password'}
                value={password}
                handleChange={(event) => setPassword(event.target.value)}/>
              <Input
                className='group u-margin-bottom-20'
                type={'password'}
                placeholder={'Confirm Password'}
                name={'passwordConfirmation'}
                value={passwordConfirmation}
                handleChange={(event) => setPasswordConfirmation(event.target.value)}/>
                <Button
                  onClick={handleResetPassword}
                  text={'Reset Password'}
                  className='btn btn-lrg btn-block btn-green u-margin-top-10'
                  />
          </section>
      }


    </section>
  )
}

export default ForgotPassword
