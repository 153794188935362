import React,{useState,useEffect} from 'react';
import '../../sass/main.scss';
import Input from '../../components/common/form-input'
import Button from '../../components/common/button'
import { editCoach } from '../../services/coaches'
import { toast } from 'react-toastify';
import NavBar from '../../components/onboarding/navbar'
import { useNavigate } from 'react-router-dom'
import TextArea from '../../components/common/form-textarea'

const Bio = ({}) =>{
  //===============================
  //STATE
  //===============================
  const [bio,setBio] = useState('')
  const navigate = useNavigate()
  useEffect(()=>{
    const token = localStorage.getItem('token');
    if(!token) navigate('/login')
  },[])
  //===============================
  //HANDLERS
  //===============================
  const onSubmit = async() =>{
    await editCoach({bio,onboarding_complete:true})
    localStorage.setItem('onboarding','true')
    if (typeof window !== 'undefined') {
         window.location.href = "https://coach.mycoachapp.co/dashboard";
    }
    window.analytics.track('Onboarding-complete');

  }

  return(
    <section className='onboarding-container'>
      <NavBar
        onBack={()=>navigate('/logo')}
        title={'Let players know about yourself.'}
        subtitle={'Write a short summary about your coaching experiences.'}
      />
      <TextArea
        className='group u-margin-bottom-40'
        rows={5}
        placeholder={'Write a short summary'}
        name={'bio'}
        value={bio}
        handleChange={(event) => setBio(event.target.value)}/>
      <Button
        onClick={onSubmit}
        text={'Finish'}
        className='btn btn-lrg btn-block btn-green u-margin-top-10'
        />
    </section>
  )
}

export default Bio
