import React,{useState} from 'react'
import '../../sass/main.scss'
import { Prices,Trials } from '../../utils/variables'
import CurrencyInput from '../common/form-currency'
import Select from '../common/form-select'

const SetSubscriptionPricing = ({onSubmit}) =>{
  //============================
  //STATE
  //============================
  const [loading,setLoading] = useState(false)
  const [price,setPrice] = useState(0)
  const [select,setSelect] = useState(null)
  const [trial,setTrial] = useState(null)
  //============================
  //HANDLERS
  //============================
  const onChangeValue = (event) =>{
    setTrial(event.target.value)
  }

  return (
    <section className='card'>
      <h2 className='h2-black bold label-header'>Subscription Pricing</h2>
      <div className='u-padding-20'>
        <p className='h3-grey bold u-margin-bottom-20' style={{lineHeight:1.2}}>Set the your monthly subscription prices. Once you submit your initial pricing, we will create a subscription item for you on both Stripe and Apple Purchases. You will be able to edit your prices at any time.</p>
        <Select
          className='group u-margin-bottom-20'
          onValueChange={(value) => {
            setSelect(value)
            setPrice(value.value)
          }}
          value={select}
          label='Subscription Monthly Pricing'
          options={Prices}/>
        <Select
          className='group u-margin-bottom-20'
          onValueChange={(value) => {
            setTrial(value)
          }}
          value={trial}
          label='Free Trial Period'
          options={Trials}/>
        <div className='center u-margin-top-40'>
          <a
            onClick={()=>select && price && trial ? onSubmit(select,trial,price) : null}
            className={select && price && trial ? 'btn btn-purple' : 'btn btn-disabled'}>
            Submit For Review
          </a>
        </div>
      </div>
    </section>
  )
}

export default SetSubscriptionPricing
