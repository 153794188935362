import React from 'react'
import '../../sass/main.scss';
import Header from './header'


const Image = ({title,url,onCancel}) =>{
  const styles = {
    backgroundImage:'url('+ url +')',
    backgroundSize:'contain'
  }
  return (
    <div className='modal-small'>
      <Header
        title={title}
        onClick={onCancel}/>
      <div className='modal-content'>
        <div className='content-card' style={styles}></div>
      </div>
    </div>
  )
}

export default Image
