import React from 'react'
import '../../sass/main.scss'
import Button from './button'


const FormOneButton = ({label,items,value,handleChange,required,error,className}) =>{
  return (
    <div className={className ? className : 'group'}>
      {
        label &&
         <div className='u-margin-bottom-15'>
           <div className='h3-black bold'>{label}</div>
           <label className='body2-grey bold'>Select one</label>
         </div>
      }
      <div className = 'form-row'>
        {
          items.map((item,index)=>(
            <div key={item} className='u-margin-right-10'>
              <Button
                text={item}
                className={item === value ? 'btn btn-green' : 'btn btn-border'}
                onClick={()=>handleChange(item)}/>
            </div>
          ))
        }
      </div>
      {error && <p style={{marginTop:10}}>{error}</p>}
    </div>
  )
}

export default FormOneButton
