import axios from 'axios';
import { API } from '../utils/variables'
import { getConfig } from '../utils/helpers'
import { toast } from 'react-toastify';

//authorize stripe connect user
export const authorizeStripeConnect = async (body) =>{
  try{
    const config = await getConfig()
    const route = `${API}/authorize-stripe-connect/`;
    const response = await axios.post(route,body,config)
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}

//authorize stripe connect user
export const getStripeDashboardLink = async () =>{
  try{
    const config = await getConfig()
    const route = `${API}/stripe/dashboard/`;
    const response = await axios.get(route,config)
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}

//authorize stripe connect user
export const payout = async () =>{
  try{
    const config = await getConfig()
    const route = `${API}/payout/`;
    const response = await axios.get(route,config)
    toast.success('Payout succesful')
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}

//get stripe customer setup
export const getStripeCustomerSetup = async () =>{
  try{
    const config = await getConfig()
    const route = `${API}/stripe/setup/`;
    const response = await axios.get(route,config)
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}

//get stripe customer portal
export const getStripeCustomerPortal = async (id) =>{
  try{
    const config = await getConfig()
    const route = `${API}/stripe/billing/?customer=${id}`;
    const response = await axios.get(route,config)
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}

//get stripe customer
export const getStripeCustomer = async () =>{
  try{
    const config = await getConfig()
    const route = `${API}/stripe/customer/`;
    const response = await axios.get(route,config)
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}
//payment methods
export const getStripeCustomerPaymentMethods = async () =>{
  try{
    const config = await getConfig()
    const route = `${API}/stripe/payment-methods/`;
    const response = await axios.get(route,config)
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}
