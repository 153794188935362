import React,{useState} from 'react'
import '../../sass/main.scss'
import AddCard from '../cards/add'
import SessionCard from '../cards/session'
import Moment from 'moment';
import { FaChevronDown,FaChevronUp } from "react-icons/fa";
import { SortableContainer,SortableElement } from 'react-sortable-hoc'

const Week = ({sessions,number,startDate,endDate,onDelete,openSessionsModal,onSortEnd}) =>{
  //===================================
  //STATE
  //===================================
  const [open,setOpen] = useState(false)
  const SortableItem = SortableElement(({ value, index}) =>(
    <SessionCard
      onDelete={()=>onDelete(value)}
      key={value.id}
      {...value.id}/>
  ))

  const SortableList = SortableContainer(({ items}) =>{
    return(
      <div className='grid-four'>
        {
          items.map((value,index) =>(
          <SortableItem
            value={value}
            index={index}
            key={value._id}/>
        ))

       }
       <AddCard title={'Add a Workout'} onClick={openSessionsModal}/>
      </div>
    )
  })

  return (
    <section className='month'>
      <div onClick={()=>setOpen(!open)}  className='month-header'>
        <h3 className='h3-black bold u-margin-bottom-15'>
          {Moment.utc(startDate).format('MMMM D')} - {Moment.utc(endDate).format('MMMM D')}
           <h6 className='body1-grey bold'>{sessions.length} Session{sessions.length === 1 ? '' : 's'}</h6>
           {
             open && <p className='body2-grey bold'>Hold to re-order</p>
           }
        </h3>
        <div className='month-header-icon'>
          {
            open ? <FaChevronUp size={20}/> : <FaChevronDown size={20}/>
          }
        </div>
      </div>
      {
        open
         &&
         <SortableList distance={1} items={sessions} onSortEnd={onSortEnd} axis='xy'/>


      }

    </section>
  )
}

export default Week;
