import React,{useState,useEffect} from 'react'
import '../../sass/main.scss';
import Header from './header'
import Footer from './footer'
import DrillsCard from '../cards/drill'
import Filters from '../drills/filters'
import TabBar from '../drills/tab-bar'
import LoadMore from '../../components/common/load-more'
import Search from '../../components/common/search-bar'
import Button from '../../components/common/button'
import Spinner from '../../components/common/spinner'
import Table from '../../components/common/table'

const DrillsTableModal = ({drills,onCancel,onSave,categories,difficulty,equipment,space,canLoadMore,loadMore,loading,search,onSearch,selected,onSelect}) =>{
  //==================================
  //STATE
  //==================================
  const [active,setActive] = useState(0)
  const [selectedDrills,setSelectedDrills] = useState([])
  const Headers = [
    {label:'',value:'url'},
    {label:'Title',value:'title'},
    {label:'Space Needed',value:'space'},
    {label:'Category',value:'category'},
    {label:'Difficulty',value:'difficulty'},
  ]
  //==================================
  //HANDLERS
  //==================================
  const onSelectDrill = (drill) =>{
    if(selectedDrills.includes(drill)){
      let tempDrills = [...selectedDrills]
      const foundIndex = selectedDrills.indexOf(drill)
      tempDrills.splice(foundIndex,1)
      setSelectedDrills(tempDrills)
    }else{
      setSelectedDrills([...selectedDrills,drill])
    }
  }

  return (
    <div className='modal-large'>
      <Header
        title={'Select a Drill'}
        onClick={onCancel}>
            <section>
              <Filters categories={categories} difficulty={difficulty} equipment={equipment} space={space}/>
                <div className='u-margin-top-20 u-margin-bottom-20'>
                  <Search
                    type={'text'}
                    placeholder={'Search for all drills'}
                    name={'search'}
                    value={search}
                    handleChange={(event) => onSearch(event.target.value)}/>
                </div>
                <TabBar
                  selected={selected}
                  onSelect={(tab)=>onSelect(tab)}
                  />
            </section>
      </Header>
      <div className='modal-grey modal-content modal-content-scrollable' style={{height:'60vh'}}>
        {
          !loading
            ?  <section className='week-container'>
                <Table
                  lessPadding
                  onSelect={(drill) =>onSave(drill)}
                  headers={Headers}
                  rows={drills}/>
              </section>
            : <div style={{marginTop:100}}><Spinner/></div>
        }

        {
          canLoadMore
           &&
           <LoadMore onClick={loadMore}/>
        }
      </div>
    </div>
  )
}

export default DrillsTableModal;
