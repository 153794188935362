import React,{useEffect,useState} from 'react';
import '../sass/main.scss';
import Header from '../components/profile/header'
import { editCoach,editLogo,editIntroVideo,getCoach } from '../services/coaches'
import { validateInfo } from '../utils/helpers'
import Input from '../components/common/form-input'
import CurrencyInput from '../components/common/form-currency'
import LogoInput from '../components/common/form-logo'
import VideoInput from '../components/common/form-video'
import TextArea from '../components/common/form-textarea'
import Spinner from '../components/common/spinner'

const Profile = ({}) =>{
  //====================================
  // STATE
  //====================================
   const [loading,setLoading] = useState(true)
   const [academy,setAcademy] = useState('')
   const [fname,setFname] = useState('')
   const [lname,setLname] = useState('')
   const [city,setCity] = useState('')
   const [country,setCountry] = useState('')
   const [instagram,setInstagram] = useState('')
   const [twitter,setTwitter] = useState('')
   const [tiktok,setTiktok] = useState('')
   const [youtube,setYoutube] = useState('')
   const [bio,setBio] = useState('')
   const [logo,setLogo] = useState('')
   const [video,setVideo] = useState('')
   const [errors,setErrors] = useState({academy:'',fname:'',lname:'',logo:''})
   const [loadingImage,setLoadingImage] = useState(false)
   const [loadingVideo,setLoadingVideo] = useState(false)

  //====================================
  // HOOKS
  //====================================
  useEffect(()=>{
    setLoading(true)
    const fetchData = async() =>{
      const { fname,lname,academy,bio,logo,video,city,country,instagram,twitter,tiktok,youtube } = await getCoach()
      setAcademy(academy)
      setFname(fname)
      setLname(lname)
      setBio(bio)
      setLogo(logo)
      setVideo(video)
      setCity(city)
      setCountry(country)
      setInstagram(instagram)
      setTwitter(twitter)
      setTiktok(tiktok)
      setYoutube(youtube)
      setLoading(false)
      window.analytics.track('Profile');
    }
    fetchData()
  },[])

  useEffect(() => {
   const warningText ='You have unsaved changes - are you sure you wish to leave this page?';
   const handleWindowClose = (e: BeforeUnloadEvent) => {
     e.preventDefault();
     return (e.returnValue = warningText);
   };
   window.addEventListener('beforeunload', handleWindowClose);
   return () => {
     window.removeEventListener('beforeunload', handleWindowClose);
   };
 }, []);

  //====================================
  // HANDLERS
  //====================================
  const _editCoach = async() =>{
    await editCoach({academy,fname,lname,bio,city,country,instagram,twitter,tiktok,youtube})
    localStorage.setItem('academy',academy)
    localStorage.setItem('lname',lname)
    localStorage.setItem('fname',fname)
    window.analytics.track('Edit Profile');
  }

  const onSubmit = () =>{
    const keys = {academy:true,fname:true,lname:true}
    let errorMessages = validateInfo({academy,fname,lname},keys)
    if(Object.entries(errorMessages).length === 0){
      _editCoach()
    } else{
      setErrors(errorMessages)
    }
  }

  const handleLogoChange = async(event) =>{
    const file = event.target.files[0]
    setLoadingImage(true)
    const data = new FormData()
    data.append('file',file)
    const response = await editLogo(data)
    localStorage.setItem('logo',response)
    setLogo(response)
    setLoadingImage(false)
  }

  const handleVideoChange = async(event) =>{
    const file = event.target.files[0]
    setLoadingVideo(true)
    const data = new FormData()
    data.append('file',file)
    const response = await editIntroVideo(data)
    localStorage.setItem('video',response)
    setVideo(response)
    setLoadingVideo(false)
  }

  const signOut = () =>{
    localStorage.removeItem('id')
    localStorage.removeItem('token')
    localStorage.removeItem('fname')
    localStorage.removeItem('lname')
    localStorage.removeItem('logo')
    localStorage.removeItem('academy')
    localStorage.removeItem('bio')
    localStorage.removeItem('onboarding')
    if (typeof window !== 'undefined') {
         window.location.href = "https://coach.mycoachapp.co/dashboard";
    }
  }

  if(!loading){
    return (
      <main className='section-container'>
        <Header title={'Edit Profile'} onSave={onSubmit} signOut={signOut}/>
        <section className='card week-container'>
          <h3 className='h3-black bold label-header'>Personal Information</h3>
          <div className='u-padding-20'>
            <Input
              className='group u-margin-bottom-20'
              error={errors.fname}
              label={'First Name*'}
              type={'text'}
              placeholder={''}
              name={'fname'}
              value={fname}
              handleChange={(event) => setFname(event.target.value)}
              required={true}/>
            <Input
              className='group u-margin-bottom-20'
              error={errors.lname}
              label={'Last Name*'}
              type={'text'}
              placeholder={''}
              name={'lname'}
              value={lname}
              handleChange={(event) => setLname(event.target.value)}
              required={true}/>
            <Input
              className='group u-margin-bottom-20'
              label={'City'}
              type={'text'}
              placeholder={''}
              name={'city'}
              value={city}
              handleChange={(event) => setCity(event.target.value)}
              required={true}/>
            <Input
              className='group'
              label={'Country'}
              type={'text'}
              placeholder={''}
              name={'country'}
              value={country}
              handleChange={(event) => setCountry(event.target.value)}
              required={true}/>
          </div>

        </section>
        <section className='card week-container'>
          <h3 className='h3-black bold label-header '>Academy Information</h3>
          <div className='u-padding-20 '>
            <Input
              className='group u-margin-bottom-20'
              error={errors.academy}
              label={'Academy Name*'}
              type={'text'}
              placeholder={''}
              name={'academy'}
              value={academy}
              handleChange={(event) => setAcademy(event.target.value)}
              required={true}/>
              <TextArea
                className='group u-margin-bottom-20'
                rows={5}
                label={'Bio'}
                placeholder={'Write a short summary about your program'}
                name={'bio'}
                value={bio}
                handleChange={(event) => setBio(event.target.value)}
                required={true}/>
              <LogoInput
                className='group u-margin-bottom-20'
                error={errors.logo}
                label={'Academy Logo*'}
                value={logo}
                name='logo'
                loading={loadingImage}
                handleChange={(event) => handleLogoChange(event)}
                />
              <VideoInput
                className='group'
                label={'Intro Video*'}
                value={video}
                name='video'
                loading={loadingVideo}
                handleChange={(event) => handleVideoChange(event)}
                />
          </div>
        </section>
        <section className='card week-container'>
          <div className='label-header'>
            <h3 className='h3-black bold'>Social Media</h3>
            <p className='body1-grey bold'>Don't include @ symbol</p>
          </div>
          <div className='u-padding-20'>
            <Input
              className='group u-margin-bottom-20'
              label={'Instagram'}
              type={'text'}
              placeholder={''}
              name={'instagram'}
              value={instagram}
              handleChange={(event) => setInstagram(event.target.value)}
              required={true}/>
              <Input
                className='group u-margin-bottom-20'
                label={'Twitter'}
                type={'text'}
                placeholder={''}
                name={'twitter'}
                value={twitter}
                handleChange={(event) => setTwitter(event.target.value)}
                required={true}/>
              <Input
                className='group u-margin-bottom-20'
                label={'Tik Tok'}
                type={'text'}
                placeholder={''}
                name={'tiktok'}
                value={tiktok}
                handleChange={(event) => setTiktok(event.target.value)}
                required={true}/>
              <Input
                className='group'
                label={'Youtube'}
                type={'text'}
                placeholder={''}
                name={'youtube'}
                value={youtube}
                handleChange={(event) => setYoutube(event.target.value)}
                required={true}/>
          </div>
        </section>
      </main>
    )
  }else{
    return (
      <main className='section-container'>
        <div style={{marginTop:200}}><Spinner/></div>
      </main>
    )

  }

}

export default Profile
