import React from 'react'
import '../../sass/main.scss'

const GenerateSpaceQuestion = ({selected,onSelect,space}) =>{
  const renderButton = (value,label) =>{
    return (
      <a
        onClick={()=>onSelect(value)}
        className={selected.includes(value) ? 'u-margin-bottom-10 btn btn-green btn-block' : 'u-margin-bottom-10 btn btn-border-green btn-block'}>
      {label}
    </a>
)
  }
  return (
    <div>
      {renderButton('Ball Mastery','Ball Mastery')}
      {renderButton('Wall Drills','First Touch')}
      {renderButton('Dribbling','Dribbling')}
      {space < 2 && renderButton('Shooting','Shooting')}
      {renderButton('Juggling','Juggling')}
      {renderButton('Agility','Agility')}
    </div>
  )
}

export default GenerateSpaceQuestion
