import React, { useEffect, useState, useRef } from "react";
import "../sass/main.scss";
import { getContent } from '../services/content'
import Nav from "./form-navbar";
import Input from "../components/common/form-input";
import TextArea from "../components/common/form-textarea";
import FormOneButton from "../components/common/form-one-button";
import FormMultipleButton from "../components/common/form-multiple-button";
import FormInstructions from "../components/common/form-instructions";
import VideoCard from "../components/cards/video";
import ImageCard from "../components/cards/image";
import DeleteModal from "../components/modals/delete";
import MediaModal from "../components/modals/select-media";
import Modal from "../components/modals/modal";
import {
  validateInfo,
  validateSession,
  estimateDuration,
} from "../utils/helpers";

const Drill = ({
  _title,
  _category,
  _difficulty,
  _equipment,
  _space,
  _single,
  _timed,
  _position,
  _url,
  _diagram,
  _instructions,
  _setUpInstructions,
  onSubmit,
  onDelete,
}) => {
  //===================================
  //STATE
  //===================================
  const [title, setTitle] = useState(_title);
  const [category, setCategory] = useState(_category);
  const [difficulty, setDifficulty] = useState(_difficulty);
  const [equipment, setEquipment] = useState(_equipment);
  const [space, setSpace] = useState(_space);
  const [single, setSingle] = useState(_single);
  const [timed, setTimed] = useState(_timed);
  const [position, setPosition] = useState(_position);
  const [url, setUrl] = useState(_url);
  const [diagram, setDiagram] = useState(_diagram);
  const [instructions, setInstructions] = useState(_instructions);
  const [images,setImages] = useState([])
  const [videos,setVideos] = useState([])
  const [skip,setSkip] = useState(0)
  const [setUpInstructions, setSetUpInstructions] = useState(
    _setUpInstructions
  );
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [mediaModalVisible, setMediaModalVisible] = useState({visible:false,items:null});
  const [errors, setErrors] = useState({
    title: "",
    category: "",
    difficulty: "",
    space: "",
    position: "",
    single: "",
    timed: "",
    url: "",
  });

  //===================================
  //HOOKS
  //===================================
    useEffect(()=>{
      const fetchData = async() =>{
        const imageResponse = await getContent(skip,'image')
        const videoResponse = await getContent(skip,'video')
        setImages(imageResponse)
        setVideos(videoResponse)
      }
      fetchData()
    },[])
  //===================================
  //HANDLERS
  //===================================

  const handleVideoChange = (e) => {
    setUrl(e.target.files[0]);
  };

  const handleImageChange = (e) => {
    setDiagram(e.target.files[0]);
  };

  const handleSubmit = () => {
    const keys = {
      title: true,
      category: true,
      difficulty: true,
      space: true,
      single: true,
      timed: true,
      url: true,
    };
    let errorMessages = validateInfo(
      { title, category, difficulty, space, single, timed, url },
      keys
    );
    if (Object.entries(errorMessages).length === 0) {
      createDrill();
    } else {
      setErrors(errorMessages);
    }
  };

  const createDrill = async () => {
    let drill = {
      title,
      category,
      difficulty,
      space: getSpace(),
      single: single === "Yes" ? true : false,
      timed: timed === "Yes" ? true : false,
      equipment,
      url,
      diagram,
      instructions,
      setUpInstructions,
    };
    await onSubmit(drill);
  };

  const getSpace = () => {
    if (space === "Garage / Inside House / Small Space") {
      return 2;
    } else if (space === "Backyard / Medium Space") {
      return 1;
    } else {
      return 0;
    }
  };

  const onAddInstructionsStep = () => {
    setInstructions([...instructions, ""]);
  };

  const onRemoveInstructionsStep = () => {
    let temp = [...instructions];
    temp.pop();
    setInstructions(temp);
  };

  const onInstructionStepChange = (value, index) => {
    let temp = [...instructions];
    temp[index] = value;
    setInstructions(temp);
  };

  const onAddSetUpInstructionsStep = () => {
    setSetUpInstructions([...setUpInstructions, ""]);
  };

  const onRemoveSetUpInstructionsStep = () => {
    let temp = [...setUpInstructions];
    temp.pop();
    setSetUpInstructions(temp);
  };

  const onSetUpInstructionStepChange = (value, index) => {
    let temp = [...setUpInstructions];
    temp[index] = value;
    setSetUpInstructions(temp);
  };

  const handleEquipmentChange = (item) => {
    let index = equipment.indexOf(item);
    if (index === -1) {
      setEquipment([...equipment, item]);
    } else {
      let temp = [...equipment];
      temp.splice(index, 1);
      setEquipment(temp);
    }
  };

  const addMediaItem = (item) =>{
    if(item.kind === 'image'){
      setDiagram(item.url)
    }else if(item.kind === 'video'){
      setUrl(item.url)
    }
    setMediaModalVisible({visible:false,items:null})
  }

  return (
    <section>
      <Nav
        onBack={"/drills"}
        onDelete={onDelete ? () => setDeleteModalVisible(true) : null}
        title={title}
        onSave={handleSubmit}
      />
      <div className="form-container">
        <Input
          className="group u-margin-bottom-40"
          error={errors.title}
          label={"Title*"}
          type={"text"}
          placeholder={"Drill title"}
          name={"title"}
          maxLength={50}
          value={title}
          handleChange={(event) => setTitle(event.target.value)}
        />
        <FormOneButton
          className="group u-margin-bottom-40"
          error={errors.category}
          label={"Category*"}
          items={[
            "Ball Mastery",
            "Dribbling",
            "Wall Drills",
            "Shooting",
            "Juggling",
            "Agility",
          ]}
          handleChange={(item) => setCategory(item)}
          value={category}
        />
        <FormOneButton
          className="group u-margin-bottom-40"
          error={errors.difficulty}
          label={"Difficulty*"}
          items={["Any", "Beginner", "Intermediate", "Advanced"]}
          handleChange={(item) => setDifficulty(item)}
          value={difficulty}
        />
        <FormMultipleButton
          className="group u-margin-bottom-40"
          label={"Equipment Needed"}
          items={[
            "Ball",
            "Cones",
            "Wall",
            "Goal",
            "Rebounder",
            "Ladder",
            "Hurdles",
          ]}
          handleChange={(item) => handleEquipmentChange(item)}
          values={equipment}
        />
        <FormOneButton
          className="group u-margin-bottom-40"
          error={errors.space}
          label={"Space Needed*"}
          items={[
            "Field / Park / Large Space",
            "Backyard / Medium Space",
            "Garage / Inside House / Small Space",
          ]}
          handleChange={(item) => setSpace(item)}
          value={space}
        />
        <FormOneButton
          className="group u-margin-bottom-40"
          error={errors.single}
          label={"Is this a single sided drill?*"}
          items={["Yes", "No"]}
          handleChange={(item) => setSingle(item)}
          value={single}
        />
        <FormOneButton
          className="group u-margin-bottom-40"
          error={errors.timed}
          label={"Is this a timed drill?*"}
          items={["Yes", "No"]}
          handleChange={(item) => setTimed(item)}
          value={timed}
        />
        <FormInstructions
          title={"Instructions"}
          instructions={instructions}
          onStepChange={(value, index) => onInstructionStepChange(value, index)}
          onRemoveStep={onRemoveInstructionsStep}
          onAddStep={onAddInstructionsStep}
        />
        <FormInstructions
          title={"Set Up Instructions"}
          instructions={setUpInstructions}
          onStepChange={(value, index) =>
            onSetUpInstructionStepChange(value, index)
          }
          onRemoveStep={onRemoveSetUpInstructionsStep}
          onAddStep={onAddSetUpInstructionsStep}
        />
        <div className="u-margin-bottom-40 u-margin-top-40">
          <h3 className="h3-black bold u-margin-bottom-15">Select Media</h3>
          <div className="grid-five">
            <VideoCard
              onClick={()=>setMediaModalVisible({visible:true,items:videos})}
              title={'Select Video'}
              url={url}/>
            <ImageCard
              url={diagram}
              onClick={()=>setMediaModalVisible({visible:true,items:images})}
              title={'Select Diagram'} />
          </div>
        </div>
      </div>
      <Modal visible={deleteModalVisible}>
        <DeleteModal
          title={"Delete Drill"}
          text={
            "Are you sure you want to delete this drill? It will be permanetly deleted."
          }
          onDelete={onDelete}
          onCancel={() => setDeleteModalVisible(false)}
        />
      </Modal>
      <Modal visible={mediaModalVisible.visible}>
        <MediaModal
          onSubmit={(item) => addMediaItem(item)}
          items={mediaModalVisible.items}
          onCancel={()=>setMediaModalVisible({visible:false,items:null})}/>
      </Modal>
    </section>
  );
};

export default Drill;
