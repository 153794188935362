import React from 'react'
import '../../sass/main.scss'
import Button from '../common/button'
import { FaChevronLeft } from "react-icons/fa";
import { useNavigate } from 'react-router-dom'

const Header = ({title,onClick,status,btnClass}) =>{
  const navigate = useNavigate()
  const getTagColor = () =>{
    switch (status) {
      case 'Approved':
        return 'tag tag-green'
      case 'Not Approved':
        return 'tag tag-red'
      case 'In Review':
        return 'tag tag-grey'
      default:
        return 'tag tag-green'
    }
  }
  return (
    <div className='all-drills-header'>
      <div className='clear-fix'>
        <div>
          <div className='nav-back u-margin-right-10'>
            <FaChevronLeft
              onClick={()=>navigate('/dashboard')}
              size={25}/>
          </div>
          <h1 className='h1-black bold'>{title}</h1>
          <span style={{fontSize:16}} className={getTagColor()}>{status}</span>
        </div>
        <div className='clear-fix-right'>
          <Button onClick={onClick} text={'Submit for Review'} className={btnClass}/>
        </div>
      </div>
    </div>
  )
}

export default Header
