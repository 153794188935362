import React from 'react'
import '../../sass/main.scss'
import { FaCheck } from "react-icons/fa";

const ContentCardDisplay = ({onClick,selected,url}) =>{
  const styles = {
    backgroundImage:'url('+ url +')'
  }

  return (
    <div onClick={onClick} className={selected ? 'content-card content-card-active' : 'content-card'} style={styles}>

    </div>
  )
}

export default ContentCardDisplay
