import axios from 'axios';
import { API } from '../utils/variables'
import { getConfig } from '../utils/helpers'
import { toast } from 'react-toastify';

//get all sessions
export const getSessions = async (skip) =>{
  try{
    const config = await getConfig()
    const route = `${API}/sessions/?skip=${skip}`;
    const response = await axios.get(route,config)
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}


//get one session
export const getSession = async (id) =>{
  try{
    const config = await getConfig()
    const route = `${API}/sessions/${id}`;
    const response = await axios.get(route,config)
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}

//create session
export const createSession = async (body) =>{
  try{
    const config = await getConfig()
    const route = `${API}/sessions/`;
    const response = await axios.post(route,body,config)
    toast.success('Your workout was succefully created.')
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}

//edit session
export const editSession = async (id,body) =>{
  try{
    const config = await getConfig()
    const route = `${API}/sessions/${id}`;
    const response = await axios.put(route,body,config)
    toast.success('Your workout was succefully saved.')
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}

//delete session
export const deleteSession = async (id) =>{
  try{
    const config = await getConfig()
    const route = `${API}/sessions/${id}`;
    const response = await axios.delete(route,config)
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}


//delete many session
export const deleteManySessions = async (ids) =>{
  try{
    const config = await getConfig()
    const route = `${API}/sessions/`;
    const response = await axios.put(route,{sessionIds:ids},config)
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}

//generate session
export const generateSession = async (body) =>{
  try{
    const config = await getConfig()
    const route = `${API}/sessions/generate`;
    const response = await axios.post(route,body,config)
    toast.success('Your workout was succefully created.')
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}
