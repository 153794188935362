import React,{useState,useEffect} from 'react';
import '../sass/main.scss';
import { getDeletedPlayers } from '../services/players';
import { activatePlayer } from '../services/subscriptions';
import Table from '../components/common/table';
import Modal from '../components/modals/modal';
import DeleteModal from '../components/modals/delete';
import { toast } from 'react-toastify';
import NavBar from '../forms/form-navbar'

const DeletedPlayers = ({}) =>{
  //=============================
  //STATE
  //=============================
  const [loading,setLoading] = useState(true)
  const [players,setPlayers] = useState([])
  const [activateModalVisible, setActivateModalVisible] = useState({visible:false,index:null})
  const Headers = [
    {label:'',value:'profile_pic'},
    {label:'Name',value:'name'},
    {label:'Joined',value:'created'},
    {label:'',value:'deactivate'},
  ]
  //=============================
  //HOOKS
  //=============================

  useEffect(()=>{
    setLoading(true)
    const fetchData = async() =>{
      const response = await getDeletedPlayers()
      setPlayers(response)
      setLoading(false)
    }
    fetchData()
  },[])
  //=============================
  //HANDLERS
  //=============================
  const onActivate = async() =>{
    const player = players[activateModalVisible.index]
    if(player){
      const response = await activatePlayer(player.subscription_id)
      let temp = [...players]
      temp.splice(activateModalVisible.index,1)
      setPlayers(temp)
      toast.success("Player has succesfully been reactivated")
    }
  }


  if(!loading){
    return (
      <main className='section-container'>
        <NavBar
          title='Deleted Players'
          onBack={'/dashboard'}/>
        <section className='week-container'>
          <Table
            active={false}
            activate={(index)=> setActivateModalVisible({ visible:true, index })}
            noItemsTitle={'No Players Deleted'}
            noItemsSubtitle={'Players that you delete will appear here.'}
            headers={Headers}
            rows={players}/>
        </section>

        <Modal visible={activateModalVisible.visible}>
          <DeleteModal
            title={"Activate Player?"}
            text={
              "Are you sure you want to reactivate this player? They will count towards your billing."
            }
            deleteText={'Activate'}
            onDelete={onActivate}
            onCancel={() => setActivateModalVisible({visible:false,index:null})}
          />
        </Modal>
      </main>
    )
  }else{
    return <div></div>
  }
}

export default DeletedPlayers;
