import React from 'react'
import '../../sass/main.scss'
import { FaRegCircle,FaCheckCircle } from "react-icons/fa";

const DrillItem = ({title,url,sets,reps,timed,completed,index}) =>{
  return(
    <div className='drill-item'>
      <div className='drill-item-circle u-margin-right-10'>
        {index}
      </div>
      <img src={url.replace('.mp4','.jpg')} width={80}  style={{borderRadius:5,marginRight:10}}/>
      <div style={{alginItems:'center'}}>
        <h3 className='h3-black bold'>{title}</h3>
        <p className='body1-grey bold'>{sets} set{sets === 1 ? '' : 's'} x {reps} {timed ? 'seconds' : 'reps'}</p>
      </div>
      <div style={{flex:1,alignSelf:'center',textAlign:'right'}}>
        {
          completed
            ?<FaCheckCircle color={'#34C759'} size={30}/>
            :<FaRegCircle color={'black'} size={30}/>
        }

      </div>

    </div>
  )
}

export default DrillItem
