import React from 'react'
import '../../sass/main.scss';
import { FiX } from 'react-icons/fi'


const Header = ({title,onClick,children}) =>{
  return(
    <div className='modal-header'>
      <FiX className='close-icon pointer' color={'black'} size={30} onClick={onClick}/>
      {
        title && <h2 className='h2-black bold'>{title}</h2>
      }
      {children}
    </div>
  )
}

export default Header
