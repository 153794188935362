import React,{useEffect,useState} from 'react'
import Input from '../../components/common/form-input'
import { register } from '../../services/login'
import { Route,Routes,useNavigate,Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { validateInfo } from '../../utils/helpers'

const Login = ({}) =>{
  //================================
  //STATE
  //================================
  const [username,setUsername] = useState('')
  const [password,setPassword] = useState('')
  const [fname,setFname] = useState('')
  const [lname,setLname] = useState('')
  const [academy,setAcademy] = useState('')
  const [errors,setErrors] = useState({username:'',password:'',fname:'',lname:'',academy})
  //================================
  //HOOKS
  //================================
  const navigate = useNavigate()
  // useEffect(()=>{
  //   const onboarding = localStorage.getItem('onboarding')
  //   const token = localStorage.getItem('token')
  //   if(token && !onboarding){
  //     navigate('/academy-name')
  //   }
  // },[])

  const handleSubmit = () =>{
    const keys = {username:true,password:true,fname:true,lname:true,academy:true}
    let errorMessages = validateInfo({username,password,fname,lname,academy},keys)
    if(Object.entries(errorMessages).length === 0){
      setErrors({username:'',password:'',fname:'',lname:'',academy:''})
      _register()
    } else{
      setErrors(errorMessages)
    }
  }

  const _register = async(info) =>{
    const response = await register({username,password,fname,lname,academy,account_type:'Coach'})
    if(response.success){
      localStorage.setItem('token',response.token)
      localStorage.setItem('logo',response.user.logo)
      localStorage.setItem('academy',response.user.academy)
      if (typeof window !== 'undefined') {
           window.location.href = "http://localhost:3001/dashboard";
      }
    }else{
      toast.error('A user with that username already exists.')
    }

  }

  return (
    <main>
      <section className='onboarding-container'>
        <h2 className='h2-black bold u-margin-bottom-20'style={{textAlign:'center'}}>Sign up with your email address.</h2>
          <Input
              autocapitalize={'none'}
              className='group u-margin-bottom-10'
              error={errors.username}
              type={'text'}
              placeholder={'Email Address'}
              name={'username'}
              value={username}
              handleChange={(event) => setUsername(event.target.value)}
              required={true}/>
            <Input
              className='group u-margin-bottom-20'
              error={errors.password}
              type={'password'}
              placeholder={'Password'}
              name={'password'}
              value={password}
              handleChange={(event) => setPassword(event.target.value)}
              required={true}/>
            <Input
                autocapitalize={'none'}
                className='group u-margin-bottom-20'
                error={errors.fname}
                type={'text'}
                placeholder={'First Name'}
                name={'fname'}
                value={fname}
                handleChange={(event) => setFname(event.target.value)}
                required={true}/>
            <Input
                autocapitalize={'none'}
                className='group u-margin-bottom-20'
                error={errors.lname}
                type={'text'}
                placeholder={'Last Name'}
                name={'lname'}
                value={lname}
                handleChange={(event) => setLname(event.target.value)}
                required={true}/>
                <Input
                  autocapitalize={'none'}
                  className='group u-margin-bottom-20'
                  error={errors.academy}
                  type={'text'}
                  placeholder={'Academy Name'}
                  name={'academy'}
                  value={academy}
                  handleChange={(event) => setAcademy(event.target.value)}
                  required={true}/>
              <a onClick={handleSubmit}style={{display:'block'}} className='btn btn-lrg btn-green u-margin-bottom-40'>SIGN UP</a>

            <hr style={{border:'.5px solid #ddd',marginBottom:30}}/>

            <h3 className='h3-black bold u-margin-bottom-20'style={{textAlign:'center'}}>Already have an account?</h3>
              <a
                onClick={()=>navigate('/login')}
                style={{display:'block'}}
                className='btn btn-lrg btn-border-green u-margin-bottom-20'>LOG INTO YOUR ACCOUNT</a>
            <p className='body1-grey bold u-margin-top-20' style={{textAlign:'center'}}>
               By clicking SIGN UP, you agree to our
               <a href="https://mycoachapp.co/terms-of-use" target="_blank" style={{color:'#0DCE7C'}}> Terms</a>
               ,
               <a href="https://mycoachapp.co/privacy-policy" target="_blank" style={{color:'#0DCE7C'}}> Privacy Policy </a>
               ,
               <a href="https://mycoachapp.co/coach-terms" target="_blank" style={{color:'#0DCE7C'}}> Coaches Terms </a>
               and
               You may receive Email Notifications from us and can opt out any time.
             </p>
      </section>
    </main>
  )
}

export default Login
