import React from 'react'
import '../../sass/main.scss'
import Button from './button'
import Filter from './filter'

const DropDown = ({label,visible,toggle,items,values,handleChange}) =>{
  return (
    <div className='dropdown'>
      <Button
        onClick={toggle}
        text={label}
        className={values.length === 0 ? 'btn btn-border' : 'btn btn-purple'}/>
      {
        visible && <Filter items={items} values={values} handleChange={(item) => handleChange(item)}/>
      }

    </div>
  )
}

export default DropDown
