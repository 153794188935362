import React from 'react'
import '../../sass/main.scss'

const GenerateSpaceQuestion = ({selected,onSelect}) =>{
  const renderButton = (value,label) =>{
    return (
      <a
        onClick={()=>onSelect(value)}
        className={selected === value ? 'u-margin-bottom-10 btn btn-green btn-block' : 'u-margin-bottom-10 btn btn-border-green btn-block'}>
      {label}
    </a>
)
  }
  return (
    <div>
      {renderButton(0,'Field / Park / Large space')}
      {renderButton(1,'Backyard / Medium Space')}
      {renderButton(2,'Garage / At Home / Small Space')}
    </div>
  )
}

export default GenerateSpaceQuestion
