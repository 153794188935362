import React from 'react'
import '../../sass/main.scss'

const CheckBox = ({label,checked,handleChange}) =>{
  return (
    <div onClick={handleChange} className='checkbox'>
      <input
          className='checkbox-input'
          type="checkbox"
          checked={checked}
          onChange={handleChange}
        />
      <label className='h3-black '>{label}</label>
    </div>
  )
}

export default CheckBox;
