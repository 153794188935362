import React,{useEffect,useState} from 'react';
import '../../sass/main.scss';
import Input from '../../components/common/form-input'
import Button from '../../components/common/button'
import { editCoach } from '../../services/coaches'
import { toast } from 'react-toastify';
import NavBar from '../../components/onboarding/navbar'
import { useNavigate } from 'react-router-dom'

const AcademyName = ({}) =>{
  //===============================
  //STATE
  //===============================
  const [academy,setAcademy] = useState('')
  const navigate = useNavigate()
  useEffect(()=>{
    const token = localStorage.getItem('token');
    if(!token) navigate('/login')
  },[])
  //===============================
  //HANDLERS
  //===============================
  const onSubmit = async() =>{
    await editCoach({academy})
    localStorage.setItem('academy',academy)
    navigate('/age-groups')

  }

  return(
    <section className='onboarding-container'>
      <NavBar
        title={'Academy name or your name?'}
        subtitle={'How do you want players to view your name, as an academy or individual coach?'}
      />
      <Input
        className='group u-margin-bottom-20'
        type={'text'}
        placeholder={'Training Academy or Alex Smith'}
        name={'academy'}
        value={academy}
        handleChange={(event) => setAcademy(event.target.value)}/>
      <Button
        onClick={onSubmit}
        text={'Next'}
        className='btn btn-lrg btn-block btn-green u-margin-top-10'
        />
    </section>
  )
}

export default AcademyName
