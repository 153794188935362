import React,{useState} from 'react'
import '../../sass/main.scss'

const TabBar = ({tab,onTabChange}) =>{

  const renderTab = (text,index) =>{
    return (
      <div
        onClick={()=>onTabChange(index)}
        className={tab === index ? 'tab-bar-tab tab-bar-tab-active' : 'tab-bar-tab'} style={{width:200}}>
        {text}
      </div>
    )
  }
  return (
    <div className='tab-bar'>
      {renderTab('My Coach Subscriptions',1)}
      {renderTab('Pay Per User',0)}
    </div>
  )
}

export default TabBar
