import React,{useState,useEffect} from 'react'
import '../../sass/main.scss';
import Header from './header'
import Footer from './footer'
import Input from '../common/form-input'
import { validateInfo } from '../../utils/helpers'

const InvitePlayer = ({onCancel,onSave}) =>{
  //===========================
  //STATE
  //===========================
  const [email,setEmail] = useState('')
  const [fname,setFname] = useState('')
  const [errors,setErrors] = useState({email:'',fname:''})
  //===========================
  //HANDLERS
  //===========================
  const onSubmit = () =>{
    const keys = {email:true}
    let errorMessages = validateInfo({email,fname},keys)
    if(Object.entries(errorMessages).length === 0){
      onSave(email,fname)
    } else{
      setErrors(errorMessages)
    }
  }

  return(
    <div className='modal-small'>
      <Header
        title={'Invite Player'}
        onClick={onCancel}/>
      <div className='modal-content'>
        <Input
          className='group u-margin-bottom-10'
          error={errors.fname}
          type={'text'}
          placeholder={'First Name'}
          name={'fname'}
          value={fname}
          handleChange={(event) => setFname(event.target.value)}/>
        <Input
          className='group u-margin-bottom-10'
          error={errors.email}
          type={'text'}
          placeholder={'Email Address'}
          name={'email'}
          value={email}
          handleChange={(event) => setEmail(event.target.value)}/>
      </div>
      <Footer
        save={'Send'}
        onSubmit={onSubmit}
        onCancel={onCancel}/>
    </div>
  )
}

export default InvitePlayer
