import React from 'react'
import '../../sass/main.scss'
import Button from '../common/button'

const Header = ({title,onClick}) =>{
  return (
    <div className='all-drills-header'>
      <div className='clear-fix'>
        <h1 className='h1-black bold'>{title}</h1>
        <div className='clear-fix-right'>
          <Button onClick={onClick} text={'Manage Groups'} className={'btn btn-purple'}/>
        </div>
      </div>
    </div>
  )
}

export default Header
