import React from 'react'
import '../../sass/main.scss'
import Button from './button'

const LoadMore = ({onClick}) =>{
  return (
    <div className='load-more'>
      <Button
        onClick={onClick}
        text={'Load More'}
        className='btn btn-border-green'/>
    </div>
  )
}

export default LoadMore
