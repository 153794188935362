import axios from 'axios';
import { API } from '../utils/variables'
import { getConfig } from '../utils/helpers'
import { toast } from 'react-toastify';

//get all drills
export const getDrills = async (categories,difficulty,equipment,space,mycoach,search,skip) =>{
  try{
    const config = await getConfig()
    const route = `${API}/drills/?categories=${categories}&difficulty=${difficulty}&equipment=${equipment}&space=${space}&search=${search}&skip=${skip}&mycoach=${mycoach}`;
    const response = await axios.get(route,config)
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}

//get one drill
export const getDrill = async (id) =>{
  try{
    const config = await getConfig()
    const route = `${API}/drills/${id}`;
    const response = await axios.get(route,config)
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}


//create a drill
export const createDrill = async (drill) =>{
  try{
    const config = await getConfig()
    const route = `${API}/drills/`;
    const response = await axios.post(route,drill,config)
    toast.success('Your drill was succesfully created.')
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}


//edit a drill
export const editDrill = async (id,drill) =>{
  try{
    const config = await getConfig()
    const route = `${API}/drills/${id}`;
    const response = await axios.put(route,drill,config)
    toast.success('Your drill was succesfully edited.')
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}

//delete a drill
export const deleteDrill = async (id,drill) =>{
  try{
    const config = await getConfig()
    const route = `${API}/drills/${id}`;
    const response = await axios.delete(route,config)
    toast.success('Your drill was succesfully deleted.')
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}
