import axios from 'axios';
import { API } from '../utils/variables'
import { getConfig } from '../utils/helpers'
import { toast } from 'react-toastify';


//get content
export const getContent = async (skip,type) =>{
  try{
    const config = await getConfig()
    const route = `${API}/content?skip=${skip}&type=${type}`;
    const response = await axios.get(route,config)
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}

//upload image
export const uploadImage = async (data) =>{
  try{
    const config = await getConfig()
    const route = `${API}/content/image`;
    const response = await axios.post(route,data,config)
    toast.success('Image was succesfully saved')
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}

//upload video
export const uploadVideo = async (data) =>{
  try{
    const config = await getConfig()
    const route = `${API}/content/video`;
    const response = await axios.post(route,data,config)
    toast.success('Video was succesfully saved')
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}

//delete image
export const deleteImages = async (images) =>{
  try{
    const config = await getConfig()
    const route = `${API}/content/image`;
    const response = await axios.put(route,images,config)
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}

//delete video
export const deleteVideos = async (videos) =>{
  try{
    const config = await getConfig()
    const route = `${API}/content/video`;
    const response = await axios.put(route,videos,config)
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}
