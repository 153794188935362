import React from 'react';
import '../sass/main.scss';
import { FaChevronLeft } from "react-icons/fa";
import { useNavigate } from 'react-router-dom'

const BillingInformation = ({}) =>{
  const navigate = useNavigate()
  return (
    <main className='section-container'>
      <div className='all-drills-header'>
        <div className='clear-fix'>
          <div className='nav-back u-margin-right-10'>
            <FaChevronLeft
              onClick={()=>navigate('/dashboard')}
              size={25}/>
          </div>
          <h1 className='h1-black bold'>Billing Information</h1>
        </div>
      </div>
      <section className='week-container'>


        <h2 className='h2-black bold'>Pay Per User</h2>
        <p className='body1-black'>
          This billing option is designed for coaches who want to charge their players using alternative payment methods.
          Pay per user can be a more flexible option for some coaches, allowing you to invite/add users manually.
          This can help coaches provide their service using My Coach as a package deal for their in-person sessions.
        </p>
        <br/>
        <h3 className='h3-black bold'>How you are billed</h3>
        <p className='body1-black'>
          You are billed <strong>$10 a month</strong> for active players on your My Coach roster.
          New players that are added during the current month will have a prorated charge from the day they initially joined.
          At the end of each month, we will caculate the number of active players that were on your roster and charge you accordingly.
        </p>
        <br/>
        <div className='card u-padding-20'>
          <h3 className='h3-black bold'>Example</h3>
          <p className='body1-black u-margin-bottom-15'>
            You are paying $10 per player.
            Twenty days into the month, you add a new player.
            There are 10 days remaining in the month.
          </p>
          <p className='body1-black bold u-margin-bottom-5'>
            How you are billed
          </p>

          <p className='body1-black'>
            To calculate the prorated cost for adding a new active player, we'd divide the cost per player by the number of days in the month,
            then multiply by the remaining number of days in the month:
            <strong> ($10 / 30 days) x (10 days) = $3.33</strong>
          </p>
        </div>
        <br/>
        <h3 className='h3-black bold'>How inactive players affect billing</h3>
          <p className='body1-black'>
            Players are only billable if they're active on your roster.
            Active players are players who viewed any workout 3 or more times and accessed any drill 5 or more times.
            If a player is inactive for the month they will not be charged.
          </p>
          <br/>
        <div className='card u-padding-20'>
          <h3 className='h3-black bold'>Example</h3>
          <p className='body1-black u-margin-bottom-15'>
            You are paying $10 per player.
            five of your players were active and three of your players were inactive.

          </p>
          <p className='body1-black bold u-margin-bottom-5'>
            How you are billed
          </p>

          <p className='body1-black'>
            To calculate your cost take only the active players and multiple by $10: 
            <strong> $10 x 5 Active Players = $50.00</strong>
          </p>
        </div>
        <br/><br/><br/>


        <h2 className='h2-black bold'>My Coach Subscriptions</h2>
        <p className='body1-black'>
          My Coach subscription is a fully managed payment option for coaches.
          With this option, coaches can create recurring subscriptions to offer their players.
          Subscriptions allow coaches to bring on remote players seamlessly and remove the difficulty of managing payments.
          In Addition, coaches will be given a public profile on the My Coach website and mobile app
          to give coaches more visibility and allow for potential new clients.
        </p>
        <br/>
        <h3 className='h3-black bold'>How billing works</h3>
        <p className='body1-black'>
          My Coach will take a 35% commission from the sale for each purchase after credit
          card processing fees or mobile app store commissions are taken.
          There will be no additional charges that come when using subscriptions.
        </p>
        <br/>
        <div className='card u-padding-20'>
          <h3 className='h3-black bold'>Example</h3>
          <p className='body1-black u-margin-bottom-15'>
            A coach has a monthly subscription of $30 a month for their service.
            A player goes to the coach's profile and
            purchases a monthly subscription from the coach.
          </p>
          <p className='body1-black bold u-margin-bottom-5'>
            How you are billed
          </p>

          <p className='body1-black'>
            In each billing cycle for the subscription, the coach, will receive 65% of the sale:
            <strong>  $30 x 65% = $19.50</strong>
          </p>
        </div>
      </section>
    </main>
  )
}

export default BillingInformation;
