import React,{useState} from 'react'
import '../../sass/main.scss'
import Table from '../common/table'
import { FaQuestionCircle,FaRegCopy } from "react-icons/fa";
import { toast } from 'react-toastify';

const Headers = [
  {label:'',value:'profile_pic'},
  {label:'Name',value:'name'},
  {label:'Joined',value:'created'},
  {label:'Payment Method',value:'paid'},
  {label:'Subscription Status',value:'active'}
]

const SubscribedPlayers = ({players,onPricing,onStripeDashboard,question,nameId}) =>{
  const copy = () =>{
    navigator.clipboard.writeText('https://mycoachapp.co/'+nameId)
    toast.success('Copied to clipboard')
  }

  return (
    <div>
      <div className='clear-fix'>
        <h2 className='h2-black bold u-margin-right-5'>Subscribed Players <FaQuestionCircle className='pointer' onClick={question}size={20} color={'#777'}/></h2>
        <div className='clear-fix-right'>
          <a onClick={onStripeDashboard} className='btn btn-border-purple u-margin-right-5'>View Stripe Dashboard</a>
          <a onClick={onPricing} className='btn btn-grey'>Edit Subscription Pricing</a>
        </div>
      </div>
      {
        nameId &&
        <div>
          <span className='body1-black bold'>Your Page : </span>
          <a href={'https://mycoachapp.co/'+nameId} target='_blank' style={{marginRight:10}}> mycoachapp.co/{nameId}</a>
          <a className='copy' onClick={() => copy()}><FaRegCopy size={15}/></a>
        </div>
      }
      <br/><br/>
      <Table
        lessPadding
        noItemsTitle={'No Players Subscribed'}
        noItemsSubtitle={'Players that purchase a subscription to your services will appear here.'}
        headers={Headers}
        rows={players}/>
    </div>
  )
}

export default SubscribedPlayers
