import React,{useEffect,useState} from 'react';
import '../../sass/main.scss';
import SessionForm from '../../forms/session'
import { useNavigate,useParams } from 'react-router-dom'
import { createSession } from '../../services/sessions'

const CreateSession = ({}) =>{
  const navigate = useNavigate()
  useEffect(() => {
   const warningText ='You have unsaved changes - are you sure you wish to leave this page?';
   const handleWindowClose = (e: BeforeUnloadEvent) => {
     e.preventDefault();
     return (e.returnValue = warningText);
   };
   window.addEventListener('beforeunload', handleWindowClose);
   return () => {
     window.removeEventListener('beforeunload', handleWindowClose);
   };
 }, []);

  const onSubmit = async(session) =>{
    session.thumbnail = session.drills[0].id.url.replace('.mp4','.jpg')
    await createSession(session)
    navigate('/workouts')
    window.analytics.track('Created Session');
  }

  return (
    <main className='section-container'>
      <SessionForm
        onSubmit={(session)=>onSubmit(session)}
        _generateAsked={false}
        _title={''}
        _notes={''}
        _duration={0}
        _categories={[]}
        _difficulty={''}
        _ages={[]}
        _drills={[]}/>
    </main>
  )
}

export default CreateSession
