import React,{useState,useEffect} from 'react'
import '../../sass/main.scss';
import Header from './header'
import Footer from './footer'
import GroupItem from '../groups/group-item'
import Button from '../common/button'
import { validateGroups } from '../../utils/helpers'

const Groups = ({groups,onCancel,onSave}) =>{
  //===========================
  //STATE
  //===========================
  const [_groups,setGroups] = useState(groups)
  //===========================
  //HANDLERS
  //===========================
  const addGroup = () =>{
    setGroups([..._groups,''])
  }

  const deleteGroup = (index) =>{
    let temp = [..._groups]
    temp.splice(index,1)
    setGroups(temp)
  }

  const saveGroups = () =>{
    if(validateGroups(_groups)){
      onSave(_groups)
    }
  }

  const handleChange = (value,index) =>{
    let temp = [..._groups]
    temp[index] = value
    setGroups(temp)
  }


  return(
    <div className='modal-medium'>
      <Header
        title={'My Groups'}
        onClick={onCancel}/>
      {
        _groups.map((group,index)=>(
          <GroupItem
            key={index}
            item={group}
            handleChange={(value)=>handleChange(value,index)}
            onDelete={()=>deleteGroup(index)}/>
        ))
      }
      <div className='modal-content'>
        <Button
          onClick={addGroup}
          text={'Add a New Group'}
          className='btn btn-block btn-green u-margin-top-10'
          />
      </div>

      <Footer
        onSubmit={saveGroups}
        onCancel={onCancel}/>
    </div>
  )
}

export default Groups
