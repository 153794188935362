import React,{useEffect,useState} from 'react';
import '../../sass/main.scss';
import SessionForm from '../../forms/session'
import { useNavigate,useParams } from 'react-router-dom'
import { getSession,editSession,deleteSession } from '../../services/sessions'
import { toast } from 'react-toastify';

const EditSession = ({}) =>{
  //===============================
  //STATE
  //===============================
  const [state,setState] = useState({title:'',notes:'',duration:null,categories:[],difficulty:'',ages:[],drills:[]})
  const [loading,setLoading] = useState(true)
  //===============================
  //HOOKS
  //===============================
  const { sessionId } = useParams()
  const navigate = useNavigate()
  useEffect(()=>{
    const fetchData = async() =>{
      const response = await getSession(sessionId)
      setState(response)
      setLoading(false)
    }
    fetchData()
  },[])

  useEffect(() => {
   const warningText ='You have unsaved changes - are you sure you wish to leave this page?';
   const handleWindowClose = (e: BeforeUnloadEvent) => {
     e.preventDefault();
     return (e.returnValue = warningText);
   };
   window.addEventListener('beforeunload', handleWindowClose);
   return () => {
     window.removeEventListener('beforeunload', handleWindowClose);
   };
 }, []);

  //===============================
  //HOOKS
  //===============================
  const onSubmit = async(session) =>{
    await editSession(sessionId,session)
    navigate('/workouts')
    window.analytics.track('Edit Session');
  }

  const onDelete = async() =>{
    const {success,program,schedule } = await deleteSession(sessionId)
    if(success === false){
      if(program){
        toast.error('Can not delete a workout that is being used in a program.')
      }else if(schedule){
        toast.error('Can not delete a workout that has been assigned to a player.')
      }
    }else{
      toast.success('Your workout was succefully deleted.')
      navigate('/workouts')
      window.analytics.track('Delete Workout');
    }
  }

  return (
    <main className='section-container'>
      {
        !loading
          &&
          <SessionForm
            onDelete={onDelete}
            onSubmit={(session)=>onSubmit(session)}
            _generateAsked={true}
            _title={state.title}
            _notes={state.notes}
            _duration={state.duration * 60}
            _categories={state.categories}
            _difficulty={state.difficulty}
            _ages={state.ages}
            _drills={state.drills}/>
      }
    </main>
  )
}

export default EditSession
