import React from 'react'
import '../../sass/main.scss';
import Button from '../common/button'


const Footer = ({onSubmit,onCancel,save}) => {
  return (
    <div className='modal-footer'>
      <div className='modal-footer-btn'>
        <Button
          className={'btn btn-grey'}
          text={'Cancel'}
          onClick={onCancel}
        />
      </div>
      <div className='modal-footer-btn'>
        <a onClick={onSubmit} className={'btn btn-green'}>
          { save ? save : 'Save'}
        </a>
      </div>
    </div>
  )
}

export default Footer
