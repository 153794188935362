import React,{useEffect,useState} from 'react';
import '../../sass/main.scss';
import DrillForm from '../../forms/drill'
import { useNavigate,useParams } from 'react-router-dom'
import { createDrill } from '../../services/drills'

const CreateDrill = ({}) =>{
  const navigate = useNavigate()
  useEffect(() => {
   const warningText ='You have unsaved changes - are you sure you wish to leave this page?';
   const handleWindowClose = (e: BeforeUnloadEvent) => {
     e.preventDefault();
     return (e.returnValue = warningText);
   };
   window.addEventListener('beforeunload', handleWindowClose);
   return () => {
     window.removeEventListener('beforeunload', handleWindowClose);
   };
 }, []);

  const onSubmit = async(drill) =>{
    await createDrill(drill)
    navigate('/drills')
    window.analytics.track('Created Drill');
  }

  return (
    <main className='section-container'>
      <DrillForm
        onSubmit={(drill)=>onSubmit(drill)}
        _title={''}
        _category={''}
        _difficulty={''}
        _equipment={[]}
        _space={''}
        _single={false}
        _timed={true}
        _position={null}
        _url={''}
        _instructions={[""]}
        _setUpInstructions={[""]}
        _diagram={''}
        />
    </main>
  )
}

export default CreateDrill
