import React,{useEffect,useState} from 'react';
import '../sass/main.scss';
import Header from '../components/messages/header'
import { getThreads,readMessage,createMultiMessage,getAutomatedMessages,createMultiMessageImage,createMultiMessageVideo } from '../services/messages'
import { editCoach } from '../services/coaches'
import Threads from '../components/messages/threads'
import Chat from '../components/messages/chat'
import Spinner from '../components/common/spinner'
import Modal from '../components/modals/modal'
import BulkMessageModal from '../components/modals/bulk-message'
import AutomatedMessages from '../components/modals/automated-messages'
import Input from '../components/messages/input'
import NoItems from '../components/common/noitems'

const Messages = ({}) =>{
  //====================================
  //STATE
  //====================================
  const [loading,setLoading] = useState(true)
  const [threads,setThreads] = useState([])
  const [thread,setThread] = useState(null)
  const [automated,setAutomated] = useState({
    subscribed:'',
    onboarding:''
  })
  const [messageModalVisible,setMessageModalVisible] = useState(false)
  const [automatedModalVisible,setAutomatedModalVisible] = useState(false)
  //====================================
  //HOOKS
  //====================================
  useEffect(()=>{
    const fetchData = async() =>{
      setLoading(true)
      const response = await getThreads()
      const response2 = await getAutomatedMessages()
      setAutomated(response2)
      setThreads(response)
      if(response.length > 0)setThread(response[0])
      setLoading(false)
      window.analytics.track('Chat');
    }
    fetchData()
  },[])

  //====================================
  //HANDLERS
  //====================================
  const selectedThread = async(thread) =>{
    setThread(thread)
    await readMessage(thread._id)
  }

  const sendMessage = async(threads,message) =>{
    setMessageModalVisible(false)
    await createMultiMessage({threads,text:message})
  }

  const onImage = async(threads,event) =>{
    setMessageModalVisible(false)
    if(event.target.files[0]){
      let file = event.target.files[0]
      const data = new FormData()
      data.append('file',file)
      data.append('threads',JSON.stringify(threads))
      await createMultiMessageImage(data)
    }
  }

  const onVideo = async(threads,event) =>{
    setMessageModalVisible(false)
    if(event.target.files[0]){
      let file = event.target.files[0]
      const data = new FormData()
      data.append('file',file)
      data.append('threads',JSON.stringify(threads))
      await createMultiMessageVideo(data)
    }
  }


  const saveAutomatedMessages = async() =>{
    setAutomatedModalVisible(false)
    await editCoach({automated_messages:automated})
  }


  return(
    <main className='section-container'>
      <Header
        onAutomate={()=>setAutomatedModalVisible(true)}
        onClick = {()=>setMessageModalVisible(true)}
        title={'Messages'}/>
      {
        !loading
          ?threads.length > 0
            ?<section className='messages-grid u-margin-top-20 '>
              <Threads
                onClick={(thread)=>selectedThread(thread)}
                threads={threads}
                selected={thread}/>
              <Chat thread={thread}/>
             </section>
             :<section className='week-container'>
             <NoItems
                 title={'No Messages Have Been Created'}
                 subtitle={'When you have active players you will be able to message them here.'}/>
           </section>
          :<div style={{marginTop:100}}><Spinner/></div>
      }
      <Modal visible={messageModalVisible}>
        <BulkMessageModal
          onImage={(threads,event) =>onImage(threads,event)}
          onVideo={(threads,event) =>onVideo(threads,event)}
          onSend={(threads,message) => sendMessage(threads,message)}
          threads={threads}
          onCancel={()=>setMessageModalVisible(false)}/>
      </Modal>
      <Modal visible={automatedModalVisible}>
        <AutomatedMessages
          onSave={saveAutomatedMessages}
          onChange={(value,key)=>setAutomated({...automated,[key]:value})}
          messages={automated}
          onCancel={()=>setAutomatedModalVisible(false)}/>
      </Modal>

    </main>
  )
}

export default Messages
