import React,{useState} from 'react'
import '../../sass/main.scss'

const PayPerUser = ({onAddPaymentMethod,onLearnMore}) =>{
  const info = (title,content) =>{
    return (
      <div>
        <h3 className='h3-black bold'>{title}:</h3>
        <p className='h3-black u-margin-bottom-20'>
          {content}
        </p>
      </div>
    )
  }
  return (
    <div className='card u-margin-bottom-20'>
      <h2 className='h2-black bold label-header'>Pay Per User</h2>
      <div className='u-padding-20'>
        {info('About','Pay-Per-User is an alternative solution allowing coaches to manage their own payments outside the My Coach platform.')}
        {info('Who is this for','This option is great for coaches who want to receive payment from their players using alternative payment methods. For example, coaches who want to receive cash payments directly from their players, or coaches who would like to include the apps service as a packaged deal with their in-person sessions.')}
        {info('How does it work','Coaches will be able to invite players on the platform via email. Players will receive an email and can accept the invitation. Once a player accepts an invite, they will be added to your roster. ')}
        {info('How billing works','At the end of each month, My Coach will calculate the number of active players on your roster and charge you $10 per player. "Active" means players who have used specific features in the app a certain amount of times that month. If a player is not active that month, they will not count towards your billing. ')}
        <div className='center'>
          <a onClick={onAddPaymentMethod} className='btn btn-purple u-margin-right-5'>Add Payment Method</a>
          <a onClick={onLearnMore} className='btn btn-grey'>Learn More</a>
        </div>
      </div>
    </div>
  )
}

export default PayPerUser
