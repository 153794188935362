import React,{useEffect,useState} from 'react';
import logo from './logo.svg';
import './sass/main.scss';
import { Route,Routes,useNavigate,Navigate } from 'react-router-dom'
import Stats from './pages/stats'
import SideNavBar from './components/side-navbar/side-navbar.component'
import Drills from './pages/drills/drills-table'
import Drill from './pages/drills/drill'
import CreateDrill from'./pages/drills/create-drill'
import EditDrill from'./pages/drills/edit-drill'
import Sessions from './pages/sessions/sessions'
import SessionsPage from './pages/sessions/sessions-table'
import EditSession from './pages/sessions/edit-session'
import CreateSession from './pages/sessions/create-session'
import GenerateSession from './pages/sessions/generate-session'
import Programs from './pages/programs/programs-table'
import CreateProgram from './pages/programs/create-program'
import EditProgram from './pages/programs/edit-program'
import Players from './pages/players/players'
import Player from './pages/players/player'
import Schedule from './pages/players/schedule'
import PlayerStats from './pages/players/stats'
import Favorites from './pages/favorites-table'
import Profile from './pages/profile'
import Dashboard from'./pages/new-dashboard'
import Subscription from'./pages/subscription'
import Messages from'./pages/messages'
import Media from'./pages/media'
import BillingInformation from'./pages/billing-information'
import DeletedPlayers from'./pages/deleted-players'

import Login from './pages/onboarding/new-login'
import SignUp from './pages/onboarding/new-sign-up'
import ForgotPassword from './pages/onboarding/forgot-password'
import AcademyName from './pages/onboarding/academy-name'
import AgeGroups from './pages/onboarding/age-groups'
import SkillLevels from './pages/onboarding/skill-levels'
import Logo from './pages/onboarding/logo'
import Bio from './pages/onboarding/bio'
import CoachApply from './pages/onboarding/coach-apply'

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const App = () =>{
  const [loading,setLoading] = useState(true)
  const [token,setToken] = useState(null)
  // const [onboarding,setOnboarding] = useState(null)
  const [academy,setAcademy] = useState('')
  const [logo,setLogo] = useState('')
  const navigate = useNavigate()
  useEffect(()=>{
    const _token = localStorage.getItem('token');
    // const _onboarding = localStorage.getItem('onboarding')
    setToken(_token)
    // setOnboarding(_onboarding)
    if(_token){
      const _logo = localStorage.getItem('logo')
      const _academy = localStorage.getItem('academy')
      setAcademy(_academy)
      setLogo(_logo)
    }
    // Getting the current URL
    let url = window.location.href;
    setLoading(false)
  },[])


  if(!loading){
    if(token){
      return (
        <main className='main-page-container'>
          <SideNavBar
            logo={logo}
            title={academy} />
          <Routes>
            <Route path={'/'} element={<Dashboard/>}/>
            <Route initial path={'/dashboard'} element={<Dashboard/>}/>
            <Route path={'/stats'} element={<Stats/>}/>
            <Route path={'/drills'} element={<Drills/>}/>
            <Route path={'/drills/:drillId'} element={<Drill/>}/>
            <Route path={'/drills/create'} element={<CreateDrill/>}/>
            <Route path={'/drills/edit/:drillId'} element={<EditDrill/>}/>
            <Route path={'/workouts/create'} element={<CreateSession/>}/>
            <Route path={'/workouts/generate'} element={<GenerateSession/>}/>
            <Route path={'/workouts'} element={<SessionsPage/>}/>
            <Route path={'/workouts/edit/:sessionId'} element={<EditSession/>}/>
            <Route path={'/programs'} element={<Programs/>}/>
            <Route path={'/programs/create'} element={<CreateProgram/>}/>
            <Route path={'/programs/edit/:programId'} element={<EditProgram/>}/>
            <Route path={'/players'} element={<Players/>}/>
            <Route path={'/players/:playerId'} element={<Player/>}/>
            <Route path={'/players/schedule/:playerId'} element={<Schedule/>}/>
            <Route path={'/players/stats/:playerId/:status'} element={<PlayerStats/>}/>
            <Route path={'/media'} element={<Media/>}/>
            <Route path={'/messages'} element={<Messages/>}/>
            <Route path={'/profile'} element={<Profile/>}/>
            <Route path={'/subscription-pricing'} element={<Subscription/>}/>
            <Route path={'/billing-information'} element={<BillingInformation/>}/>
            <Route path={'/deleted-players'} element={<DeletedPlayers/>}/>
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          <ToastContainer bodyClassName="toastBody"/>
        </main>
      );
    }else{
      return (
        <main className='main-page-container'>
          <Routes>
            <Route path={'/login'} element={<Login/>}/>
            <Route path={'/apply'} element={<CoachApply/>}/>
            <Route path={'/sign-up'} element={<SignUp/>}/>
            <Route path={'/forgot-password'} element={<ForgotPassword/>}/>
            <Route path={'/academy-name'} element={<AcademyName/>}/>
            <Route path={'/age-groups'} element={<AgeGroups/>}/>
            <Route path={'/levels'} element={<SkillLevels/>}/>
            <Route path={'/logo'} element={<Logo/>}/>
            <Route path={'/bio'} element={<Bio/>}/>
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
          <ToastContainer bodyClassName="toastBody"/>
        </main>
      );
    }
  }else{
    <main className='main-page-container'></main>
  }

}

export default App;
