import React from 'react'
import '../../sass/main.scss'
import { FaCheck } from "react-icons/fa";

const ContentCard = ({onClick,selected,onSelect,url}) =>{
  const styles = {
    backgroundImage:'url('+ url +')'
  }

  return (
    <div onClick={onClick} className='content-card' style={styles}>
      <div
        onClick={(event)=>{
          event.stopPropagation();
          onSelect()
        }}
        className={selected ? 'content-card-circle content-card-circle-active' : 'content-card-circle'}>
        {
          selected && <FaCheck color='white' size={15}/>
        }
      </div>
    </div>
  )
}

export default ContentCard
