import axios from 'axios';
import { API } from '../utils/variables'
import { getConfig } from '../utils/helpers'
import { toast } from 'react-toastify';
//get all programs
export const getPrograms = async () =>{
  try{
    const config = await getConfig()
    const route = `${API}/programs/`;
    const response = await axios.get(route,config)
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}

//get one program
export const getProgram = async (id) =>{
  try{
    const config = await getConfig()
    const route = `${API}/programs/${id}`;
    const response = await axios.get(route,config)
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}

//create program
export const createProgram = async (body) =>{
  try{
    const config = await getConfig()
    const route = `${API}/programs/`;
    const response = await axios.post(route,body,config)
    toast.success('Your program was succefully created.')
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}

//edit program
export const editProgram = async (id,body) =>{
  try{
    const config = await getConfig()
    const route = `${API}/programs/${id}`;
    const response = await axios.put(route,body,config)
    toast.success('Your program was succefully saved.')
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}

//delete program
export const deleteProgram = async (id) =>{
  try{
    const config = await getConfig()
    const route = `${API}/programs/${id}`;
    const response = await axios.delete(route,config)
    toast.success('Your program was succefully deleted.')
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}

//delete many programs
export const deleteManyPrograms = async (ids) =>{
  try{
    const config = await getConfig()
    const route = `${API}/programs`;
    const response = await axios.put(route,{programIds:ids},config)
    toast.success('Your programs were succefully deleted.')
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}
