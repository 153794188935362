import axios from 'axios';
import { API } from '../utils/variables'
import { toast } from 'react-toastify';

//login
export const login = async (body) =>{
  try{
    const route = `${API}/mycoach-login`;
    const response = await axios.post(route,body)
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}

//register
export const register = async (body) =>{
  try{
    const route = `${API}/mycoach-register`;
    const response = await axios.post(route,body)
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}

export const forgotPassword = async (body) =>{
  try{
    const route = `${API}/mycoach-forgot-password`;
    const response = await axios.post(route,body)
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}

export const resetPassword = async (body) =>{
  try{
    const route = `${API}/mycoach-reset-password`;
    const response = await axios.post(route,body)
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}

export const createShortLink = async (id) =>{
  try{
    const id = localStorage.getItem('id')
    const api_key = 'AIzaSyDA5UziJCXyhQTa1ftL8V7GbWVKbo9LxAA'
    const route = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${api_key}`;
    const headers = {
      headers: {'Content-Type': "application/json"}
    };
    const body = {
      "dynamicLinkInfo": {
        "domainUriPrefix": "https://mycoachsports.page.link",
        "link": `https://mycoachapp.co/invite?id=6358418ce3627f2375fbd9f9`,
        "iosInfo": {
          "iosBundleId": "co.mycoachapp.mycoach",
          "iosAppStoreId": '1629966460',
        }
      }
    }
    const response = await axios.post(route,body,headers)
    console.log(response.data)
    // return response.data
  }catch(err){
    console.log(err)
    toast.error('An unexpected error occured')
  }
}
