import React,{useState,useEffect} from 'react';
import '../../sass/main.scss';
import { getPrograms,deleteManyPrograms } from '../../services/programs'
import Header from '../../components/programs/header'
import Program from '../../components/cards/program'
import { useNavigate } from 'react-router-dom'
import Spinner from '../../components/common/spinner'
import NoItems from '../../components/common/noitems'
import Table from '../../components/common/table'
import Modal from '../../components/modals/modal'
import DeleteModal from '../../components/modals/delete'
const Programs = ({}) =>{
  //====================================
  // STATE
  //====================================
  const [loading,setLoading] = useState(true)
  const [programs,setPrograms] = useState([])
  const [checked,setChecked] = useState([])
  const [indecies,setIndecies] = useState([])
  const [deleteModalVisible,setDeleteModalVisible] = useState(false)
  const Headers = [
    {label:'',value:'_id'},
    {label:'',value:'thumbnail'},
    {label:'Title',value:'title'},
    {label:'Duration',value:'sessions'},
    {label:'Difficulty',value:'difficulty'},
    {label:'Created',value:'created'}
  ]

  //====================================
  // HOOKS
  //====================================
  const navigate = useNavigate()
  useEffect(()=>{
    setLoading(true)
    const fetchData = async() =>{
      const response = await getPrograms()
      setPrograms(response)
      setLoading(false)
      window.analytics.track('Programs');
    }
    fetchData()
  },[])

  const onCheck = (session) =>{
    const {id,idx} = session
    const index = checked.indexOf(id)
    if(index === -1){
      setChecked([...checked,id])
      setIndecies([...indecies,idx])
    }else{
      let temp = [...checked]
      let temp2 = [...indecies]
      temp.splice(index,1)
      temp2.splice(index,1)
      setChecked(temp)
      setIndecies(temp2)
    }
  }

  const onBulkDelete = async() =>{
    await deleteManyPrograms(checked)
    let temp =[...programs]
    temp = temp.filter(function(value, index) {
         return indecies.indexOf(index) == -1;
    })
    setPrograms(temp)
    setIndecies([])
    setChecked([])
  }

  return (
    <main className='section-container'>
      <Header
        onDelete={()=>setDeleteModalVisible(true)}
        canDelete={checked.length}
        title={'My Programs'}/>
      {
        !loading
          ?programs.length > 0
            ?<section className='week-container'>
                <Table
                  lessPadding
                  checked={checked}
                  onCheck={(id) => onCheck(id)}
                  onDrill={(id)=>navigate('/programs/edit/'+id)}
                  headers={Headers}
                  rows={programs}/>
              </section>
            :<section className='week-container'>
            <NoItems
                title={'Create your First Program'}
                subtitle={'Click the button below to get started'}
                button={'Create a Program'}
                onClick={()=>navigate('/programs/create')}/>
          </section>
          :<div style={{marginTop:100}}><Spinner/></div>
      }
      <Modal visible={deleteModalVisible}>
        <DeleteModal
          title={'Delete Programs'}
          text={'Are you sure you want to delete these programs? They will be permanetly deleted.'}
          onDelete={onBulkDelete}
          onCancel={()=>setDeleteModalVisible(false)}/>
      </Modal>

    </main>
  )
}

export default Programs;
