import React from 'react'
import '../../sass/main.scss'

const GenerateDifficultyQuestion = ({selected,onSelect}) =>{
  const renderButton = (value,label) =>{
    return (
      <a
        onClick={()=>onSelect(value)}
        className={selected === value ? 'u-margin-bottom-10 btn btn-green btn-block' : 'u-margin-bottom-10 btn btn-border-green btn-block'}>
      {label}
    </a>
)
  }
  return (
    <div>
      {renderButton('Novice','Novice')}
      {renderButton('Beginner','Beginner')}
      {renderButton('Intermediate','Intermediate')}
      {renderButton('Advanced','Advanced')}
      {renderButton('Expert','Expert')}
    </div>
  )
}

export default GenerateDifficultyQuestion
