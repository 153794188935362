import React,{useRef} from 'react'
import '../../sass/main.scss'
import Spinner from './spinner'
import Button from './button'

const FormLogo = ({value,error,label,name,handleChange,className,loading}) =>{
  const inputFile = useRef(null)
  return (
    <div className={className ? className : 'group'}>
      {
        label && <label className='h3-black bold u-margin-bottom-15'>{label}</label>
      }
      {
       !loading
          ?<div>
            <div>
              <img src={value} width='150' height='150' className='form-input-logo'/>
              <input
                accept="image/*"
                onInput={handleChange}
                type='file'
                name={name}
                ref={inputFile}
                style={{display: 'none'}}/>
            </div>
            <Button
              onClick={()=>inputFile.current.click()}
              text={'Edit Logo'}
              className={'btn btn-green u-margin-top-15'}/>
          </div>

          :<Spinner/>
      }

      {error && <p>{error}</p>}
    </div>
  )
}

export default FormLogo
