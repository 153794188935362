import React,{useEffect,useState} from 'react';
import '../sass/main.scss';
import { getSubscriptionPrice,editCoach } from '../services/coaches'
import { updateSubscriptionPrice } from '../services/subscriptions'
import Spinner from '../components/common/spinner'
import Header from '../components/subscription/header'
import CurrencyInput from '../components/common/form-currency'
import Select from '../components/common/form-select'
import { Prices,Trials } from '../utils/variables'

const Subscription = ({}) =>{
  //============================
  //STATE
  //============================
  const [loading,setLoading] = useState(false)
  const [subscription,setSubscription] = useState({price:0,trial:'No'})
  const [price,setPrice] = useState(0)
  const [select,setSelect] = useState(null)
  const [status,setStatus] = useState(null)
  const [trial,setTrial] = useState(null)
  //============================
  //HOOKS
  //============================
  useEffect(()=>{
    setLoading(true)
    const fetchData = async() =>{
      const response = await getSubscriptionPrice()
      const { price,label,status,trial } = response
      setPrice(price)
      setSelect({value:price,label:label})
      setTrial({value:trial,label:trial + ' Days'})
      setStatus(status)
      setSubscription({price,trial})
      setLoading(false)
    }
    fetchData()
  },[])
  //============================
  //HANDLERS
  //============================
  const onChangeValue = (event) =>{
    setTrial(event.target.value)
  }

  const onSubmit = async() =>{
    let subscription_price = {
      price,
      label:select.label,
      trial: trial.value,
      status:'In Review',
    }
    await updateSubscriptionPrice({subscription_price})
    window.location.reload(false);
  }

  if(!loading){
    return(
      <main className='section-container'>
        <Header
          onClick={status !== 'In Review' ? onSubmit : null}
          btnClass= {status !== 'In Review' ? 'btn btn-purple' : 'btn btn-disabled'}
          title={'Subscription Pricing'}
          status={status}/>
        <section className='form-container'>
          <Select
            className='group u-margin-bottom-20'
            onValueChange={(value) => {
              setSelect(value)
              setPrice(value.value)
            }}
            value={select}
            label='Subscription Monthly Pricing'
            options={Prices}/>
          <Select
            className='group u-margin-bottom-20'
            onValueChange={(value) => {
              setTrial(value)
            }}
            value={trial}
            label='Free Trial Period'
            options={Trials}/>

        </section>
      </main>
    )
  }else{
    return(
      <main className='section-container'>

      </main>
    )
  }

}

// <label className='h3-black bold'>Include 7 day FREE trial?</label>
//   <div onChange={onChangeValue} className='body1-grey bold u-margin-top-10'>
//     <div className='u-margin-bottom-10'>
//       <input checked={trial === "Yes"} type="radio" value="Yes" name="trial" />
//       <label style={{marginLeft:5,marginRight:10}}>Yes</label>
//     </div>
//     <div>
//       <input checked={trial === "No"} type="radio" value="No" name="trial" />
//       <label style={{marginLeft:5}}>No</label>
//     </div>
//   </div>

export default Subscription
