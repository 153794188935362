import React from 'react'
import '../../sass/main.scss'
import AddCard from '../cards/add'
import SessionCard from '../cards/session'
import Button from '../common/button'
import { SortableContainer,SortableElement } from 'react-sortable-hoc'

const Week = ({number,sessions,addWorkout,deleteWeek,onDelete,onSortEnd}) =>{
  const SortableItem = SortableElement(({ value, index}) =>(
    <SessionCard
      onDelete={()=>onDelete(value)}
      key={value._id}
      {...value}/>
  ))

  const SortableList = SortableContainer(({ items}) =>{
    return(
      <div className='grid-four'>
        {
          items.map((value,index) =>(
          <SortableItem
            value={value}
            index={index}
            key={value._id}/>
        ))

       }
       <AddCard title={'Add a Workout'} onClick={addWorkout}/>
      </div>
    )
  })

  return (
    <section className='month-no-back'>
      <div className='week-header'>
        <h3 className='h3-black bold u-margin-bottom-15'>Week {number}</h3>
        <div style={{textAlign:'right',flex:1}}>
          <Button
            onClick={deleteWeek}
            text='Delete Week'
            className ='btn btn-border-light-red'/>
        </div>

      </div>
      <SortableList distance={1} items={sessions} onSortEnd={onSortEnd} axis='xy'/>
    </section>
  )
}

export default Week;
