import React from 'react'
import '../../sass/main.scss'
import { FaPlay,FaPlayCircle,FaMinusCircle,FaTrashAlt } from 'react-icons/fa';

const SessionCard = ({title,_id,thumbnail,difficulty,categories,onClick,active,onDelete}) =>{
  return (
    <a className={'card'} onClick={onClick}>

      <div className={onDelete ? 'thumbnail' : 'thumbnail play-icon'} style={{backgroundImage:`url(${thumbnail})`}}>
        <div className='overlay'>
          {
            onDelete
             &&
             <div className='remove-icon'>
               <FaTrashAlt onClick={onDelete} size={20} color={'red'}/>
             </div>
          }
        </div>
      </div>
      <section className='card-bottom'>
        <p className='body1-black bold'>{title}</p>
        <div className='tags'>
          <span className='tag tag-grey'>{difficulty}</span>
          {
            categories && <span className='tag tag-grey'>{categories[0]}</span>
          }
        </div>
      </section>
    </a>
  )
}

export default SessionCard
