import React from 'react'
import '../../sass/main.scss'

const Instruction = ({number,text}) =>{
  return (
    <div className='instructions'>
      <div className='instructions-circle'>
        <h3 className='h3-black bold'>{number}</h3>
      </div>
      <p className='body1-grey bold instructions-text'>{text}</p>
    </div>
  )
}

export default Instruction
