import React from 'react'
import '../../sass/main.scss'

const FormInput = ({label,type,handleChange,name,value,placeholder,required,error,maxlength,className}) =>{
  return (
    <div className={className ? className : 'group'}>
      {
        label && <label className='h3-black bold u-margin-bottom-15'>{label}</label>
      }
      <input
        type={type}
        className={error ? 'form-input form-error' : 'form-input'}
        placeholder={placeholder}
        onChange={handleChange}
        name={name}
        maxLength={maxlength}
        value={value}
        required={required}/>
      {error && <p>{error}</p>}
    </div>
  )
}

export default FormInput
