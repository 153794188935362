import React,{useEffect,useState} from 'react'
import '../../sass/main.scss'

const Message = ({message,current}) =>{
  const align = current ? {textAlign:'right'} : {textAlign:'left'}

  const renderMessage = () =>{
    if(message.message){
      return <span className={current ? 'message message-right' : 'message message-left'}>{message.message}</span>
    }else if(message.image){
      return <img src={message.image} style={{borderRadius:5}} width={200} height={200}/>
    }else if(message.video){
      return (
        <video
          style={{borderRadius:5}}
          width={200}
          height={200}
          controls >
           <source src={message.video} type="video/mp4"/>
        </video>
      )
    }
  }
  return(
    <div className='message-container' style={align}>
      {renderMessage()}
    </div>
  )
}

export default Message
