import React,{useState} from 'react'
import '../../sass/main.scss'
import DropDown from '../common/dropdown'
import { Categories,Difficulty,Equipment,Space } from '../../utils/variables'
import { useDispatch } from 'react-redux'
import { updateCategories,updateDifficulty, updateEquipment,updateSpace } from '../../redux/actions'
import ClearFilter from '../common/clear-filters'

const Filters = ({categories,difficulty,equipment,space}) =>{
  //=============================
  //STATE
  //=============================
  const [visible,setVisible] = useState(null)
  //==============================================
  //DISPATCH
  //==============================================
  const dispatch = useDispatch()
  const _updateCategories = (categories) => { dispatch(updateCategories(categories)) }
  const _updateDifficulty = (difficulty) => { dispatch(updateDifficulty(difficulty)) }
  const _updateEquipment  = (equipment)  => { dispatch(updateEquipment(equipment)) }
  const _updateSpace  = (space)  => { dispatch(updateSpace(space)) }
  //=============================
  //HANDLERS
  //=============================
  const toggleDropDown = (label) => {
    if(visible == label){
      setVisible(null)
    }else{
      setVisible(label)
    }
  }

  const handleChange = (values,label,item) =>{
    let array = [...values]
    if(array.includes(item)){
      let index = array.indexOf(item)
      array.splice(index,1)
    }else{
      array.push(item)
    }

    switch (label) {
      case 'categories':
        _updateCategories(array)
        break;
      case 'difficulty':
        _updateDifficulty(array)
       break;
      case 'equipment':
        _updateEquipment(array)
        break;
      case 'space':
        _updateSpace(array)
        break;
      default:

    }
  }

  const clearFilters = () =>{
    if(
      categories.length !== 0 ||
      equipment.length  !== 0 ||
      difficulty.length !== 0 ||
      space.length      !== 0
    ){
       setVisible(null)
      _updateCategories([])
      _updateDifficulty([])
      _updateEquipment([])
      _updateSpace([])
    }
  }


  return (
    <div className='filters'>
      <DropDown
        handleChange={(item) => handleChange(categories,'categories',item)}
        values={categories}
        items={Categories}
        toggle={()=>toggleDropDown('Categories')}
        label={'Categories'}
        visible={visible == 'Categories'}/>
      <DropDown
        handleChange={(item) => handleChange(difficulty,'difficulty',item)}
        values={difficulty}
        items={Difficulty}
        toggle={()=>toggleDropDown('Difficulty')}
        label={'Difficulty'}
        visible={visible == 'Difficulty'}/>
      <DropDown
        handleChange={(item) => handleChange(equipment,'equipment',item)}
        values={equipment}
        items={Equipment}
        toggle={()=>toggleDropDown('Equipment')}
        label={'Equipment'}
        visible={visible == 'Equipment'}/>
      <DropDown
        handleChange={(item) => handleChange(space,'space',item)}
        values={space}
        items={Space}
        toggle={()=>toggleDropDown('Space')}
        label={'Space'}
        visible={visible == 'Space'}/>
      <ClearFilter onClick={clearFilters}/>
    </div>
  )
}

export default Filters
