import React,{useState,useEffect} from 'react'
import '../../sass/main.scss';
import Button from '../common/button'

const Delete = ({title,text,onCancel,onDelete,deleteText}) =>{
  return (
    <div className='modal-small'>
      <div className='modal-content'>
        <h2 className='h2-black bold u-margin-bottom-10'>{title}</h2>
        <p className='body1-grey bold center u-margin-bottom-10'>{text}</p>
        <Button
          className='btn btn-block btn-light-red u-margin-bottom-5'
          text={deleteText || 'Delete'}
          onClick={()=>{
            onDelete()
            onCancel()
          }}/>
        <Button className='btn btn-block btn-grey' text={'Cancel'} onClick={onCancel}/>
      </div>
    </div>
  )
}

export default Delete
