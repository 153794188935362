import React,{useState,useEffect} from 'react'
import '../../sass/main.scss';
import Header from './header'
import DrillItem from '../drills/drill-item'

const Stats = ({title,drills,drillsComplete,onCancel,rating,comment}) =>{

  const getRating = () =>{
    switch (rating) {
      case 1:
        return 'Too Easy'
      case 2:
        return 'Just Right'
      case 3:
        return 'Too Hard'
      default:
        return 'Just Right'

    }
  }
  return (
    <div className='modal-medium'>
      <Header
        title={title}
        onClick={onCancel}>
        <span className='tag tag-green'>{Math.floor((drillsComplete.length/drills.length)*100)}% Completed</span>
        {
          rating !== 0
          &&
          <h3 className='bold u-margin-top-10'>Overall Rating: {getRating()}</h3>
        }
        {
          comment
           &&
            <p>{comment}</p>
        }
      </Header>
      <div className='modal-content modal-content-scrollable'>
        {
          drills.map((drill,index)=>(
            <DrillItem
              key={index}
              index={index+1}
              {...drill.id}
              {...drill}
              completed={drillsComplete.includes(drill.id._id)}/>
          ))
        }
      </div>
    </div>
  )
}

export default Stats
