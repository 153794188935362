import React from 'react'
import '../../sass/main.scss'

const Spinner = ({}) =>{
  return (
    <div className="spinner-container">
      <div className="loading-spinner">
      </div>
    </div>
  )
}

export default Spinner
