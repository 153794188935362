import React from 'react'
import '../../sass/main.scss'

const InitialQuestions = ({improve,level,message,videos,days}) =>{
  return(
    <section className='form-container'>
      <h3 className='h3-black bold u-margin-bottom-15'>Initial Questions</h3>
      <div className='u-margin-bottom-15'>
        <p className='body1-black bold'>What do you want to improve on the most?</p>
        <p className='body1-grey bold'>{improve.join(', ')}</p>
      </div>
      <div className='u-margin-bottom-15'>
        <p className='body1-black bold'>How many days do you train each week?</p>
        <p className='body1-grey bold'>{days}</p>
      </div>
      <div className='u-margin-bottom-15'>
        <p className='body1-black bold'>What level do you currently play at?</p>
        <p className='body1-grey bold'>{level}</p>
      </div>
      <div className='u-margin-bottom-15'>
        <p className='body1-black bold'>What are your goals?</p>
        <p className='body1-grey bold'>{message ? message : '----'}</p>
      </div>
      <div>
        <p className='body1-black bold u-margin-bottom-15 '>Highlight clips?</p>
        <div className='grid-four'>
          {
            videos.map((video,index)=>(
              <video
                key={index}
                style={{borderRadius:10,width:'100%',height:200}}
                controls >
                 <source src={video} type="video/mp4"/>
              </video>
            ))
          }
        </div>
      </div>
    </section>
  )
}

export default InitialQuestions
