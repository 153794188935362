import React,{useState} from 'react'
import '../../sass/main.scss';
import Header from './header'
import Footer from './footer'
import SessionsCard from '../cards/session'
import Table from '../../components/common/table'

const SessionsModal = ({sessions,onCancel,onSave}) =>{
  //==================================
  //STATE
  //==================================
  const [active,setActive] = useState(0)
  const [selectedSessions,setSelectedSessions] = useState([])
  const Headers = [
    {label:'',value:'thumbnail'},
    {label:'Title',value:'title'},
    {label:'Duration',value:'duration'},
    {label:'Difficulty',value:'difficulty'},
    {label:'Created',value:'created'}
  ]
  //==================================
  //HANDLERS
  //==================================
  const onSelectSession = (session) =>{
    if(selectedSessions.includes(session)){
      let tempSessions = [...selectedSessions]
      const foundIndex = selectedSessions.indexOf(session)
      tempSessions.splice(foundIndex,1)
      setSelectedSessions(tempSessions)
    }else{
      setSelectedSessions([...selectedSessions,session])
    }
  }

  return (
    <div className='modal-large'>
      <Header
        title={'Select a Workout'}
        onClick={onCancel}/>
      <div style={{height:'75vh'}} className='modal-grey modal-content modal-content-scrollable'>
        <section className='week-container'>
            <Table
              lessPadding
              onSelect={(session) => onSave(session)}
              headers={Headers}
              rows={sessions}/>
          </section>
      </div>

    </div>
  )
}

export default SessionsModal;
