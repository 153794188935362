import React from 'react';
import '../../sass/main.scss';
import { FaChevronLeft } from "react-icons/fa";
import { useNavigate } from 'react-router-dom'

const NavBar = ({title,subtitle,onBack}) =>{
  const navigate = useNavigate()
  return (
    <nav className='nav-onboarding'>
        {
          onBack
            &&
            <div className='nav-back u-margin-right-10'>
              <FaChevronLeft onClick={onBack} size={25}/>
            </div>
        }
        <div>
          <h2 className='h2-black bold'>{title}</h2>
            {
              subtitle
                &&
                <p className='body1-grey bold'>{subtitle}</p>
            }
        </div>


    </nav>
  )
}

export default NavBar
