import React,{useEffect,useState} from 'react'
import Input from '../../components/common/form-input'
import { login } from '../../services/login'
import { Route,Routes,useNavigate,Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { validateInfo } from '../../utils/helpers'

const Login = ({}) =>{
  //================================
  //STATE
  //================================
  const [username,setUsername] = useState('')
  const [password,setPassword] = useState('')
  const [errors,setErrors] = useState({username:'',password:''})
  //================================
  //HOOKS
  //================================
  const navigate = useNavigate()
  // useEffect(()=>{
  //   const onboarding = localStorage.getItem('onboarding')
  //   const token = localStorage.getItem('token')
  //   if(token && !onboarding){
  //     navigate('/academy-name')
  //   }
  // },[])

  const handleSubmit = () =>{
    if(username && password){
      _login()
    }
    // const keys = {username:true,password:true}
    // let errorMessages = validateInfo({username,password},keys)
    // if(Object.entries(errorMessages).length === 0){
    //   setErrors({username:'',password:''})
    //   _login()
    // } else{
    //   setErrors(errorMessages)
    // }
  }

  const _login = async() =>{
    const response = await login({username,password,account_type:'Coach'})
    if(response.success){
      window.analytics.identify(response.user.id,{
        name:response.user.name,
        username:response.user.username
      })
      window.analytics.track('Signed In');
      localStorage.setItem('id',response.user.id)
      localStorage.setItem('token',response.token)
      localStorage.setItem('logo',response.user.logo)
      localStorage.setItem('academy',response.user.academy)
      localStorage.setItem('onboarding','true')
      if (typeof window !== 'undefined') {
           window.location.href = "https://coach.mycoachapp.co/dashboard";
      }
    }else{
      toast.error(response.msg)
    }

  }

  return (
    <main>
      <section className='onboarding-container'>
        <h2 className='h2-black bold u-margin-bottom-20'style={{textAlign:'center'}}>To continue, log in to My Coach.</h2>
          <Input
              autocapitalize={'none'}
              className='group u-margin-bottom-10'
              error={errors.username}
              type={'text'}
              placeholder={'Email Address'}
              name={'username'}
              value={username}
              handleChange={(event) => setUsername(event.target.value)}
              required={true}/>
            <Input
              className='group u-margin-bottom-20'
              error={errors.password}
              type={'password'}
              placeholder={'Password'}
              name={'password'}
              value={password}
              handleChange={(event) => setPassword(event.target.value)}
              required={true}/>
            <a onClick={handleSubmit}style={{display:'block'}} className='btn btn-lrg btn-green u-margin-bottom-20'>SIGN IN</a>
            <a onClick={()=>navigate('/forgot-password')}style={{display:'block',textAlign:'center',marginBottom:40,color:'#0DCE7C'}}>Forgot Password?</a>
            <hr style={{border:'.5px solid #ddd',marginBottom:30}}/>
            <h3 className='h3-black bold u-margin-bottom-20'style={{textAlign:'center'}}>Don&apos;t have an account?</h3>

            <a
              onClick={()=>navigate('/sign-up')}
              style={{display:'block'}}
              className='btn btn-lrg btn-border-green u-margin-bottom-20'>SIGN UP FOR MY COACH</a>
            <p className='body1-grey bold u-margin-top-20' style={{textAlign:'center'}}>
               By clicking SIGN IN, you agree to our
               <a href="https://mycoachapp.co/terms-of-use" target="_blank" style={{color:'#0DCE7C'}}> Terms</a>
               ,
               <a href="https://mycoachapp.co/privacy-policy" target="_blank" style={{color:'#0DCE7C'}}> Privacy Policy </a>
               ,
               <a href="https://mycoachapp.co/coach-terms" target="_blank" style={{color:'#0DCE7C'}}> Coaches Terms </a>
               and
               You may receive Email Notifications from us and can opt out any time.
             </p>
      </section>
    </main>
  )
}

export default Login
