import React,{useState,useEffect} from 'react';
import '../../sass/main.scss';
import Input from '../../components/common/form-input'
import Button from '../../components/common/button'
import { editCoach } from '../../services/coaches'
import { toast } from 'react-toastify';
import NavBar from '../../components/onboarding/navbar'
import { useNavigate } from 'react-router-dom'
import FormMultipleButton from '../../components/common/form-multiple-button'

const SkillLevels = ({}) =>{
  //===============================
  //STATE
  //===============================
  const [levels,setLevels] = useState([])
  const navigate = useNavigate()
  useEffect(()=>{
    const token = localStorage.getItem('token');
    if(!token) navigate('/login')
  },[])
  //===============================
  //HANDLERS
  //===============================
  const onSubmit = async() =>{
    if(levels.length > 0){
      await editCoach({skill_levels:levels})
      navigate('/logo')
    }

  }

  const handleSkillChange = (item) =>{
    if(levels.includes(item)){
      let array = [...levels]
      let index = array.indexOf(item)
      array.splice(index,1)
      setLevels(array)
    }else{
      setLevels([...levels,item])
    }
  }

  return(
    <section className='onboarding-container'>
      <NavBar
        onBack={()=>navigate('/age-groups')}
        title={'What levels do you coach?'}
        subtitle={'Select at least one level.'}
      />
      <FormMultipleButton
        className='group u-margin-bottom-20'
        items={['Recreational','Club','College','Semi-Pro','Professional']}
        handleChange={(item)=> handleSkillChange(item)}
        values={levels}/>
      <Button
        onClick={onSubmit}
        text={'Next'}
        className='btn btn-lrg btn-block btn-green u-margin-top-10'
        />
    </section>
  )
}

export default SkillLevels
