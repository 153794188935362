import React from 'react'
import '../../sass/main.scss'
import Button from '../common/button'
import Filters from './filters'
import Search from '../../components/common/search-bar'
import TabBar from './tab-bar'

const Header = ({title,categories,difficulty,equipment,space,search,onSearch,onCreate,selected,onSelect}) =>{
  return (
    <div className='all-drills-header' style={{paddingBottom:0}}>
      <div className='clear-fix'>
        <h1 className='h1-black bold'>{title}</h1>
        {
          /*
          <div className='clear-fix-right'>
            <Button onClick={onCreate} text={'+ Create Drill'} className={'btn btn-purple'}/>
          </div>
          */
        }


      </div>
      <Filters categories={categories} difficulty={difficulty} equipment={equipment} space={space}/>
      <div className='u-margin-top-20 u-margin-bottom-20'>
        <Search
          type={'text'}
          placeholder={'Search for all drills'}
          name={'search'}
          value={search}
          handleChange={(event) => onSearch(event.target.value)}/>
      </div>
      <TabBar
        selected={selected}
        onSelect={(tab)=>onSelect(tab)}
        />

    </div>
  )
}

export default Header
