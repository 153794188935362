import axios from 'axios';
import { API } from '../utils/variables'
import { getConfig } from '../utils/helpers'
import { toast } from 'react-toastify';

//get threads
export const getThreads = async () =>{
  try{
    const config = await getConfig()
    const route = `${API}/threads`;
    const response = await axios.get(route,config)
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}

//get messages
export const getMessages = async (thread) =>{
  try{
    const config = await getConfig()
    const route = `${API}/messages/${thread}`;
    const response = await axios.get(route,config)
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}

//create messages
export const createMessage = async (thread,body) =>{
  try{
    const config = await getConfig()
    const route = `${API}/messages/${thread}/`;
    const response = await axios.post(route,body,config)
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}

//read messages
export const readMessage = async (thread) =>{
  try{
    const config = await getConfig()
    const route = `${API}/coach-read/${thread}/`;
    const response = await axios.put(route,{},config)
    return response.data
  }catch(err){
  }
}

//create bulk messages
export const createMultiMessage = async (body) =>{
  try{
    const config = await getConfig()
    const route = `${API}/multi-messages/`;
    const response = await axios.post(route,body,config)
    toast.success('Your message was succefully sent')
    return response.data
  }catch(err){
    console.log(err)
    toast.error('An unexpected error occured')
  }
}

//image message
export const messageImage = async (thread,body) =>{
  try{
    const config = await getConfig()
    const route = `${API}/message-image/${thread}/`;
    const response = await axios.post(route,body,config)
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}

//video message
export const messageVideo = async (thread,body) =>{
  try{
    const config = await getConfig()
    const route = `${API}/message-video/${thread}/`;
    const response = await axios.post(route,body,config)
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}

//get automated messages
export const getAutomatedMessages = async () =>{
  try{
    const config = await getConfig()
    const route = `${API}/automated-messages`;
    const response = await axios.get(route,config)
    return response.data
  }catch(err){
    // toast.error('An unexpected error occured')
  }
}

//create bulk messages image
export const createMultiMessageImage = async (body) =>{
  try{
    const config = await getConfig()
    const route = `${API}/multi-message-image/`;
    const response = await axios.post(route,body,config)
    toast.success('Your image was succefully sent')
    return response.data
  }catch(err){
    console.log(err)
    toast.error('An unexpected error occured')
  }
}

//create bulk messages video
export const createMultiMessageVideo = async (body) =>{
  try{
    const config = await getConfig()
    const route = `${API}/multi-message-video/`;
    const response = await axios.post(route,body,config)
    toast.success('Your video was succefully sent')
    return response.data
  }catch(err){
    console.log(err)
    toast.error('An unexpected error occured')
  }
}
