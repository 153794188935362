import React,{useState} from 'react'
import '../../sass/main.scss'
import Avatar from 'react-avatar'

const Thread = ({thread,selected,onClick}) =>{
  const [unread,setUnread] = useState(thread.coach_unread)
  return(
    <div
      onClick={()=>{
        setUnread(0)
        onClick(thread)
      }}
      className={thread._id === selected._id ? 'threads-row threads-row-selected' : 'threads-row'}>
      <Avatar
        src={thread.player.profile_pic}
        round
        size={40}/>
      <div style={{alignSelf:'center',marginLeft:5}}>
        <p className='h3-black bold'>{thread.player.name}</p>
      </div>
      {
        unread > 0
          &&
          <div style={{flex:1,textAlign:'right',alignSelf:'center'}}>
            <span className='thread-unread'>{unread}</span>
          </div>
      }
    </div>
  )
}

export default Thread
