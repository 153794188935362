import { toast } from 'react-toastify';

export const validateInfo = (values,keys):any =>{
  let errors:any = {}
  if(keys.username){
    if(!values.username){
      errors.username = 'Email is required'
    }else if(!validateEmail(values.username)){
      errors.username = 'Invalid email address'
    }
  }

  if(keys.email){
    if(!values.email){
      errors.email = 'Email is required'
    }else if(!validateEmail(values.email)){
      errors.email = 'Invalid email address'
    }
  }

  if(keys.password){
    if(!values.password){
      errors.password = 'Password is required'
    }else if(values.password.length < 7){
      errors.password = 'Password must contain at least 7 characters'
    }
  }


  if(keys.academy && !values.academy){
    errors.academy = 'Academy name is required'
  }

  if(keys.fname && !values.fname){
    errors.fname = 'First name is required'
  }

  if(keys.lname && !values.lname){
    errors.lname = 'Last name is required'
  }


  if(keys.title && !values.title){
    errors.title = 'Title is required'
  }

  if(keys.difficulty && values.difficulty === ''){
    errors.difficulty = 'Difficulty is required'
  }


  if(keys.ages && values.ages.length === 0){
    errors.ages = 'Select at least one age'
  }

  if(keys.categories && values.categories.length === 0){
    errors.categories = 'Select at least one category'
  }

  if(keys.duration && !values.duration){
    errors.duration = 'Duration is required'
  }

  if(keys.space && !values.space){
    errors.space = 'Space is required'
  }

  if(keys.category && !values.category){
    errors.category = 'Category is required'
  }

  if(keys.single && !values.single){
    errors.single = 'Single is required'
  }

  if(keys.timed && !values.timed){
    errors.timed = 'Timed is required'
  }


  return errors

}

export const validateProgram = (weeks) =>{
  if(weeks.length === 0){
    toast.error('You need at least one week')
    return false
  }else{
    let isValid = true
    weeks.map((week,index)=>{
      if(week.length == 0){
        toast.error('You need at least one workout in week '+(index+1))
        isValid = false
        return;
      }
    })
    return isValid

  }
}

export const validateSession = (drills) =>{
  if(drills.length === 0){
    toast.error('At least one drill is required')
    return false
  }else{
    let isValid = true;
    drills.map((drill,index)=>{
      if(drill.reps === ''){
        toast.error('Missing # of reps on drill '+(index+1))
        isValid = false
        return;
      }else if(drill.sets === ''){
        toast.error('Missing # of sets on drill '+(index+1))
        isValid = false
        return;
      }
    })
    return isValid
  }
}

export const getConfig = async () =>{
  const token = localStorage.getItem('token')
  return {
    headers: {'Authorization': "bearer " + token}
  };
}

export const getMondayOfCurrentWeek = () =>{
  const today = new Date();
  const first = today.getDate() - today.getDay() + 1;

  const monday = new Date(today.setDate(first));

  return monday;
}

export const validateEmail = (email) =>{
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
  }

export const validateGroups = (groups) =>{
  let isValid = true
  for(let i =0;i<groups.length;i++){
    if(groups[i] === '' || groups[i] === undefined || groups[i] === null){
      console.log(groups[i])
      toast.error('Each group is required to have a name.')
      isValid = false
      break;
    }
  }
  return isValid
}

export const estimateDuration = (drill) =>{
  const { timed,single,repRange,sets,rest,estTime } = drill
  let oneSetTime = 0

  if(timed){
    if(single) oneSetTime = 2 * (repRange[1] + rest)
    oneSetTime = repRange[1] + rest
  }else{
    if(single) oneSetTime =  2 * ((repRange[1] * estTime) + rest)
    oneSetTime = (repRange[1] * estTime) + rest
  }


  return oneSetTime * sets
}
