import React,{useState} from 'react'
import '../../sass/main.scss'
import AddCard from '../cards/add'
import SessionCard from '../cards/session'
import Moment from 'moment';
import { FaChevronDown,FaChevronUp } from "react-icons/fa";
import StatsCard from '../../components/cards/session-stats'

const StatsWeek = ({sessions,number,startDate,endDate,onClick}) =>{
  //===================================
  //STATE
  //===================================
  const [open,setOpen] = useState(false)
  return (
    <section className='month'>
      <div onClick={()=>setOpen(!open)}  className='month-header'>
        <h3 className='h3-black bold u-margin-right-5 u-margin-bottom-15'>
          {Moment.utc(startDate).format('MMMM D')} - {Moment.utc(endDate).format('MMMM D')}
           <h6 className='body1-grey bold'>{sessions.length} Session{sessions.length === 1 ? '' : 's'}</h6>
        </h3>
        <h3 className='h3-green bold'>{new Date() < new Date(endDate) ? 'This Week' : ''}</h3>
        <div className='month-header-icon'>
          {
            open ? <FaChevronUp size={20}/> : <FaChevronDown size={20}/>
          }
        </div>
      </div>
      {
        open
         &&
         <div className='grid-four'>
           {
             sessions.map((session,index)=>(
               <StatsCard
                 onClick={()=>onClick(session)}
                 {...session}
                 key={index}/>
             ))
           }
         </div>
      }


    </section>
  )
}

export default StatsWeek;
