import React from 'react'
import '../../sass/main.scss'


const Modal = ({children,visible}) =>{
  if(visible){
    return (
      <section className='modal'>
        <div className='modal-main'>
          {children}
        </div>
      </section>
    )
  }else{
    return null
  }
}

export default Modal
