import React from 'react'
import '../../sass/main.scss'

const StatCard = ({title,value}) =>{
  return(
    <div className='card card-stats'>
      <h1 className='h1-black bold u-margin-bottom-10 u-margin-right-5'>{value}</h1>
      <h3 className='h3-black bold'>{title}</h3>
    </div>
  )
}

export default StatCard
