import React from 'react'
import '../../sass/main.scss'

const SessionStats = ({id,drills_complete,rating,comments,onClick}) =>{
  return(
    <div onClick={onClick} className='card'>
      <div className='thumbnail' style={{backgroundImage:`url(${id.thumbnail})`}}><div className='overlay'></div></div>
      <section className='card-bottom'>
        <p className='body1-black bold u-margin-bottom-5'>{id.title}</p>
        <span className='tag tag-green'>{Math.floor((drills_complete.length/id.drills.length)*100)}% Completed</span>
      </section>
    </div>
  )
}

export default SessionStats
