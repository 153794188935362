import React from 'react'
import '../../sass/main.scss'
import Button from '../common/button'

const Header = ({title,onSave,signOut}) =>{
  return (
    <div className='all-drills-header'>
      <div className='clear-fix'>
        <h1 className='h1-black bold'>{title}</h1>
        <div className='clear-fix-right'>
          <Button onClick={onSave} text={'Save'} className={'btn btn-purple u-margin-right-5'}/>
          <Button onClick={signOut} text={'Sign Out'} className={'btn btn-grey'}/>
        </div>
      </div>
    </div>
  )
}

export default Header
