import React,{useState,useEffect} from 'react';
import '../../sass/main.scss';
import ProgramForm from '../../forms/program'
import { useNavigate } from 'react-router-dom'
import { createProgram } from '../../services/programs'

const CreateProgram = ({}) =>{
  const navigate = useNavigate()

  useEffect(() => {
   const warningText ='You have unsaved changes - are you sure you wish to leave this page?';
   const handleWindowClose = (e: BeforeUnloadEvent) => {
     e.preventDefault();
     return (e.returnValue = warningText);
   };
   window.addEventListener('beforeunload', handleWindowClose);
   return () => {
     window.removeEventListener('beforeunload', handleWindowClose);
   };
 }, []);

  const onSubmit = async(program) =>{
    program.thumbnail = program.sessions[0][0].thumbnail
    await createProgram(program)
    navigate('/programs')
    window.analytics.track('Create Program');
  }
  return (
    <main className='section-container'>
      <ProgramForm
        onSubmit={(program)=>onSubmit(program)}
        _title={''}
        _summary={''}
        _difficulty={''}
        _ages={[]}
        _sessions={[[]]}/>
    </main>
  )
}

export default CreateProgram
