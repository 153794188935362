import React from 'react';
import '../sass/main.scss';
import { FaChevronLeft } from "react-icons/fa";
import Button from '../components/common/button'
import { useNavigate } from 'react-router-dom'

const FormNavBar = ({title,onSave,onDelete,onBack}) =>{
  const navigate = useNavigate()
  return (
    <nav className='nav'>
      <div className='nav-back u-margin-right-10'>
        <FaChevronLeft
          onClick={()=>{
            if(onSave){
              const response = window.confirm('You have unsaved changes - are you sure you wish to leave this page?')
              if(response) navigate(onBack)
            }else{
              navigate(onBack)
            }

          }}
          size={25}/>
      </div>
      <h2 className='h2-black bold'>{title}</h2>
      <div className='nav-btn'>
        {
          onDelete
          &&
          <Button
            onClick={onDelete}
            text={'Delete'}
            className='btn btn-grey u-margin-right-5'/>
        }
        {
          onSave
            &&
            <Button
              onClick={onSave}
              text={'Save'}
              className='btn btn-purple'/>

        }

      </div>
    </nav>
  )
}

export default FormNavBar
