
export const DrillActionTypes = {
  'UPDATE_CATEGORIES': 'UPDATE_CATEGORIES',
  'UPDATE_DIFFICULTY': 'UPDATE_DIFFICULTY',
  'UPDATE_EQUIPMENT' :'UPDATE_EQUIPMENT',
  'UPDATE_SPACE' :'UPDATE_SPACE'
}

export const PlayerActionTypes = {
  'SELECT_PLAYER':'SELECT_PLAYER'
}
