import React from 'react'
import '../../sass/main.scss'

const GenerateDurationQuestion = ({selected,onSelect,categories}) =>{
  const renderButton = (value,label) =>{
    return (
      <a
        onClick={()=>onSelect(value)}
        className={selected === value ? 'u-margin-bottom-10 btn btn-green btn-block' : 'u-margin-bottom-10 btn btn-border-green btn-block'}>
      {label}
    </a>
)
  }
  return (
    <div>
      {categories === 1 && renderButton(10,'10 Minutes')}
      {categories < 3 && renderButton(15,'15 Minutes')}
      {renderButton(20,'20 Minutes')}
      {renderButton(25,'25 Minutes')}
      {renderButton(30,'30 Minutes')}
    </div>
  )
}

export default GenerateDurationQuestion
