import React,{useState,useEffect} from 'react'
import '../../sass/main.scss';
import Header from './header'
import Footer from './footer'
import Input from '../common/form-small-input'

const EditSetsReps = ({sets,reps,single,timed,onCancel,onSave}) =>{
  //===========================
  //STATE
  //===========================
  const [_sets,setSets] = useState(sets)
  const [_reps,setReps] = useState(reps)
  return(
    <div className='modal-small'>
      <Header
        title={'Edit Sets & Reps'}
        onClick={onCancel}/>
      <div className='modal-content'>
        <Input
          tab={single ? 'R/L' : 'Both'}
          label='Sets'
          type={'number'}
          name={'sets'}
          value={_sets}
          handleChange={(event) => setSets(event.target.value)}/>
        <Input
          tab={timed ? 'Seconds' : 'Reps'}
          label='Reps'
          type={'number'}
          name={'reps'}
          value={_reps}
          handleChange={(event) => setReps(event.target.value)}/>
      </div>
      <Footer
        onSubmit={()=>onSave(_sets,_reps)}
        onCancel={onCancel}/>
    </div>
  )
}

export default EditSetsReps
