import React from 'react'
import '../../sass/main.scss'
import CheckBox from './checkbox'

const Filter = ({items,values,handleChange}) =>{
  return (
    <div className='filter'>
      {
        items.map((item,index)=>(
          <CheckBox
            handleChange = {() => handleChange(item.value)}
            checked={values.includes(item.value)}
            key={item.label}
            label={item.label}/>
        ))
      }

    </div>
  )
}

export default Filter
