import React from 'react'
import '../../sass/main.scss'
import { Link } from 'react-router-dom'

const Tab = ({title,active,onClick,icon,to}) =>{
  return (
    <li onClick={onClick}>
      <Link to={to} className={active ? 'tab tab-active' : 'tab'}>
        <div className = 'tab-icon'>
          {icon}
        </div>
        <p className={active ?'h3-white bold' : 'h3-grey bold'}>{title}</p>
      </Link>
    </li>
  )
}

export default Tab
