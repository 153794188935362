import React from 'react'
import '../../sass/main.scss'
import Button from './button'

const NoItems = ({title,onClick,subtitle,button}) =>{
  return (
    <div className='no-items-container'>
      <div className='no-items'>
        <h2 className='h2-black bold'>{title}</h2>
        <p className='h3-grey bold u-margin-bottom-10'>{subtitle}</p>
        {
          button && <Button text={button} onClick={onClick} className='btn btn-green'/>
        }
      </div>
    </div>
  )
}

export default NoItems
