import React from 'react'
import '../../sass/main.scss'
import Button from '../common/button'
import { useNavigate } from 'react-router-dom'

const Header = ({title,canDelete,onDelete}) =>{
  const navigate = useNavigate()

  return (
    <div className='all-drills-header'>
      <div className='clear-fix'>
        <h1 className='h1-black bold'>{title}</h1>
        <div className='clear-fix-right'>
          <Button onClick={()=>navigate('/programs/create')}text={'+ Create Program'} className={'btn btn-purple u-margin-right-5'}/>
          <Button onClick={canDelete && onDelete} text={'Delete Programs'} className={canDelete ? 'btn btn-light-red' : 'btn btn-disabled'}/>
        </div>
      </div>
    </div>
  )
}

export default Header
