import React from 'react'
import '../../sass/main.scss'
import { FiMinusCircle } from "react-icons/fi";

const Button = ({onClick}) => {
  return (
    <div>
      <a onClick={onClick} className={'btn btn-border'}>
        <FiMinusCircle/>
      </a>
    </div>
  )
}

export default Button;
