import React,{useState} from 'react';
import '../../sass/main.scss';
import Nav from '../../forms/form-navbar'

const GenerateSession = ({}) =>{
  const [space,setSpace] = useState('')
  const [equipment,setEquipment] = useState('')
  const [categories,setCategories] = useState('')
  const [duration,setDuration] = useState('')
  const [difficult,setDifficulty] = useState('')
  const [step,setStep] = useState(1)
  return (
    <main className='section-container'>
      <Nav
        onBack={'/workouts'}
        title={'Generate Workout'}/>
    </main>
  )
}

export default GenerateSession
