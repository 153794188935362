import React from 'react'
import '../../sass/main.scss'
import { FaVideo } from "react-icons/fa";


const ImageCard = ({url,onClick,title}) =>{

  const styles = {
    backgroundImage:'url('+ url +')',
    backgroundPosition:'center',
    backgroundSize:'cover',
    backgroundRepeat:'noRepeat',
  }

  if(url){
    return (
      <div onClick={onClick} className='video-card' style={styles}>

      </div>
    )
  }else{
    return (
      <div onClick={onClick} className='video-card'>
        <div>
          <FaVideo size={30}/>
        </div>
        <h1 className='bold'>{title}</h1>
      </div>
    )
  }
}

export default ImageCard
