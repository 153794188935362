export const API = 'https://api.mycoachapp.co/api';
// export const API = 'http://localhost:8080/api'

export const Categories = [
  {value:'Ball Mastery',label:'Ball Mastery'},
  {value:'Dribbling',label:'Dribbling'},
  {value:'Wall Drills',label:'Wall Drills'},
  {value:'Shooting',label:'Shooting'},
  {value:'Passing',label:'Passing'},
  {value:'Juggling',label:'Juggling'},
  {value:'Agility',label:'Agility'}
]

export const Difficulty = [
  {value:'Any',label:'Any'},
  {value:'Beginner',label:'Beginner'},
  {value:'Intermediate',label:'Intermediate'},
  {value:'Advanced',label:'Advanced'},
]

export const Equipment = [
  {value:'Rebounder',label:'Rebounder'},
  {value:'Ladder',label:'Ladder'},
  {value:'Hurdles',label:'Hurdles'},
]

export const Space = [
  {value:2,label:'Small Space'},
  {value:1,label:'Medium Space'},
  {value:0,label:'Large Space'},
]

export const Prices = [
  { value: 1599, label: '$15.99' },
  { value: 1699, label: '$16.99' },
  { value: 1799, label: '$17.99' },
  { value: 1899, label: '$18.99' },
  { value: 1999, label: '$19.99' },
  { value: 2099, label: '$20.99' },
  { value: 2199, label: '$21.99' },
  { value: 2299, label: '$22.99' },
  { value: 2399, label: '$23.99' },
  { value: 2499, label: '$24.99' },
  { value: 2599, label: '$25.99' },
  { value: 2699, label: '$26.99' },
  { value: 2799, label: '$27.99' },
  { value: 2899, label: '$28.99' },
  { value: 2999, label: '$29.99' },
  { value: 3099, label: '$30.99' },
  { value: 3199, label: '$31.99' },
  { value: 3299, label: '$32.99' },
  { value: 3399, label: '$33.99' },
  { value: 3499, label: '$34.99' },
  { value: 3599, label: '$35.99' },
  { value: 3699, label: '$36.99' },
  { value: 3799, label: '$37.99' },
  { value: 3899, label: '$38.99' },
  { value: 3999, label: '$39.99' },
  { value: 4099, label: '$40.99' },
  { value: 4199, label: '$41.99' },
  { value: 4299, label: '$42.99' },
  { value: 4399, label: '$43.99' },
  { value: 4499, label: '$44.99' },
  { value: 4599, label: '$45.99' },
  { value: 4699, label: '$46.99' },
  { value: 4799, label: '$47.99' },
  { value: 4899, label: '$48.99' },
  { value: 4999, label: '$49.99' },
]

export const Trials = [
  {value:3,label:'3 days'},
  {value:7,label:'7 days'},
  {value:14,label:'14 days'},
  {value:21,label:'21 days'},
  {value:30,label:'30 days'},
]
