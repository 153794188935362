import React from 'react'
import '../../sass/main.scss'
import TabBar from './tab-bar'

const Header = ({academy,publicAcct,tab,onTabChange}) =>{
  return(
    <div className='new-dashboard-header'>
      <div className='clear-fix'>
        <div>
          {
            publicAcct
              ? <span className={'tag tag-green'}>Public Account</span>
              : <span className={'tag tag-light-red'}>Private Account</span>
          }
          <h2 className='h2-black bold u-margin-bottom-10 u-margin-top-10'>{academy}</h2>
        </div>
        <div className='clear-fix-right'>


        </div>
      </div>
      <TabBar tab={tab} onTabChange={(tab)=>onTabChange(tab)}/>

    </div>
  )
}

export default Header
