import React from 'react'
import '../../sass/main.scss'
import Thread from './thread'

const Threads = ({threads,selected,onClick}) =>{
  return (
    <section className='threads'>
      <div className='threads-header'>
        <p className='h3-black bold'>Players</p>
      </div>
      {
        threads.map((thread,index)=>(
          <Thread
            key={thread._id}
            selected={selected}
            thread={thread}
            onClick={onClick}/>
        ))
      }
    </section>
  )
}

export default Threads
