import React,{useEffect,useState} from 'react'
import '../../sass/main.scss'
import { getMessages,createMessage,messageImage,messageVideo,readMessage } from '../../services/messages'
import Spinner from '../../components/common/spinner'
import ChatList from './chatlist'
import Input from './input'
import Moment from 'moment';
import io from "socket.io-client"
import Resizer from "react-image-file-resizer";

const Chat = ({thread}) =>{
  //==============================
  // STATE
  //==============================
  const [loading,setLoading] = useState(false)
  const [dates,setDates] = useState([])
  const [message,setMessage] = useState('')
  const [userId,setUserId] = useState('')
  //==============================
  // HOOKS
  //==============================
  useEffect(()=>{
    const socket = io("https://api.mycoachapp.co")
    let chatListner = "chat-"+thread._id
    let id = localStorage.getItem('id')
    setUserId(id)
    socket.on(chatListner, msg =>{
      if(msg.user._id !== id){
        let newMessage = {
          message:msg.text,
          image:msg.image,
          video:msg.video,
          sender:msg.user._id,
          created:new Date()
        }
        setDates((dates)=>{
          if(dates.length > 0){
            let tempDates = [...dates]
            let tempDate = {...tempDates[tempDates.length - 1]}
            tempDate.messages.push(newMessage)
            tempDates[dates.length - 1] = tempDate
            return tempDates
          }else{
            return [{
              _id:new Date(),
              messages:[newMessage]
            }]
          }
        })
        // addNewMessage(newMessage)
      }
    })
    const fetchData = async() =>{
      console.log('Changed')
      setLoading(true)
      if(thread){
        const response = await getMessages(thread._id)
        setDates(response)
      }
      setLoading(false)
    }
    fetchData()
    return async() =>{
      await readMessage(thread._id)
      socket.off('chat-'+thread._id);
    }
  },[thread])

  //==============================
  // HANDLERS
  //==============================
  const handleChange = (event) =>{
    setMessage(event.target.value)
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        500,
        500,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
  });

  const addNewMessage = (newMessage) =>{
    if(dates.length > 0){
      let tempDates = [...dates]
      let tempDate = {...tempDates[tempDates.length - 1]}
      tempDate.messages.push(newMessage)
      tempDates[dates.length - 1] = tempDate
      setDates(tempDates)
    }else{
      dates.push({
        _id:new Date(),
        messages:[newMessage]
      })
    }
  }
  const onSend = async() =>{
    let newMessage = {
      message:message,
      sender:userId,
      created:new Date()
    }
    addNewMessage(newMessage)
    await createMessage(thread._id,{text:message})
    setMessage('')
  }

  const onImage = async(event) =>{
    if(event.target.files[0]){
      let file = event.target.files[0]
      file = await resizeFile(file);
      const data = new FormData()
      data.append('file',file)
      var reader  = new FileReader();
      reader.onload = function(e)  {
        let image = e.target.result;
        let newMessage = {
          image:image,
          sender:userId,
          created:new Date()
        }
        addNewMessage(newMessage)
       }
       reader.readAsDataURL(file);
      await messageImage(thread._id,data)
    }
  }

  const onVideo = async(event) =>{
    if(event.target.files[0]){
      const file = event.target.files[0]
      const data = new FormData()
      data.append('file',file)
      var reader  = new FileReader();
      reader.onload = function(e)  {
        let video = e.target.result;
        let newMessage = {
          video,
          sender:userId,
          created:new Date()
        }
        addNewMessage(newMessage)
       }
       reader.readAsDataURL(file);
      await messageVideo(thread._id,data)
    }
  }

  const readFile = (file) =>{
    var reader  = new FileReader();
    let target = null
    reader.onload = function(e)  {
      target = e.target.result;
     }
     // you have to declare the file loading
     reader.readAsDataURL(file);
     return target
  }

  return (
    <div>
      <section className='messages'>
        {
          !loading
            ?<div>
              <ChatList dates={dates} sender={thread.coach}/>
             </div>
            :<div style={{marginTop:100}}><Spinner/></div>
        }
      </section>
      <Input
        onImage={(event)=>onImage(event)}
        onVideo={(event)=>onVideo(event)}
        value={message}
        handleChange={handleChange}
        onSend={onSend}/>
    </div>
  )
}

export default Chat
