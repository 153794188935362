import React from 'react'
import '../../sass/main.scss'
import Button from '../common/button'
import { useNavigate } from 'react-router-dom'
import TabBar from './tab-bar'

const Header = ({onClick,canDelete,onDelete,selected,onSelect}) =>{
  const navigate = useNavigate()

  return (
    <div className='all-drills-header' style={{paddingBottom:0}}>
      <div className='clear-fix'>
        <h1 className='h1-black bold'>Media</h1>
        <div className='clear-fix-right'>
          <Button onClick={onClick}text={selected === 0 ? '+ Upload Image' : '+ Upload Video'} className={'btn btn-purple u-margin-right-5'}/>
          <Button onClick={canDelete && onDelete} text={'Delete'} className={canDelete ? 'btn btn-light-red' : 'btn btn-disabled'}/>
        </div>
      </div>
      <TabBar selected={selected} onSelect={(tab)=>onSelect(tab)}/>
    </div>
  )
}

export default Header
