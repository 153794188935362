import React,{useEffect,useState} from 'react';
import '../../sass/main.scss';
import { toast } from 'react-toastify';
import NavBar from '../../components/onboarding/navbar'
import Input from '../../components/common/form-input'
import Button from '../../components/common/button'
import { useNavigate } from 'react-router-dom'
import SignUpForm from '../../forms/signup'
import TextArea from '../../components/common/form-textarea'
import { register } from '../../services/login'
import { validateInfo } from '../../utils/helpers'
import { FiCheckCircle } from "react-icons/fi";


const CoachApply = ({}) =>{
  //===============================
  //STATE
  //===============================
  const [username,setUsername] = useState('')
  const [password,setPassword] = useState('')
  const [fname,setFname] = useState('')
  const [lname,setLname] = useState('')
  const [social,setSocial] = useState('')
  const [bio,setBio] = useState('')
  const [errors,setErrors] = useState({username:'',password:'',fname:'',lname:''})
  const [complete,setComplete] = useState(false)
  const navigate = useNavigate()
  //===============================
  //HANDLERS
  //===============================
  const handleSubmit = () =>{
    const keys = {username:true,password:true,fname:true,lname:true}
    let errorMessages = validateInfo({username,password,fname,lname},keys)
    if(Object.entries(errorMessages).length === 0){
      setErrors({username:'',password:'',fname:'',lname:''})
      onSubmit({username,password,fname,lname,app_social_links:social,app_info:bio})
    } else{
      setErrors(errorMessages)
    }
  }

  const onSubmit = async(info) =>{
    const response = await register({...info,account_type:'Coach'})
    if(response.success){
      setComplete(true)
      window.analytics.track('Applied');
    }else{
      toast.error('A user with that email address already exists.')
    }
  }

if(!complete){
  return(
    <section style={{marginBottom:50}}>
      <section className='forgot-password-container'>
        <NavBar
          onBack={()=>navigate('/login')}
          title={'Apply to Become a Coach'}
        />
        <Input
          className='group u-margin-bottom-10'
          error={errors.username}
          type={'text'}
          placeholder={'Email Address'}
          name={'username'}
          value={username}
          handleChange={(event) => setUsername(event.target.value)}
          required={true}/>
        <Input
          className='group u-margin-bottom-10'
          error={errors.password}
          type={'password'}
          placeholder={'Password must contain at least 7 charachters'}
          name={'password'}
          value={password}
          handleChange={(event) => setPassword(event.target.value)}
          required={true}/>
        <Input
          className='group u-margin-bottom-10'
          error={errors.fname}
          type={'text'}
          placeholder={'First Name'}
          name={'fname'}
          value={fname}
          handleChange={(event) => setFname(event.target.value)}
          required={true}/>
        <Input
          className='group u-margin-bottom-10'
          error={errors.lname}
          type={'text'}
          placeholder={'Last Name'}
          name={'lname'}
          value={lname}
          handleChange={(event) => setLname(event.target.value)}
          required={true}/>
        <Input
          className='group u-margin-bottom-20'
          type={'text'}
          placeholder={'Social media links or website'}
          name={'social'}
          value={social}
          handleChange={(event) => setSocial(event.target.value)}/>
        <TextArea
          className='group u-margin-bottom-20'
          rows={5}
          placeholder={'Tell us about yourself and any questions you may have.'}
          name={'bio'}
          value={bio}
          handleChange={(event) => setBio(event.target.value)}/>
        <Button
          onClick={handleSubmit}
          text={'Sign Up'}
          className='btn btn-block btn-green u-margin-top-20'
          />
          <p className='body2-grey bold u-margin-top-20' style={{textAlign:'center'}}>
            By clicking Sign Up, you agree to our
            <a style={{fontSize:13}} href="https://mycoachapp.co/terms-of-use" target="_blank"> Terms</a>,
            <a  style={{fontSize:13}} href="https://mycoachapp.co/privacy-policy" target="_blank"> Privacy Policy</a> and
            <a  style={{fontSize:13}} href="https://mycoachapp.co/coach-terms" target="_blank"> Coach Terms</a>.
            You may receive Email Notifications from us and can opt out any time.
          </p>
      </section>
    </section>
  )
}else{
  return (
    <section className='forgot-password-container'>
      <div style={{textAlign:'center'}}>
            <FiCheckCircle size={100} color={'#0DCE7C'}/>
            <h2 className='h2-black bold u-margin-bottom-5'>Application Submitted!</h2>
            <p className='body1-grey bold u-margin-bottom-30'>We will begin reviewing your application shortly. We will send you an email within 1-2 bussiness days letting you know if you have been accepted.</p>
          </div>
    </section>
  )

}

}

export default CoachApply
