import axios from 'axios';
import { API } from '../utils/variables'
import { getConfig } from '../utils/helpers'
import { toast } from 'react-toastify';

//get all players
export const getPlayers = async () =>{
  try{
    const config = await getConfig()
    const route = `${API}/players/`;
    const response = await axios.get(route,config)
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}

//get players schedule
export const getPlayersSchedule = async (id) =>{
  try{
    const config = await getConfig()
    const route = `${API}/schedule/${id}`;
    const response = await axios.get(route,config)
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}

//update schedule
export const updateSchedule = async (body) =>{
  try{
    const config = await getConfig()
    const route = `${API}/schedule/`;
    const response = await axios.put(route,body,config)
    toast.success('The players schedule was succefully saved')
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}

//get player stats
export const getPlayersStats = async (playerId) =>{
  try{
    const config = await getConfig()
    const route = `${API}/player-stats/${playerId}`;
    const response = await axios.get(route,config)
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}

//get groups
export const getGroups = async () =>{
  try{
    const config = await getConfig()
    const route = `${API}/groups/`;
    const response = await axios.get(route,config)
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}

//update Subscription
export const editSubscription = async (id,body) =>{
  try{
    const config = await getConfig()
    const route = `${API}/subscribe/${id}`;
    const response = await axios.put(route,body,config)
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}

//get added players
export const getAddedPlayers = async () =>{
  try{
    const config = await getConfig()
    const route = `${API}/added-players/`;
    const response = await axios.get(route,config)
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}

//get subscribed players
export const getSubscribedPlayers = async () =>{
  try{
    const config = await getConfig()
    const route = `${API}/subscribed-players/`;
    const response = await axios.get(route,config)
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}

//get deleted players
export const getDeletedPlayers = async () =>{
  try{
    const config = await getConfig()
    const route = `${API}/deleted-players/`;
    const response = await axios.get(route,config)
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}

export const invitePlayer = async (body) =>{
  try{
    const config = await getConfig()
    const route = `${API}/invite/`;
    const response = await axios.post(route,body,config)
    toast.success('Invite was succesfully sent')
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}
