import React from 'react';
import '../../sass/main.scss';
import { FaChevronLeft } from "react-icons/fa";
import Button from '../common/button'
import { useNavigate,Link } from 'react-router-dom'
import Avatar from 'react-avatar'
import TabBar from '../../components/players/tab-bar'

const NavBar = ({title,status,onBack,profilePic,noAlert,tab,onTabChange}) =>{
  const navigate = useNavigate()
  return (
    <nav className='nav' style={{display:'block',padding:0}}>
      <div style={{display:'flex',padding:10}}>
        <div className='nav-back u-margin-right-10'>
          <FaChevronLeft onClick={()=>{
            if(noAlert){
              navigate(onBack)
            }else{
              const response = window.confirm('You have unsaved changes - are you sure you wish to leave this page?')
              if(response) navigate(onBack)
            }
          }}
          size={25}/>
        </div>
          <Avatar
            src={profilePic}
            round
            size={40}/>
          <div>
            <h2  style={{marginLeft:5}} className='h2-black bold'>{title}</h2>
          </div>
        {
          status
            &&
            <div style={{alignSelf:'center'}}className='nav-btn'>
              <span
                style={{textTransform:'capitalize'}}
                className={status === 'inactive' ? 'tag tag-large tag-red' : 'tag tag-lrg tag-blue'}>{status}</span>
            </div>
        }
      </div>
      <TabBar tab={tab} onTabChange={(tab)=> onTabChange(tab)}/>


    </nav>
  )
}

export default NavBar
