import React,{useState,useEffect} from 'react'
import '../../sass/main.scss';
import Header from './header'
import Drill from '../../pages/drills/drillmodal'

const DrillModal = ({drillId,onCancel}) =>{
  return(
    <div className='modal-medium-full'>
      <Header
        onClick={onCancel}/>
      <div style={{height:'90vh',padding:0}} className='modal-content modal-content-scrollable'>
        <Drill
          drillId={drillId}/>
      </div>
    </div>
  )
}

export default DrillModal
