import React,{useEffect,useState} from 'react';
import '../../sass/main.scss';
import Header from '../../components/players/header'
import { getPlayers,getGroups,editSubscription } from '../../services/players'
import { editCoach } from '../../services/coaches'
import { useNavigate } from 'react-router-dom'
import Table from '../../components/common/table'
import Spinner from '../../components/common/spinner'
import Modal from '../../components/modals/modal'
import GroupsModal from '../../components/modals/groups'
import AddToGroupModal from '../../components/modals/add-to-group'
import { toast } from 'react-toastify';

const Players = ({}) =>{
  //====================================
  // STATE
  //====================================
  const [loading,setLoading] = useState(true)
  const [players,setPlayers] = useState([])
  const [selectedPlayerIndex,setSelectedPlayerIndex] = useState(null)
  const [groups,setGroups] = useState([])
  const [modalVisible,setModalVisible] = useState(false)
  const [groupModalVisible,setGroupModalVisible] = useState(false)
  const Headers = [
    {label:'',value:'profile_pic'},
    {label:'Name',value:'name'},
    {label:'Group',value:'group'},
    {label:'Workouts This Week',value:'sessions'},
    {label:'',value:'addtogroup'},
  ]

  //====================================
  // HOOKS
  //====================================
  const navigate = useNavigate()
  useEffect(()=>{
    const fetchData = async() =>{
      setLoading(true)
      const players = await getPlayers()
      const groups = await getGroups()
      setPlayers(players)
      setGroups(groups)
      setLoading(false)
    }
    fetchData()
  },[])

  //====================================
  // HANDLERS
  //====================================
  const saveGroups = async(groups) =>{
    setModalVisible(false)
    setGroups(groups)
    await editCoach({groups})
  }

  const saveGroup = async(group) =>{
    setGroupModalVisible(false)
    let temp = [...players]
    let player = temp[selectedPlayerIndex]
    await editSubscription(player.subscriptionId,{group})
    toast.success('Player saved to group')
    temp[selectedPlayerIndex].group = group
    setPlayers(temp)
  }

  return (
    <main className='section-container'>
      <Header title='Players' onClick={()=>setModalVisible(true)}/>
      {
        !loading
          ?<section className='week-container'>
            <Table
              noItemsTitle='No Active Players Yet'
              noItemsSubtitle='Active players will be shown here'
              lessPadding
              onClick={(_id,status)=>navigate('/players/'+_id)}
              addToGroup={(index) =>{
                setSelectedPlayerIndex(index)
                setGroupModalVisible(true)
              }}
              headers={Headers}
              rows={players}/>
            </section>
          :<div style={{marginTop:100}}><Spinner/></div>
      }
      <Modal visible={modalVisible}>
        <GroupsModal
          onCancel={()=>setModalVisible(false)}
          groups={groups}
          onSave={(groups)=>saveGroups(groups)}/>
      </Modal>
      <Modal visible={groupModalVisible}>
        <AddToGroupModal
          groups={groups}
          onCancel={()=>setGroupModalVisible(false)}
          item={selectedPlayerIndex !== null ? players[selectedPlayerIndex].group : ''}
          onSave={(group)=>saveGroup(group)}/>
      </Modal>

    </main>
  )
}

export default Players
