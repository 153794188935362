import React from 'react'
import '../../sass/main.scss'
import { FaPlusCircle } from 'react-icons/fa';

const AddCard = ({onClick,title}) =>{
  return (
    <div onClick={onClick} className='card card-add'>
      <div>
        <div style={{textAlign:'center'}}>
          <FaPlusCircle size={30}color='#1C0DCE'/>
        </div>
        <a style={{color:'#1C0DCE'}} className='bold'>{title}</a>
      </div>
    </div>
  )
}

export default AddCard;
