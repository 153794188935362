import React,{useState,useEffect} from 'react';
import '../../sass/main.scss';
import Input from '../../components/common/form-input'
import Button from '../../components/common/button'
import { editCoach } from '../../services/coaches'
import { toast } from 'react-toastify';
import NavBar from '../../components/onboarding/navbar'
import { useNavigate } from 'react-router-dom'
import FormMultipleButton from '../../components/common/form-multiple-button'

const AgeGroups = ({}) =>{
  //===============================
  //STATE
  //===============================
  const [ages,setAges] = useState([])
  const navigate = useNavigate()
  useEffect(()=>{
    const token = localStorage.getItem('token');
    if(!token) navigate('/login')
  },[])
  //===============================
  //HANDLERS
  //===============================
  const onSubmit = async() =>{
    if(ages.length > 0){
      await editCoach({age_groups:ages})
      navigate('/levels')
    }

  }

  const handleAgeChange = (item) =>{
    if(ages.includes(item)){
      let array = [...ages]
      let index = array.indexOf(item)
      array.splice(index,1)
      setAges(array)
    }else{
      setAges([...ages,item])
    }
  }

  return(
    <section className='onboarding-container'>
      <NavBar
        onBack={()=>navigate('/academy-name')}
        title={'What age groups do you coach?'}
        subtitle={'Select at least one age group.'}
      />
      <FormMultipleButton
        className='group u-margin-bottom-20'
        items={['6 or younger','7-8','9-10','11-12','13-15','16 or older']}
        handleChange={(item)=> handleAgeChange(item)}
        values={ages}/>
      <Button
        onClick={onSubmit}
        text={'Next'}
        className='btn btn-lrg btn-block btn-green u-margin-top-10'
        />
    </section>
  )
}

export default AgeGroups
