import React from 'react'
import '../../sass/main.scss'
import Moment from 'moment';
import Avatar from 'react-avatar'
import Button from './button'
import CheckBox from './checkbox'
import NoItems from './noitems'

const Table = ({headers,rows,onClick,onDrill,onSelect,addToGroup,lessPadding,checked,onCheck,deactivate,activate,active,deletePlayer,noItemsTitle,noItemsSubtitle}) =>{
  const renderCell = (row,header,rowIndex) =>{
    if(header.value === 'created'){
      return Moment(row[header.value]).format('MMM D, YYYY')
    }else if(header.value === 'profile_pic'){
      return (
        <Avatar
          src={row.profile_pic}
          size={45}
          round/>
      )
    }else if(header.value === 'space'){
      if(row.space === 2){
        return 'Small'
      }else if(row.space === 1){
        return 'Medium'
      }else{
        return 'Large'
      }
    }else if(header.value === 'difficulty'){
      let className = 'tag tag-md'
      if(row.difficulty === 'Advanced'){
        className +=' tag-red'
      }else if(row.difficulty === 'Intermediate'){
        className +=' tag-blue'
      }else if(row.difficulty === 'Beginner'){
        className +=' tag-green'
      }else{
        className +=' tag-grey'
      }
      return (
        <span className={className}>{row.difficulty}</span>
      )
    }else if(header.value === 'category'){
      let className = 'tag tag-md'
      if(row.category === 'Shooting'){
        className +=' tag-green'
      }else if(row.category === 'Ball Mastery'){
        className +=' tag-orange'
      }else if(row.category === 'Wall Drills'){
        className +=' tag-yellow'
      }else if(row.category === 'Juggling'){
        className +=' tag-light-purple'
      }else if(row.category === 'Dribbling'){
        className +=' tag-blue'
      }else if(row.category === 'Agility'){
        className +=' tag-light-red'
      }else{
        className +=' tag-grey'
      }
      return (
        <span className={className}>{row.category}</span>
      )
    }else if(header.value === 'active'){
      let className = 'tag tag-md'
      if(row.active === 'Active'){
        className +=' tag-green'
      }else{
        className +=' tag-grey'
      }
      return <span className={className}>{row.active}</span>
    }else if(header.value === 'url'){
      return (
        <img
          src={row.url.replace('.mp4','.jpg')}
          width={70}
          height={45}
          style={{borderRadius:4}}/>
      )
    }else if(header.value === 'thumbnail'){
      return (
        <img
          src={row.thumbnail}
          width={70}
          height={45}
          style={{borderRadius:4}}/>
      )
    }else if(header.value === 'sessions' && header.label === 'Duration'){
      if(row.sessions.length == 1) return '1 week'
      return row.sessions.length + ' weeks'
    }else if(header.value === 'duration'){
      if(row.duration == 1) return '1 minute'
      return row.duration + ' minutes'
    }else if(header.value === 'addtogroup'){
      return(
        <a
          className='btn btn-border-green'
          onClick={(event)=>{
            event.stopPropagation();
            addToGroup(rowIndex)
          }}>
          Add to Group
        </a>
      )
    }else if(header.value === 'deactivate'){
      if(!active){
        return(
          <a
            className='btn btn-border-green'
            onClick={(event)=>{
              event.stopPropagation();
              activate(rowIndex)
            }}>
            Activate
          </a>
        )
      }else{
        return(
          <a
            className='btn btn-border-light-red'
            onClick={(event)=>{
              event.stopPropagation();
              deactivate(rowIndex)
            }}>
            Deactivate
          </a>
        )
      }
    }else if(header.value === 'deleteplayer'){
      if(!row['pending_delete']){
        return(
          <a
            className='btn btn-light-red'
            onClick={(event)=>{
              event.stopPropagation();
              deletePlayer(rowIndex,'delete')
            }}>
            Delete
          </a>
        )
      }else{
        return(
          <a
            className='btn btn-border-light-red'
            onClick={(event)=>{
              event.stopPropagation();
              deletePlayer(rowIndex,'cancel')
            }}>
            Cancel Deletion
          </a>
        )
      }
    }else if(header.value === '_id'){
      return (
        <span  onClick={(e)=>e.stopPropagation()}>
          <input
            className='checkbox-input'
            type="checkbox"
            checked={checked.includes(row[header.value])}
            onChange={()=>onCheck({id:row[header.value],idx:rowIndex})}
          />
        </span>

      )
    }
    else{
      return row[header.value]
    }
  }

  const getCellStyle = (header) =>{
    let style = {}
    if(lessPadding){
      style.paddingTop = 10
      style.paddingBottom = 3
    }

    if(header.value === 'deleteplayer' || header.value === 'addtogroup' || header.value === 'deactivate'){
      style.textAlign = 'right'
    }
    return style
  }

  if(rows.length > 0){
    return (
      <table className='table'>
        <thead>
          <tr className='table-header'>
            {
              headers.map((header,index)=>(
                <th key={index}>{header.label}</th>
              ))
            }
          </tr>
        </thead>
        <tbody>
          {
            rows.map((row,index)=>(
              <tr
                key={index}
                onClick={()=>{
                  if(onDrill){
                    onDrill(row._id)
                  }else if(onSelect){
                    onSelect(row)
                  }else{
                    onClick(row._id,row.status)
                  }
                }}
                className={index % 2 == 0 ? 'table-row-white' : 'table-row-dark'}>
                {
                  headers.map((header,index2)=>(
                    <td
                      style={getCellStyle(header)}
                      className='table-cell'
                      key={index2} >
                      {renderCell(row,header,index)}
                    </td>
                  ))
                }
              </tr>
            ))
          }
        </tbody>
      </table>
    )
  }else{
    return (
      <NoItems
        title={noItemsTitle || 'No Items'}
        subtitle={noItemsSubtitle || 'Items you add will appear here.'}/>
    )
  }
}

export default Table
