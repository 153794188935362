import { PlayerActionTypes } from '../../utils/enums'

const INITIAL_STATE = {
  _id:null,
  name:'',
  profile_pic:''
}

export const PlayerReducer = (state = INITIAL_STATE, action) =>{
  switch (action.type) {
    case PlayerActionTypes.SELECT_PLAYER:
      return { ...state,...action.payload }
    default:
      return state
  }
}

export default PlayerReducer;
