import React,{useState} from 'react'
import '../../sass/main.scss'
import { FaTrashAlt } from "react-icons/fa";
import Input from '../common/form-input'

const GroupItem = ({item,handleChange,onDelete}) =>{
  //===========================
  //STATE
  //===========================

  return(
    <div className='group-item'>
      <div style={{flex:1}}>
        <Input
          className='group'
          type={'text'}
          placeholder={'Group Name'}
          name={'title'}
          maxLength={50}
          value={item}
          handleChange={(event) => handleChange(event.target.value)}/>
      </div>
        <div style={{flex:.1,alignSelf:'center',textAlign:'right'}}>
          <FaTrashAlt size={30} color={'red'} onClick={onDelete}/>
        </div>
    </div>
  )
}

export default GroupItem
