import React,{useEffect,useState} from 'react';
import '../sass/main.scss';
import Input from '../components/common/form-input'
import TextArea from '../components/common/form-textarea'
import FormOneButton from '../components/common/form-one-button'
import FormMultipleButton from '../components/common/form-multiple-button'
import Nav from './form-navbar'
import Week from '../components/programs/week'
import Button from '../components/common/button'
import Modal from '../components/modals/modal'
import SessionsModal from '../components/modals/sessions-table'
import { getSessions } from '../services/sessions'
import { createProgram } from '../services/programs'
import { validateInfo,validateProgram } from '../utils/helpers'
import { useNavigate } from 'react-router-dom'
import DeleteModal from '../components/modals/delete'

const ProgramForm = ({_title,_summary,_difficulty,_ages,_sessions,onSubmit,onDelete}) =>{
  //==================================
  //STATE
  //==================================
  const [title,setTitle] = useState(_title)
  const [summary,setSummary] = useState(_summary)
  const [difficulty,setDifficulty] = useState(_difficulty)
  const [ages,setAges] = useState(_ages)
  const [sessions,setSessions] = useState(_sessions)
  const [isFetchedWorkouts,setIsFetchedWorkouts] = useState(false)
  const [foundSessions,setFoundSessions] = useState([])
  const [visible,setVisible] = useState(false)
  const [week,setWeek] = useState(null)
  const [deleteModalVisible,setDeleteModalVisible] = useState(false)
  const [errors,setErrors] = useState({title:'',difficulty:'',ages:''})
  //==================================
  //HOOKS
  //==================================
  const navigate = useNavigate()
  useEffect(()=>{
    const fetchData = async() =>{
      const response = await getSessions(0)
      setFoundSessions(response)
    }
    fetchData()
  },[])

  //==================================
  //HANDLERS
  //==================================


  const _createProgram = async() =>{
    let program ={
      title,
      summary,
      difficulty,
      ages,
      sessions,
    }
    onSubmit(program)
  }
  const handleSubmit = () =>{
    if(validateProgram(sessions)){
      const keys = {title:true,difficulty:true}
      let errorMessages = validateInfo({title,difficulty},keys)
      if(Object.entries(errorMessages).length === 0){
        _createProgram()
      } else{
        setErrors(errorMessages)
      }
    }
  }
  const handleAgeChange = (item) =>{
    if(ages.includes(item)){
      let array = [...ages]
      let index = array.indexOf(item)
      array.splice(index,1)
      setAges(array)
    }else{
      setAges([...ages,item])
    }
  }

  const deleteWeek = (index) =>{
    let array = [...sessions]
    array.splice(index,1)
    setSessions(array)
  }

  const addWorkoutToWeek = (newSession) =>{
    let tempWeek = [...sessions]
    let tempInnerSessions = [...sessions[week],newSession]
    tempWeek[week] = tempInnerSessions
    setSessions(tempWeek)
    setVisible(false)
  }

  const deleteSession = (sessionIndex,weekIndex) =>{
    let tempWeek = [...sessions]
    let tempSessions = [...sessions[weekIndex]]
    tempSessions.splice(sessionIndex,1)
    tempWeek[weekIndex] = tempSessions
    setSessions(tempWeek)
  }

  const onSortEnd = (oldIndex,newIndex,weekIndex) =>{
    const array = Array.from(sessions)
    const items = Array.from(array[weekIndex])
    const [reorderedItem] = items.splice(oldIndex,1)
    items.splice(newIndex,0,reorderedItem)
    array[weekIndex] = items
    setSessions(array)
  }

  return (
    <section>
      <Nav
        onBack={'/programs'}
        onDelete={onDelete ? ()=>setDeleteModalVisible(true) : null}
        title={title}
        onSave={handleSubmit}/>
      <div className='form-container'>
        <Input
          className='group u-margin-bottom-20'
          error={errors.title}
          label={'Title*'}
          type={'text'}
          placeholder={'Program title'}
          name={'title'}
          maxLength={50}
          value={title}
          handleChange={(event) => setTitle(event.target.value)}
          required={true}/>
        <TextArea
          className='group u-margin-bottom-20'
          rows={5}
          label={'Summary'}
          placeholder={'Write a short summary about this program'}
          name={'summary'}
          value={summary}
          handleChange={(event) => setSummary(event.target.value)}
          required={true}/>
        <FormOneButton
          className='group u-margin-bottom-40'
          error={errors.difficulty}
          label={'Difficulty*'}
          items={['Beginner','Intermediate','Advanced']}
          handleChange={(item)=>setDifficulty(item)}
          value={difficulty}/>
      </div>
      <div className='week-container'>
        {
          sessions.map((week,index)=>(
            <Week
              key={index}
              onDelete={(sessionIndex)=>deleteSession(sessionIndex,index)}
              onSortEnd={({oldIndex,newIndex}) => onSortEnd(oldIndex,newIndex,index)}
              addWorkout={()=>{
                setVisible(true)
                setWeek(index)
              }}
              deleteWeek={()=>deleteWeek(index)}
              number={index+1}
              sessions={week}/>
          ))
        }
        <div className='add-week'>
          <Button
            onClick={()=>setSessions([...sessions,[]])}
            text='Add a Week'
            className ='btn btn-purple'/>
        </div>
      </div>
      <Modal visible={visible}>
        <SessionsModal
          sessions={foundSessions}
          onSave={(session)=>addWorkoutToWeek(session)}
          onCancel={()=>setVisible(false)}/>
      </Modal>
      <Modal visible={deleteModalVisible}>
        <DeleteModal
          title={'Delete Program'}
          text={'Are you sure you want to delete this program? It will be permanetly deleted.'}
          onDelete={onDelete}
          onCancel={()=>setDeleteModalVisible(false)}/>
      </Modal>
    </section>

  )
}

export default ProgramForm
