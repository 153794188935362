import React,{useState,useEffect,useRef} from 'react';
import '../../sass/main.scss';
import { getDrills } from '../../services/drills'
import { getFavorites } from '../../services/favorites'
import Header from '../../components/drills/header'
import Drill from '../../components/cards/drill'
import Spinner from '../../components/common/spinner'
import LoadMore from '../../components/common/load-more'
import { connect,useDispatch} from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectCategories,selectDifficulty,selectEquipment,selectSpace } from '../../redux/selectors/drills'
import DrillModal from '../../components/modals/drill'
import Modal from '../../components/modals/modal'
import Table from '../../components/common/table'
const LIMIT = 52

const DrillsTable = ({categories,difficulty,equipment,space}) =>{
  //====================================
  // STATE
  //====================================
  const [loading,setLoading] = useState(true)
  const [drills,setDrills] = useState([])
  const [search,setSearch] = useState('')
  const [skip,setSkip] = useState(0)
  const [canLoadMore,setCanLoadMore] = useState(false)
  const [loadingMore,setLoadingMore] = useState(false)
  const [drillModalVisible,setDrillModalVisible] = useState(false)
  const [selectedDrillId,setSelectedDrillId] = useState(null)
  const [tab,setTab] = useState(0)
  const listInnerRef = useRef();
  const Headers = [
    {label:'',value:'url'},
    {label:'Title',value:'title'},
    {label:'Space Needed',value:'space'},
    {label:'Category',value:'category'},
    {label:'Difficulty',value:'difficulty'},
  ]

  //====================================
  // HOOKS
  //====================================
  const navigate = useNavigate()
  useEffect(()=>{
    setSkip(0)
    setLoading(true)
    const fetchData = async() =>{
      let response
      if(tab === 0 || tab == 2){
        response = await getData(0)
        checkIfCanLoadMore(0,response.length)
      }else if(tab == 1){
        response = await getFavorites()
        setCanLoadMore(false)
      }
      setDrills(response)
      setLoading(false)
      window.analytics.track('Drills');
    }
    fetchData()

  },[categories,difficulty,equipment,space,search,tab])

  //====================================
  // HANDLERS
  //====================================
  const handleScroll = e => {
    let element = e.target
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      // do something at end of scroll
    }
  }

  const checkIfCanLoadMore = (skip,length) =>{
    if(length === LIMIT){
      setCanLoadMore(true)
      setSkip(skip + LIMIT)
    }else{
      setCanLoadMore(false)
    }
  }

  const loadMore = async() =>{
    if(!loading && !loadingMore){
      setLoadingMore(true)
      const response = await getData(skip)
      checkIfCanLoadMore(skip,response.length)
      setDrills([...drills,...response])
      setLoadingMore(false)
    }
  }

  const getData = async(skip) =>{
    const response = await getDrills(
      JSON.stringify(categories),
      JSON.stringify(difficulty),
      JSON.stringify(equipment),
      JSON.stringify(space),
      tab === 2 ? 'true' : 'false',
      search,
      skip
    )
    return response
  }

  const onSearch = (search) =>{
    setSearch(search)
  }

  return (
    <main
      onScroll={handleScroll}
      className='section-container'>
      <Header
        title={'All Drills'}
        onCreate={()=>navigate('/drills/create')}
        search={search}
        onSearch={(search)=>onSearch(search)}
        categories={categories}
        difficulty={difficulty}
        equipment={equipment}
        space={space}
        selected={tab}
        onSelect={(tab)=> setTab(tab)}
        />
      {
        !loading
          ?<section className='week-container'>
            <Table
              noItemsTitle='No Favorites Saved'
              noItemsSubtitle='Drills you favorite will appear here.'
              lessPadding
              onDrill={(id)=>{
                setSelectedDrillId(id)
                setDrillModalVisible(true)
              }}
              headers={Headers}
              rows={drills}/>
            </section>
          :<div style={{marginTop:100}}><Spinner/></div>
      }

      {
        !loading && canLoadMore
         &&
         <LoadMore onClick={loadMore}/>
      }
      <Modal visible={drillModalVisible}>
        <DrillModal
          drillId={selectedDrillId}
          onCancel={()=>setDrillModalVisible(false)}/>
      </Modal>
    </main>
  )
}

const mapStateToProps = state =>{
  return {
    categories:selectCategories(state),
    difficulty:selectDifficulty(state),
    equipment:selectEquipment(state),
    space:selectSpace(state)
  }
}
export default connect(mapStateToProps)(DrillsTable)
