import React,{useEffect,useState} from 'react'
import '../../sass/main.scss'
import Tab from './tab'
import { FaCaretRight,FaChartBar,FaFutbol,FaRegCalendarCheck,FaUserFriends,FaRegHeart,FaClipboardList,FaUserAlt,FaChartPie,FaDollarSign,FaRocketchat,FaRegImages } from 'react-icons/fa';
import Header from './header'
import {useLocation} from "react-router-dom";

const SideNavBar = ({logo,title}) =>{
  //================================
  // STATe
  //================================
  const [active,setActive] = useState(0)
  //================================
  // HOOKS
  //================================
  let { pathname } = useLocation();
  useEffect(()=>{
    pathname = pathname.split('/')
    pathname = pathname[1]
    switch (pathname) {
      case 'stats':
        setActive(0)
        break;
      case 'drills':
        setActive(1)
        break;
      case 'workouts':
        setActive(2)
        break;
      case 'programs':
        setActive(3)
        break;
      case 'players':
        setActive(4)
        break;
      case 'media':
        setActive(5)
        break;
      case 'profile':
        setActive(6)
        break;
      case 'dashboard':
        setActive(7)
        break;
      case 'pricing':
        setActive(8)
        break;
      case 'messages':
        setActive(9)
        break;
      default:
        setActive(7)
    }
  },[])

  return (
    <nav className="side-nav">
      <Header logo={logo} title={title}/>
      <ul>
        <Tab onClick = {()=>setActive(7)} icon={<FaChartBar color={active == 7 ? 'white' : '#666'} size={20}/>} title={'Dashboard'} to={'/dashboard'} active={active == 7}/>
        <Tab onClick = {()=>setActive(0)} icon={<FaChartPie color={active == 0 ? 'white' : '#666'} size={20}/>} title={'Stats'} to={'/stats'} active={active == 0}/>
        <Tab onClick = {()=>setActive(1)}icon={<FaFutbol color={active == 1 ? 'white' : '#666'} size={20}/>} title={'Drills'} to={'/drills'}active={active == 1}/>
        <Tab onClick = {()=>setActive(2)}icon={<FaClipboardList color={active == 2 ? 'white' : '#666'} size={20}/>} title={'Workouts'} to={'/workouts'} active={active == 2}/>
        <Tab onClick = {()=>setActive(3)}icon={<FaRegCalendarCheck color={active == 3 ? 'white' : '#666'} size={20}/>} title={'Programs'} to={'/programs'} active={active == 3}/>
        <Tab onClick = {()=>setActive(4)}icon={<FaUserFriends color={active == 4 ? 'white' : '#666'} size={20}/>} title={'Players'} to={'/players'} active={active == 4}/>
        <Tab onClick = {()=>setActive(9)}icon={<FaRocketchat color={active == 9 ? 'white' : '#666'} size={20}/>} title={'Messages'} to={'/messages'} active={active == 9}/>
        {
          /*
          <Tab onClick = {()=>setActive(5)}icon={<FaRegImages color={active == 5 ? 'white' : '#7b7e90'} size={20}/>} title={'Media'} to={'/media'} active={active == 5}/>

          */
        }

        <Tab onClick = {()=>setActive(6)}icon={<FaUserAlt color={active == 6 ? 'white' : '#7b7e90'} size={20}/>} title={'Profile'} to={'/profile'} active={active == 6}/>
      </ul>
    </nav>
  )
}

export default SideNavBar
