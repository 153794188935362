import React,{useRef} from 'react'
import '../../sass/main.scss'
import Spinner from './spinner'
import Button from './button'
const FormVideo = ({value,error,label,name,handleChange,className,loading}) =>{
  const inputFile = useRef(null)
  return (
    <div className={className ? className : 'group'}>
      {
        label && <label className='h3-black bold u-margin-bottom-15'>{label}</label>
      }
      {
        !loading
          ?<div>
            <div>
              <video key={value} width='450' className='form-input-video' controls >
                 <source src={value} type="video/mp4"/>
              </video>
              <input
                accept="video/*"
                onInput={handleChange}
                type='file'
                name={name}
                ref={inputFile}
                style={{display: 'none'}}/>
            </div>

            <Button
              onClick={()=>inputFile.current.click()}
              text={'Edit Video'}
              className={'btn btn-green u-margin-top-15'}/>
          </div>
          :<Spinner/>
      }

      {error && <p>{error}</p>}
    </div>
  )
}

export default FormVideo
