import React,{useState} from 'react'
import '../../sass/main.scss'

const ConnectToStripe = ({onConnectToStripe,onLearnMore}) =>{
  const info = (title,content) =>{
    return (
      <div>
        <h3 className='h3-black bold'>{title}:</h3>
        <p className='h3-black u-margin-bottom-20'>
          {content}
        </p>
      </div>
    )
  }
  return (
    <div className='card'>
      <h2 className='h2-black bold label-header'>My Coach Subscriptions</h2>
      <div className='u-padding-20'>
        {info('About','My Coach subscriptions is a fully managed payment service that allows coaches to provide recurring monthly subscriptions for their service.')}
        {info('Who is this for','This option is great for coaches who want to avoid managing payments from their players and coaches who have virtual clients.')}
        {info('How does it work','After connecting to a Stripe account, coaches will select their monthly subscription price. Next, My Coach will set up your subscription and create a payment link. Finally, you will receive a unique link ex. mycoachapp.co/your-academy and will be able to share that with your players. Once a purchase is made, you will be notified and receive payment to your bank account via Stripe.')}
        {info('How billing works','My Coach takes 35% commission from every sale. There is no cost to the coach otherwise. ')}
        <div className='center'>
          <a onClick={onConnectToStripe} className='btn btn-purple u-margin-right-5'>Connect To Stripe</a>
          <a onClick={onLearnMore} className='btn btn-grey'>Learn More</a>
        </div>
      </div>
    </div>
  )
}

export default ConnectToStripe
