import React from 'react'
import '../../sass/main.scss'
import Input from './form-input'

const FormInstructions = ({title,instructions,onAddStep,onRemoveStep,onStepChange}) =>{
  return (
    <section>
      <h3 className='h3-black bold u-margin-bottom-20'>{title}</h3>
      {
        instructions.map((instruction,index)=>(
          <Input
            key={index}
            className="group u-margin-bottom-20"
            label={"Step "+(index + 1)}
            type={"text"}
            placeholder={""}
            name={"step-"+(index+1)}
            value={instruction}
            handleChange={(event) => onStepChange(event.target.value,index)}
          />
        ))
      }
      <div style={{textAlign:'center'}}>
        {
          instructions.length < 5 && <a onClick={onAddStep} className='btn btn-purple u-margin-right-5'>Add Step</a>
        }
        {
          instructions.length > 1 && <a onClick={onRemoveStep} className='btn btn-light-red'>Remove Step</a>
        }
      </div>
    </section>
  )
}

export default FormInstructions
