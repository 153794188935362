import React,{useState,useEffect} from 'react'
import '../../sass/main.scss';
import Nav from '../../components/players/navbar'
import { getPlayersStats } from '../../services/players'
import { useNavigate,useParams } from 'react-router-dom'
import Week from '../../components/players/stats-week'
import Spinner from '../../components/common/spinner'
import StatsModal from '../../components/modals/stats'
import Modal from '../../components/modals/modal'
import StatCard from '../../components/stats/stat-card'
import Info from '../../components/stats/info'


const Stats = ({player,schedule,subscription,stats}) =>{
  //========================================
  //STATE
  //========================================
  const [statsModalVisible,setStatsModalVisible] = useState(false)
  const [session,setSession] = useState(null)

  return(
    <main>
      <section>
        <section className='week-container'>
            <h3 className='h3-black bold u-margin-bottom-15'>Overall Stats</h3>
            <div className='grid-three u-margin-bottom-40'>
              <StatCard title={'Minutes'} value={Math.floor(stats.duration/60)}/>
              <StatCard title={'Drills'} value={stats.drills}/>
              <StatCard title={'Sessions'} value={stats.sessions}/>
            </div>
            <h3 className='h3-black bold u-margin-bottom-15'>Previous Schedule</h3>
            {
              schedule.map((week,index)=>(
                <Week
                  onClick={(session)=>{
                    setSession(session)
                    setStatsModalVisible(true)
                  }}
                  key={index}
                  number={index+1}
                  startDate={week.start_date}
                  endDate={week.end_date}
                  sessions={week.sessions}/>
              ))
            }
          </section>
      </section>
        {
          session
            &&
            <Modal visible={statsModalVisible}>
              <StatsModal
                onCancel={()=>setStatsModalVisible(false)}
                rating={session.rating}
                comment={session.comment}
                title={session.id.title}
                drills={session.id.drills}
                drillsComplete={session.drills_complete}/>
            </Modal>
        }

    </main>
  )
}

export default Stats
