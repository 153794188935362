import React from 'react'
import '../../sass/main.scss'
import { Link } from 'react-router-dom'

const Header = ({logo,title,onClick}) =>{
  return (
    <div className = 'side-nav-header' onClick={()=>onClick}>
      <img
        className='logo'
        src={logo}/>
      <h3 className='h3-black bold'>{title}</h3>
    </div>
  )
}

export default Header
