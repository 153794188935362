import React,{useState,useEffect} from 'react'
import '../../sass/main.scss';
import Button from '../common/button'
import DateCard from '../cards/date'

const Delete = ({onCancel,onSave,weeks}) =>{
  //===============================
  // STATE
  //===============================
  const [active,setActive] = useState(0)

  return (
    <div className='modal-medium'>
      <div className='modal-content'>
        <h2 className='h2-black bold u-margin-bottom-10'>Select the starting week</h2>
        <div className='grid-four u-margin-bottom-20'>
          {
            weeks.map((week,index)=>(
              <DateCard
                onClick={()=>setActive(index)}
                active={active === index}
                startDate={week.start_date}
                endDate={week.end_date}/>
            ))
          }
        </div>
        <Button
          className='btn btn-block btn-green u-margin-bottom-5'
          text={'Save'}
          onClick={()=>onSave(active)}/>
        <Button className='btn btn-block btn-grey' text={'Cancel'} onClick={onCancel}/>
      </div>
    </div>
  )
}

export default Delete
