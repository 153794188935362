import axios from 'axios';
import { API } from '../utils/variables'
import { getConfig } from '../utils/helpers'
import { toast } from 'react-toastify';


//deactivate player
export const deletePlayer = async (id) =>{
  try{
    const config = await getConfig()
    const route = `${API}/subscribe/${id}`;
    const response = await axios.delete(route,config)
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}

//deactivate player
export const deactivatePlayer = async (id) =>{
  try{
    const config = await getConfig()
    const route = `${API}/deactivate/${id}`;
    const response = await axios.put(route,{},config)
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}

//activate player
export const activatePlayer = async (id) =>{
  try{
    const config = await getConfig()
    const route = `${API}/activate/${id}`;
    const response = await axios.put(route,{},config)
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}

//updateSubscriptionPrice
export const updateSubscriptionPrice = async (body) =>{
  try{
    const config = await getConfig()
    const route = `${API}/subscription-price/`;
    const response = await axios.put(route,body,config)
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}
