import React from 'react'
import InitialQuestions from '../../components/players/initial-questions'


const Info = ({subscription}) =>{
  return (
    <section>
      <InitialQuestions {...subscription}/>
    </section>
  )
}

export default Info
