import React,{useState,useEffect} from 'react'
import '../../sass/main.scss';
import Header from './header'
import Footer from './footer'
import FormOneButton from '../common/form-one-button'

const AddToGroup = ({item,groups,onCancel,onSave}) =>{
  //===========================
  //STATE
  //===========================
  const [group,setGroup] = useState(item)

  const saveGroup = () =>{
    if(group){
      onSave(group)
    }
  }

  return(
    <div className='modal-small'>
      <Header
        title={'Select Group'}
        onClick={onCancel}/>
      <div className='modal-content'>
        <FormOneButton
          className='group u-margin-bottom-20 u-margin-top-20'
          items={groups}
          handleChange={(item)=>setGroup(item)}
          value={group}/>
      </div>
      <Footer
        onSubmit={saveGroup}
        onCancel={onCancel}/>
    </div>
  )
}

export default AddToGroup
