import React,{useState,useEffect} from 'react';
import '../../sass/main.scss';
import { getSessions,deleteManySessions } from '../../services/sessions'
import Header from '../../components/sessions/header'
import Session from '../../components/cards/session'
import LoadMore from '../../components/common/load-more'
import Spinner from '../../components/common/spinner'
import NoItems from '../../components/common/noitems'
import { useNavigate,useParams } from 'react-router-dom'
import Table from '../../components/common/table'
import Modal from '../../components/modals/modal'
import DeleteModal from '../../components/modals/delete'
const LIMIT = 20
const SessionsTable = ({}) =>{
  //====================================
  // STATE
  //====================================
  const [loading,setLoading] = useState(true)
  const [sessions,setSessions] = useState([])
  const [skip,setSkip] = useState(0)
  const [canLoadMore,setCanLoadMore] = useState(false)
  const [loadingMore,setLoadingMore] = useState(false)
  const [checked,setChecked] = useState([])
  const [indecies,setIndecies] = useState([])
  const [deleteModalVisible,setDeleteModalVisible] = useState(false)
  const Headers = [
    {label:'',value:'_id'},
    {label:'',value:'thumbnail'},
    {label:'Title',value:'title'},
    {label:'Duration',value:'duration'},
    {label:'Difficulty',value:'difficulty'},
    {label:'Created',value:'created'}
  ]

  //====================================
  // HOOKS
  //====================================
  const navigate = useNavigate()
  useEffect(()=>{
    setLoading(true)
    setSkip(0)
    const fetchData = async() =>{
      const response = await getData()
      setSessions(response)
      setLoading(false)
      checkIfCanLoadMore(response.length)
      window.analytics.track('Sessions');
    }
    fetchData()
  },[])

  //====================================
  // HANDLERS
  //====================================
  const checkIfCanLoadMore = (length) =>{
    if(length === LIMIT){
      setCanLoadMore(true)
      setSkip(skip + LIMIT)
    }else{
      setCanLoadMore(false)
    }
  }

  const loadMore = async() =>{
    if(!loading && !loadingMore){
      setLoadingMore(true)
      const response = await getData()
      checkIfCanLoadMore(response.length)
      setSessions([...sessions,...response])
      setLoadingMore(false)
    }
  }

  const getData = async() =>{
    const response = await getSessions(skip)
    return response
  }

  const onCheck = (session) =>{
    const {id,idx} = session
    const index = checked.indexOf(id)
    if(index === -1){
      setChecked([...checked,id])
      setIndecies([...indecies,idx])
    }else{
      let temp = [...checked]
      let temp2 = [...indecies]
      temp.splice(index,1)
      temp2.splice(index,1)
      setChecked(temp)
      setIndecies(temp2)
    }
  }

  const onBulkDelete = async() =>{
    await deleteManySessions(checked)
    let temp =[...sessions]
    temp = temp.filter(function(value, index) {
         return indecies.indexOf(index) == -1;
    })
    setSessions(temp)
    setIndecies([])
    setChecked([])
  }
  return (
    <main className='section-container'>
      <Header
        onDelete={()=>setDeleteModalVisible(true)}
        canDelete={checked.length}
        onCreate={()=>navigate('/workouts/create')}
        title={'My Workouts'}/>
      {
        !loading
          ? sessions.length > 0
              ? <section className='week-container'>
                  <Table
                    lessPadding
                    checked={checked}
                    onCheck={(id) => onCheck(id)}
                    onDrill={(id)=>navigate('/workouts/edit/'+id)}
                    headers={Headers}
                    rows={sessions}/>
                </section>
              :<section className='week-container'>
              <NoItems
                  title={'Create your First Workout'}
                  subtitle={'Click the button below to get started'}
                  button={'Create a Workout'}
                  onClick={()=>navigate('/workouts/create')}/>
            </section>

          :<div style={{marginTop:100}}><Spinner/></div>
      }
      <Modal visible={deleteModalVisible}>
        <DeleteModal
          title={'Delete Workouts'}
          text={'Are you sure you want to delete these workouts? They will be permanetly deleted.'}
          onDelete={onBulkDelete}
          onCancel={()=>setDeleteModalVisible(false)}/>
      </Modal>

      {
        canLoadMore
         &&
         <LoadMore onClick={loadMore}/>
      }

    </main>
  )
}

export default SessionsTable;
