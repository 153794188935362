import React,{useState} from 'react'
import '../../sass/main.scss';
import Header from './header'
import Footer from './footer'
import ProgramsCard from '../cards/program'
import Table from '../../components/common/table'

const ProgramsModal = ({programs,onCancel,onSave}) =>{
  //==================================
  //STATE
  //==================================
  const [active,setActive] = useState(0)
  const Headers = [
    {label:'',value:'thumbnail'},
    {label:'Title',value:'title'},
    {label:'Duration',value:'sessions'},
    {label:'Difficulty',value:'difficulty'},
    {label:'Created',value:'created'}
  ]
  return (
    <div className='modal-large'>
      <Header
        title={'Select a Program'}
        onClick={onCancel}/>
      <div style={{height:'75vh'}} className='modal-grey modal-content modal-content-scrollable'>
        <section className='week-container'>
            <Table
              lessPadding
              onSelect={(program)=>onSave(program)}
              headers={Headers}
              rows={programs}/>
          </section>
      </div>
    </div>
  )
}

export default ProgramsModal;
