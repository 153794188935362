import React,{useState,useRef,useEffect} from 'react';
import '../../sass/main.scss';
import Input from '../../components/common/form-input'
import Button from '../../components/common/button'
import { editCoach,editLogo } from '../../services/coaches'
import { toast } from 'react-toastify';
import NavBar from '../../components/onboarding/navbar'
import { useNavigate } from 'react-router-dom'

const Logo = ({}) =>{
  //===============================
  //STATE
  //===============================
  const [logo,setLogo] = useState('')
  const [image,setImage] = useState('https://globasport.sfo2.cdn.digitaloceanspaces.com/clubLogo.png')
  const navigate = useNavigate()
  const inputFile = useRef(null)
  useEffect(()=>{
    const token = localStorage.getItem('token');
    if(!token) navigate('/login')
  },[])
  //===============================
  //HANDLERS
  //===============================
  const onSubmit = async() =>{
    const data = new FormData()
    data.append('file',logo)
    const response = await editLogo(data)
    localStorage.setItem('logo',response)
    navigate('/bio')

  }

  const handleLogoChange = async(event) =>{
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0]
      setLogo(file)
      setImage(URL.createObjectURL(event.target.files[0]))
     }

  }

  return(
    <section className='onboarding-container'>
      <NavBar
        onBack={()=>navigate('/levels')}
        title={'Academy Logo or Profile Pic?'}
        subtitle={'Upload a picture of either your logo or profile picture.'}
      />
     <div style={{textAlign:'center'}}>
        <img
          src={image}
          width='150' height='150' className='form-input-logo'/>
        <input
          accept="image/*"
          onInput={handleLogoChange}
          type='file'
          name={'logo'}
          ref={inputFile}
          style={{display: 'none'}}/>
      </div>
      <Button
        onClick={()=>{
          if(!logo){
            inputFile.current.click()
          }else{
            onSubmit()
          }
        }}
        text={!logo ? 'Upload Photo' : 'Next'}
        className='btn btn-lrg btn-block btn-green u-margin-top-10'
        />
    </section>
  )
}

export default Logo
