import React,{useEffect,useState} from 'react';
import '../../sass/main.scss';
import { getPlayersSchedule,updateSchedule } from '../../services/players'
import { getSessions } from '../../services/sessions'
import { getPrograms } from '../../services/programs'
import { useNavigate,useParams } from 'react-router-dom'
import Nav from '../../components/players/navbar'
import Month from '../../components/players/month'
import Week from '../../components/players/week'
import Modal from '../../components/modals/modal'
import SessionsModal from '../../components/modals/sessions-table'
import ProgramsModal from '../../components/modals/programs-table'
import DateModal from '../../components/modals/program-date'
import Button from '../../components/common/button'
import Spinner from '../../components/common/spinner'
import { toast } from 'react-toastify';
import { getMondayOfCurrentWeek } from '../../utils/helpers'



const Schedule = ({schedule,foundSessions,foundPrograms,setSchedule,playerId,coach,onSubmit}) =>{
  //====================================
  // STATE
  //====================================
  const [sessionsModalVisible,setSessionsModalVisible] = useState(false)
  const [programsModalVisible,setProgramsModalVisible] = useState(false)
  const [dateModalVisible,setDateModalVisible] = useState(false)
  const [program,setProgram] = useState(null)
  const [week,setWeek] = useState(null)

  //====================================
  // HOOKS
  //====================================
  const navigate = useNavigate()

  useEffect(() => {
   const warningText ='You have unsaved changes - are you sure you wish to leave this page?';
   const handleWindowClose = (e: BeforeUnloadEvent) => {
     e.preventDefault();
     return (e.returnValue = warningText);
   };
   window.addEventListener('beforeunload', handleWindowClose);
   return () => {
     window.removeEventListener('beforeunload', handleWindowClose);
   };
 }, []);

  //====================================
  // HANDLERS
  //====================================

  const removeWorkout = (sessionIndex,weekIndex) =>{
    const array = [...schedule]
    array[weekIndex].sessions.splice(sessionIndex,1)
    setSchedule(array)
  }

  const onSortEnd = (oldIndex,newIndex,weekIndex) =>{
    const array = Array.from(schedule)
    const items = Array.from(array[weekIndex].sessions)
    const [reorderedItem] = items.splice(oldIndex,1)
    items.splice(newIndex,0,reorderedItem)
    array[weekIndex].sessions = items
    setSchedule(array)
  }

  const openSessionsModal = (week) =>{
    setWeek(week)
    setSessionsModalVisible(true)
  }

  const addProgram = (weekIndex) =>{
    let array = [...schedule]
    program.sessions.map(async(sessions,index)=>{
      if(array[weekIndex+index]){
        sessions.map((session,index2)=>{
          schedule[weekIndex+index].sessions.push({
            id:{
              _id:session._id,
              title:session.title,
              thumbnail:session.thumbnail,
              categories:session.categories,
              difficulty:session.difficulty
            }
          })
        })
      }else{
        let week = newWeek()
        schedule.push(week)
        sessions.map((session,index2)=>{
          schedule[schedule.length - 1].sessions.push({
            id:{
              _id:session._id,
              title:session.title,
              thumbnail:session.thumbnail,
              categories:session.categories,
              difficulty:session.difficulty
            }
          })
        })

      }
    })


    setDateModalVisible(false)
    toast.success('The program was succefully added to the schedule')
  }

  const addWorkoutToWeek = (session) =>{
    const array = [...schedule]
      array[week].sessions.push({
        id:{
          _id:session._id,
          title:session.title,
          thumbnail:session.thumbnail,
          categories:session.categories,
          difficulty:session.difficulty
        }
      })
    setSchedule(array)
    setSessionsModalVisible(false)
  }

  const newWeek = () =>{
      const player = playerId
      let nextStartDate
      let nextEndDate
      let tempDate
      if(schedule.length > 0){
        const lastDate = new Date(schedule[schedule.length -1].end_date)
        nextStartDate = new Date(lastDate.setDate(lastDate.getDate() + 1))
        nextEndDate = new Date(lastDate.setDate(lastDate.getDate() + 6))
      }else{
        nextStartDate = getMondayOfCurrentWeek()
        tempDate = new Date(nextStartDate)
        nextEndDate = new Date(tempDate.setDate(tempDate.getDate() + 6))
      }
      nextStartDate.setHours(0,0,0,0);
      nextEndDate.setHours(0,0,0,0);
      let nextStartDateString = nextStartDate.getFullYear()+'-'+(nextStartDate.getMonth()+1)+'-'+nextStartDate.getDate()
      let nextEndDateString = nextEndDate.getFullYear()+'-'+(nextEndDate.getMonth()+1)+'-'+nextEndDate.getDate()
      return {
        _id:null,
        coach:coach,
        player:player,
        start_date:nextStartDate,
        end_date:nextEndDate,
        start_date_string:nextStartDateString,
        end_date_string:nextEndDateString,
        sessions:[]
      }
   }

   const addWeek = () =>{
     let week = newWeek()
     setSchedule([...schedule,week])
   }



  return (
    <main>
      <section>
         <section  className='week-container'>
           <div className='nav-btn u-margin-bottom-20'>
             <Button
               onClick={()=>setProgramsModalVisible(true)}
               text={'Add Program'}
               className='btn btn-grey u-margin-right-5'/>
             <Button
               onClick={onSubmit}
               text={'Save'}
               className='btn btn-green'/>
           </div>
             {
               schedule.map((week,index)=>(
                 <Week
                   key={index}
                   number={index+1}
                   startDate={week.start_date}
                   endDate={week.end_date}
                   openSessionsModal={() => openSessionsModal(index)}
                   onDelete={(sessionIndex)=>removeWorkout(sessionIndex,index)}
                   onSortEnd={({oldIndex,newIndex}) =>onSortEnd(oldIndex,newIndex,index)}
                   sessions={week.sessions}/>
               ))
             }
             <div className='add-week'>
               <Button
                 onClick={addWeek}
                 text='Add a Week'
                 className ='btn btn-purple'/>
             </div>
           </section>
       </section>
      <Modal visible={sessionsModalVisible}>
        <SessionsModal
          sessions={foundSessions}
          onSave={(session)=>addWorkoutToWeek(session)}
          onCancel={()=>setSessionsModalVisible(false)}/>
      </Modal>
      <Modal visible={programsModalVisible}>
        <ProgramsModal
          programs={foundPrograms}
          onSave={(program)=>{
            setProgram(program)
            setProgramsModalVisible(false)
            setDateModalVisible(true)
          }}
          onCancel={()=>setProgramsModalVisible(false)}/>
      </Modal>
      <Modal visible={dateModalVisible}>
        <DateModal
          weeks={schedule}
          onSave={(week)=>addProgram(week)}
          onCancel={()=>setDateModalVisible(false)}/>
      </Modal>
    </main>
  )
}


export default Schedule
