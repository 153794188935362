import React from 'react'
import '../../sass/main.scss';
import Button from '../common/button'


const Footer = ({onSubmit,onPrevious,save}) => {
  return (
    <div className='modal-footer' style={{justifyContent:'flex-start',paddingLeft:5}}>
      <div className='modal-footer-btn'>
        <Button
          className={'btn btn-grey'}
          text={'Previous'}
          onClick={onPrevious}
        />
      </div>
      <div className='modal-footer-btn' style={{flex:1,textAlign:'right'}}>
        <a onClick={onSubmit} className={'btn btn-green'}>
          { save ? save : 'Save'}
        </a>
      </div>
    </div>
  )
}

export default Footer
