import axios from 'axios';
import { API } from '../utils/variables'
import { getConfig } from '../utils/helpers'
import { toast } from 'react-toastify';


//get coach profile
export const getCoach = async () =>{
  try{
    const config = await getConfig()
    const route = `${API}/coach/`;
    const response = await axios.get(route,config)
    return response.data
  }catch(err){
    console.log(err)
    toast.error('An unexpected error occured')
  }
}
//get coaches dashboard
export const getDashboard = async () =>{
  try{
    const config = await getConfig()
    const route = `${API}/dashboard/`;
    const response = await axios.get(route,config)
    return response.data
  }catch(err){
    console.log(err)
    toast.error('An unexpected error occured')
  }
}
//edit coach
export const editCoach = async (body) =>{
  try{
    const config = await getConfig()
    const route = `${API}/coaches/`;
    const response = await axios.put(route,body,config)
    toast.success('Your profile was succefully saved.')
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}

//edit logo
export const editLogo= async (body) =>{
  try{
    const config = await getConfig()
    const route = `${API}/profile-pic/`;
    const response = await axios.post(route,body,config)
    toast.success('Your logo was succefully saved.')
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}

//edit logo
export const editIntroVideo = async (body) =>{
  try{
    const config = await getConfig()
    const route = `${API}/intro-video/`;
    const response = await axios.post(route,body,config)
    toast.success('Your video was succefully saved.')
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}

//get payments
export const getPayments = async () =>{
  try{
    const config = await getConfig()
    const route = `${API}/payments/`;
    const response = await axios.get(route,config)
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}

//get subscription price
export const getSubscriptionPrice = async () =>{
  try{
    const config = await getConfig()
    const route = `${API}/subscribe`;
    const response = await axios.get(route,config)
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}
