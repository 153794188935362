import React,{useState} from 'react'
import '../../sass/main.scss';
import Header from './header'
import Footer from './footer'

const VideoModal = ({title,url,onCancel}) =>{
  return (
    <div className='modal-medium'>
      <Header
        title={title}
        onClick={onCancel}/>
      <div className='modal-content'>
        <video width="100%" height="300px" controls >
           <source src={url} type="video/mp4"/>
        </video>
      </div>
    </div>
  )
}

export default VideoModal;
