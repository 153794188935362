import React,{useEffect,useState} from 'react';
import '../../sass/main.scss';
import ProgramForm from '../../forms/program'
import { useNavigate,useParams} from 'react-router-dom'
import { editProgram,deleteProgram,getProgram } from '../../services/programs'

const EditProgram = ({}) =>{
  //===============================
  //STATE
  //===============================
  const [state,setState] = useState({title:'',summary:'',difficulty:'',ages:[],sessions:[],thumbnail:''})
  const [loading,setLoading] = useState(true)
  //===============================
  //HOOKS
  //===============================
  const { programId } = useParams()
  const navigate = useNavigate()
  useEffect(()=>{
    const fetchData = async() =>{
      const response = await getProgram(programId)
      setState(response)
      setLoading(false)
      window.analytics.track('Edit Program');
    }
    fetchData()

  },[])

  useEffect(() => {
   const warningText ='You have unsaved changes - are you sure you wish to leave this page?';
   const handleWindowClose = (e: BeforeUnloadEvent) => {
     e.preventDefault();
     return (e.returnValue = warningText);
   };
   window.addEventListener('beforeunload', handleWindowClose);
   return () => {
     window.removeEventListener('beforeunload', handleWindowClose);
   };
 }, []);

  //===============================
  //HANDLERS
  //===============================
  const onSubmit = async(program) =>{
    await editProgram(programId,program)
    navigate('/programs')
  }

  const onDelete = async() =>{
    await deleteProgram(programId)
    navigate('/programs')
    window.analytics.track('Delete Program');
  }

  return (
    <main className='section-container'>
      {
        !loading
          &&
          <ProgramForm
            onDelete={onDelete}
            onSubmit={(program)=>onSubmit(program)}
            _title={state.title}
            _summary={state.summary}
            _difficulty={state.difficulty}
            _ages={state.ages}
            _sessions={state.sessions}/>
      }
    </main>
  )
}

export default EditProgram
