import React from 'react'
import '../../sass/main.scss'

const Button = ({className,text,onClick}) => {
  return (
    <a onClick={onClick} className={className}>
      {text}
    </a>
  )
}

export default Button;
