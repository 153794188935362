import React,{useState} from 'react'
import '../../sass/main.scss'

const PricingInProgress = ({onAddPaymentMethod}) =>{
  return (
    <div className='card u-padding-20 u-margin-bottom-20'>
      <h2 className='h2-black bold u-margin-bottom-10'>Subscription Pricing In Review</h2>
      <p className='h3-grey u-margin-bottom-20' style={{lineHeight:1.3}}>
        We are currently creating your subscriptions for you. Your payment links will be ready to go in 2-3 bussiness days.
      </p>
    </div>
  )
}

export default PricingInProgress
