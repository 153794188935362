import React from 'react';
import '../../sass/main.scss';
import { FaChevronLeft,FaRegHeart,FaHeart } from "react-icons/fa";
import Button from '../common/button'
import { useNavigate } from 'react-router-dom'

const NavBar = ({title,onBack,onFavorite,isFavorite}) =>{
  const navigate = useNavigate()
  return (
    <nav className='nav'>
      <div className='nav-back u-margin-right-10'>
        <FaChevronLeft onClick={()=>navigate(onBack)} size={25}/>
      </div>
      <h2 className='h2-black bold'>{title}</h2>
      <div className='nav-btn'>
        <div style={{alignSelf:'center'}}>
          {
            isFavorite
              ? <FaHeart    size={30}  onClick={onFavorite}/>
              : <FaRegHeart size={30}  onClick={onFavorite}/>
          }
        </div>
      </div>
    </nav>
  )
}

export default NavBar
