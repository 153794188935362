import axios from 'axios';
import { API } from '../utils/variables'
import { getConfig } from '../utils/helpers'
import { toast } from 'react-toastify';

//get all favorites
export const getFavorites = async () =>{
  try{
    const config = await getConfig()
    const route = `${API}/favorites`;
    const response = await axios.get(route,config)
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}

//create favorite
export const createFavorite = async (itemId,type) =>{
  try{
    const config = await getConfig()
    const route = `${API}/favorites/${itemId}?type=${type}`;
    const response = await axios.post(route,{},config)
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}

//delete favorite
export const deleteFavorite = async (itemId,type) =>{
  try{
    const config = await getConfig()
    const route = `${API}/favorites/${itemId}?type=${type}`;
    const response = await axios.delete(route,config)
    return response.data
  }catch(err){
    toast.error('An unexpected error occured')
  }
}
