import React,{useState} from 'react'
import '../../sass/main.scss';
import Header from './header'
import Footer from './footer'
import ContentCard from '../media/content-card-display'


const SelectMedia = ({items,type,onCancel,onSubmit}) =>{
  console.log(items)
  //================================
  // STATE
  //================================
  const [item,setItem] = useState(null)
  //================================
  // HANDLERS
  //================================
  const onSelect = (selected) =>{
    if(item !== selected){
      setItem(selected)
    }else{
      setItem(null)
    }
  }

  return (
    <div className='modal-medium'>
      <Header
        title={'Select an Item'}
        onClick={onCancel}/>
      <div className='modal-content grid-four' style={{gap:0}}>
        {
          items.map((i) =>(
            <ContentCard
              onClick={()=>onSelect(i)}
              selected={item === i}
              url={i.kind === 'video' ? i.url.replace('.mp4','.jpg') : i.url}/>
          ))
        }
      </div>
      <Footer
        onSubmit={() => onSubmit(item)}
        onCancel={onCancel}/>
    </div>
  )
}

export default SelectMedia
