import React,{useState} from 'react'
import '../../sass/main.scss'
import LineTabBar from './line-tab-bar'
import Table from '../common/table'
import { FaQuestionCircle} from "react-icons/fa";
const Headers = [
  {label:'',value:'profile_pic'},
  {label:'Name',value:'name'},
  {label:'Joined',value:'created'},
  {label:'Status',value:'active'},
  {label:'',value:'deleteplayer'},
]

const NewAddedPlayers = ({onInvitePlayer,players,onBilling,question,deletePlayer,goToDeletedPlayers,estCost,credits}) =>{


  return (
    <div>
      <div className='clear-fix u-margin-bottom-40'>
        <div>
          <h2 className='h2-black bold u-margin-right-5'>Invited Players <FaQuestionCircle className='pointer' onClick={question}size={20} color={'#777'}/></h2>
          <p className='body1-black bold'>Estimated cost this month: <strong style={{color:'black'}}>${estCost}</strong></p>
          <p className='body1-grey bold'>Free Credits : ${credits}</p>
        </div>
        <div className='clear-fix-right'>
          <a onClick={onInvitePlayer} className='btn btn-border-purple u-margin-right-5'>+ Invite Player</a>
          <a onClick={goToDeletedPlayers} className='btn btn-border-light-red u-margin-right-5'>Deleted Players</a>
          <a onClick={onBilling} className='btn btn-grey'>Manage Billing</a>
        </div>
      </div>
      <Table
        lessPadding
        deletePlayer={(index,type)=>deletePlayer(index,type)}
        noItemsTitle={'No Players Added'}
        noItemsSubtitle={'Players that you add will appear here.'}
        headers={Headers}
        rows={players}/>
    </div>
  )
}

export default NewAddedPlayers
