import React,{useEffect,useState} from 'react';
import '../sass/main.scss';
import Nav from './form-navbar'
import Input from '../components/common/form-input'
import TextArea from '../components/common/form-textarea'
import FormOneButton from '../components/common/form-one-button'
import FormMultipleButton from '../components/common/form-multiple-button'
import { validateInfo,validateSession,estimateDuration } from '../utils/helpers'
import { useNavigate } from 'react-router-dom'
import DrillCard from '../components/cards/edit-drill-new'
import Modal from '../components/modals/modal'
import VideoModal from '../components/modals/video'
import DrillModal from '../components/modals/drill'
import DrillsModal from '../components/modals/drills-table'
import DeleteModal from '../components/modals/delete'
import GenerateModal from '../components/modals/generate-session'
import EditSetsRepsModal from '../components/modals/edit-sets-reps'
import AddCard from '../components/cards/add'
import { generateSession } from '../services/sessions'
import { getDrills } from '../services/drills'
import { getFavorites } from '../services/favorites'
import { connect,useDispatch} from 'react-redux'
import { selectCategories,selectDifficulty,selectEquipment,selectSpace } from '../redux/selectors/drills'
import { SortableContainer,SortableElement } from 'react-sortable-hoc'
import Button from '../components/common/button'
import GenerateQuestion from '../components/sessions/generate-question'

const LIMIT = 52

const SessionForm =  ({
    _title,_duration,_notes,_categories,_difficulty,_ages,_drills,_generateAsked,onDelete,onSubmit,
    filterCategories,filterEquipment,filterDifficulty,filterSpace,
  }) =>{
  //==================================
  //STATE
  //==================================
  const [title,setTitle] = useState(_title)
  const [duration,setDuration] = useState(_duration)
  const [notes,setNotes] = useState(_notes)
  const [categories,setCategories] = useState(_categories)
  const [difficulty,setDifficulty] = useState(_difficulty)
  const [ages,setAges] = useState(_ages)
  const [drills,setDrills] = useState(_drills)
  const [selectedDrillIndex,setSelectedDrillIndex] = useState(0)
  const [videoModalVisible,setVideoModalVisible] = useState(false)
  const [selectDrillsModalVisible,setSelectDrillsModalVisible] = useState(false)
  const [deleteModalVisible,setDeleteModalVisible] = useState(false)
  const [repsModalVisible,setRepsModalVisible] = useState(false)
  const [generateModalVisible,setGenerateModalVisible] = useState(false)
  const [errors,setErrors] = useState({title:'',difficulty:''})

  const [generateAsked,setGenerateAsked] = useState(_generateAsked)
  const [loading,setLoading] = useState(true)
  const [foundDrills,setFoundDrills] = useState([])
  const [skip,setSkip] = useState(0)
  const [search,setSearch] = useState('')
  const [canLoadMore,setCanLoadMore] = useState(false)
  const [loadingMore,setLoadingMore] = useState(false)
  const [favorites,setFavorites] = useState(false)
  const [tab,setTab] = useState(0)
  //==================================
  //HOOKS
  //==================================
  useEffect(()=>{
    setSkip(0)
    setCanLoadMore(false)
    setLoading(true)
    const fetchData = async() =>{
      if(tab === 0 || tab === 2){
        const response = await fetchDrills(0)
        checkIfCanLoadMore(0,response.length)
        setFoundDrills(response)
      }else{
        const response = await getFavorites()
        setFoundDrills(response)
      }
      setLoading(false)
    }
    fetchData()
  },[filterCategories,filterEquipment,filterDifficulty,filterSpace,search,tab])


  //==================================
  // DRILL HANDLERS
  //==================================

  const checkIfCanLoadMore = (skip,length) =>{
    if(length === LIMIT){
      setCanLoadMore(true)
      setSkip(skip + LIMIT)
    }else{
      setCanLoadMore(false)
    }
  }

  const loadMore = async() =>{
    if(!loading && !loadingMore){
      setLoadingMore(true)
      const response = await fetchDrills(skip)
      checkIfCanLoadMore(skip,response.length)
      setFoundDrills([...foundDrills,...response])
      setLoadingMore(false)
    }
  }

  const fetchDrills = async(skip) =>{
    const response = await getDrills(
      JSON.stringify(filterCategories),
      JSON.stringify(filterDifficulty),
      JSON.stringify(filterEquipment),
      JSON.stringify(filterSpace),
      tab === 2 ? 'true' : 'false',
      search,
      skip
    )
    return response
  }

  //==================================
  // SESSION HANDLERS
  //==================================

  const _createSession = async() =>{
    let session ={
      title,
      duration:Math.floor(duration / 60),
      notes,
      categories,
      difficulty,
      ages,
      drills
    }
    onSubmit(session)
  }

  const handleSubmit = () =>{
    if(validateSession(drills)){
      const keys = {title:true,difficulty:true}
      let errorMessages = validateInfo({title,difficulty},keys)
      if(Object.entries(errorMessages).length === 0){
        _createSession()
      } else{
        setErrors(errorMessages)
      }
    }
  }

  const handleAgeChange = (item) =>{
    if(ages.includes(item)){
      let array = [...ages]
      let index = array.indexOf(item)
      array.splice(index,1)
      setAges(array)
    }else{
      setAges([...ages,item])
    }
  }

  const handleCategoryChange = (item) =>{
    if(categories.includes(item)){
      let array = [...categories]
      let index = array.indexOf(item)
      array.splice(index,1)
      setCategories(array)
    }else{
      setCategories([...categories,item])
    }
  }

  const deleteDrill = (drill) =>{
    const index = drills.indexOf(drill)
    let array = [...drills]
    array.splice(index,1)
    setDrills(array)
    setDuration(duration - estimateDuration(drill))
  }


  const onSetsRepsChange = (sets,reps) =>{
    let array = [...drills]
    array[selectedDrillIndex].sets = sets
    array[selectedDrillIndex].reps = reps
    setDrills(array)
    setRepsModalVisible(false)
  }

  const openSetsRepsModal = (drill) =>{
    const index = drills.indexOf(drill)
    setSelectedDrillIndex(index)
    setRepsModalVisible(true)
  }

  const openDrillVideoModal = (drill) => {
    const index = drills.indexOf(drill)
    if(!selectDrillsModalVisible){
      setSelectedDrillIndex(index)
      setVideoModalVisible(true)
    }
  }

  const openDrillsModal = () =>{
    if(!videoModalVisible){
      setSelectDrillsModalVisible(true)
    }
  }

  const addDrills = (drill) =>{
    let newDrill = {
      id:{
        _id:drill._id,
        title:drill.title,
        url:drill.url,
        single:drill.single,
      },
      category:drill.category,
      sets:drill.sets,
      reps:drill.repRange[1] ? drill.repRange[1] : 6,
      timed:drill.timed,
      single:drill.single,
      rest:drill.rest,
      estTime:drill.estTime,
      repRange:drill.repRange
    }
    setDuration(duration + estimateDuration(drill))
    setDrills([...drills,newDrill])
    setSelectDrillsModalVisible(false)
  }


  const SortableItem = SortableElement(({ value, index}) =>(
      <DrillCard
        key={value._id}
        index={index}
        category={value.category}
        onView={()=>openDrillVideoModal(value)}
        onEdit={()=>openSetsRepsModal(value)}
        onDelete={()=>deleteDrill(value)}
        setss={value.sets}
        reps={value.reps}
        timed={value.timed}
        {...value.id}/>
  ))

  const SortableList = SortableContainer(({ items}) =>{
    return(
      <div className='grid-four'>
        {
          items.map((value,index) =>(
          <SortableItem
            value={value}
            index={index}
            key={value._id}/>
        ))

       }
       <AddCard title={'Add a Drill'} onClick={openDrillsModal}/>
      </div>
    )
  })

  const getWorkout = () =>{
    if(!generateAsked){
      return(
        <GenerateQuestion onClick={(res) => {
            setGenerateAsked(true)
            if(res) setGenerateModalVisible(true)
          }}/>
      )
    }else{
      return (
        <SortableList distance={1} items={drills} onSortEnd={onSortEnd} axis='xy'/>
      )
    }
  }

  const onSortEnd = ({oldIndex,newIndex}) =>{
    const items = Array.from(drills)
    const [reorderedItem] = items.splice(oldIndex,1)
    items.splice(newIndex,0,reorderedItem)
    setDrills(items)
  }

  const onGenerateWorkout = async(filters) =>{
    const response = await generateSession(filters)
    setDuration(filters.duration * 60)
    setDrills(response)
    setGenerateModalVisible(false)
  }

  return(
    <section>
      <Nav
        onBack={'/workouts'}
        onDelete={onDelete ? ()=>setDeleteModalVisible(true) : null}
        title={title}
        onSave={handleSubmit}/>
      <div className='form-container'>
        <Input
          className='group u-margin-bottom-20'
          error={errors.title}
          label={'Title*'}
          type={'text'}
          placeholder={'Workout title'}
          name={'title'}
          maxLength={50}
          value={title}
          handleChange={(event) => setTitle(event.target.value)}
          required={true}/>
        <TextArea
          className='group u-margin-bottom-20'
          rows={5}
          label={'Notes'}
          placeholder={'Add any notes you would like the players to have about this session.'}
          name={'notes'}
          value={notes}
          handleChange={(event) => setNotes(event.target.value)}
          required={true}/>
        <FormOneButton
          className='group u-margin-bottom-40'
          error={errors.difficulty}
          label={'Difficulty*'}
          items={['Beginner','Intermediate','Advanced']}
          handleChange={(item)=>setDifficulty(item)}
          value={difficulty}/>
        </div>
        <div className='week-container'>
          <h3 className='h3-black bold'>Drills (est. duration {Math.floor(duration/60)} minutes)</h3>
          {generateAsked && <p className='body1-grey bold u-margin-bottom-10'>Hold to re-order</p>}
          {getWorkout()}
        </div>
     { drills.length > 0
       &&
       <Modal visible={videoModalVisible}>
         <DrillModal
           drillId={drills[selectedDrillIndex].id._id}
           onCancel={()=>setVideoModalVisible(false)}/>
       </Modal>
     }
     {
       drills.length > 0
        &&
        <Modal visible={repsModalVisible}>
          <EditSetsRepsModal
            sets={drills[selectedDrillIndex].sets}
            reps={drills[selectedDrillIndex].reps}
            single={drills[selectedDrillIndex].id.single}
            timed={drills[selectedDrillIndex].id.timed}
            onSave={(sets,reps) => onSetsRepsChange(sets,reps)}
            onCancel={()=>setRepsModalVisible(false)}/>
        </Modal>
     }
     <Modal visible={selectDrillsModalVisible}>
       <DrillsModal
         search={search}
         onSearch={(search)=>setSearch(search)}
         canLoadMore={canLoadMore}
         loadMore={loadMore}
         loading={loading}
         selected={tab}
         onSelect={(tab) =>setTab(tab)}
         categories={filterCategories}
         equipment={filterEquipment}
         difficulty={filterDifficulty}
         space={filterSpace}
         drills={foundDrills}
         onSave={(drills)=>addDrills(drills)}
         onCancel={()=>setSelectDrillsModalVisible(false)}/>
     </Modal>
     <Modal visible={deleteModalVisible}>
       <DeleteModal
         title={'Delete Workout'}
         text={'Are you sure you want to delete this workout? It will be permanetly deleted.'}
         onDelete={onDelete}
         onCancel={()=>setDeleteModalVisible(false)}/>
     </Modal>
     <Modal visible={generateModalVisible}>
       <GenerateModal
         onSave={(filters)=>onGenerateWorkout(filters)}
         onCancel={()=>{
           setGenerateModalVisible(false)
           setGenerateAsked(false)
         }}/>
     </Modal>

    </section>
  )
}

const mapStateToProps = state =>{
  return {
    filterCategories:selectCategories(state),
    filterDifficulty:selectDifficulty(state),
    filterEquipment:selectEquipment(state),
    filterSpace:selectSpace(state)
  }
}
export default connect(mapStateToProps)(SessionForm)
