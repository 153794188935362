import React from 'react'
import '../../sass/main.scss'
import { Link } from 'react-router-dom'
import { FaPlay,FaPlayCircle,FaMinusCircle,FaTrashAlt } from 'react-icons/fa';
import Input from '../common/form-small-input'
import Button from '../common/button'

const EditDrillCard = ({title,_id,url,setss,reps,single,timed,onEdit,onView,onDelete,setSets,setReps,index}) =>{
  return (
    <div className='card'>
      <div className='thumbnail' style={{backgroundImage:`url(${url.replace('.mp4','.jpg')})`}}>
        <div className='overlay'>
          <div className='remove-icon'>
            <FaTrashAlt onClick={onDelete} size={20} color={'red'}/>
          </div>
        </div>
      </div>
      <section className='card-bottom'>
        <p className='body1-black bold u-margin-bottom-5'>{title}</p>
        <div>
          <span className='tag tag-grey'>{single ? 'Single Foot' : 'Both Feet'}</span>
          <span className='tag tag-grey'>{timed ? 'Timed' : 'Reps'}</span>
        </div>
        <p style={{textAlign:'center',marginTop:20}} className='body1-black bold'>{setss} x {reps} {timed ? 'seconds' : 'reps'}</p>
      </section>
      <div style={{padding:5,display:'flex',borderTop:'1px solid #dddd'}}>
        <div onClick={onEdit}style={{flex:1,textAlign:'center',borderRight:'1px solid #ddd'}}>
          <a >Edit Reps</a>
        </div>
        <div onClick={onView}style={{flex:1,textAlign:'center'}}>
          <a >View Drill</a>
        </div>
      </div>
    </div>
  )
}

export default EditDrillCard
