import { DrillActionTypes } from '../../utils/enums'

//update categories
export const updateCategories = (categories) =>{
  return {
    type:DrillActionTypes.UPDATE_CATEGORIES,
    payload:categories
  }
}

//update difficulty
export const updateDifficulty = (difficulty) =>{
  return {
    type:DrillActionTypes.UPDATE_DIFFICULTY,
    payload:difficulty
  }
}

//update Equipment
export const updateEquipment = (equipment) =>{
  return {
    type:DrillActionTypes.UPDATE_EQUIPMENT,
    payload:equipment
  }
}

//update space
export const updateSpace = (space) =>{
  return {
    type:DrillActionTypes.UPDATE_SPACE,
    payload:space
  }
}
