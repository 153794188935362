import React,{useState,useEffect} from 'react'
import '../../sass/main.scss';
import Header from './header'
import PlayerSelect from '../messages/player-select'
import Input from '../messages/input'
import Button from '../common/button'
import { toast } from 'react-toastify';

const BulkMessage = ({onCancel,threads,onSend,onImage,onVideo}) =>{
  //==================================
  //STATE
  //==================================
  const [players,setPlayers] = useState([])
  const [message,setMessage] = useState('')
  //==================================
  //HANDLERS
  //==================================
  const handleChange = (thread) =>{
    if(players.includes(thread)){
      let index = players.indexOf(thread)
      let temp = [...players]
      temp.splice(index,1)
      setPlayers(temp)
    }else{
      setPlayers([...players,thread])
    }
  }

  const handleSend = () =>{
    if(players.length === 0){
      toast.error('Select at least one player')
    }else if(message){
      onSend(players,message)
    }
  }

  const onImageChange = (event) =>{
    if(players.length === 0){
      toast.error('Select at least one player')
    }else{
      console.log(true)
      onImage(players,event)
    }
  }

  const onVideoChange = (event) =>{
    if(players.length === 0){
      toast.error('Select at least one player')
    }else{
      onVideo(players,event)
    }
  }


  return(
    <div className='modal-medium'>
      <Header
        title={'Select Players'}
        onClick={onCancel}/>
      <div className='modal-content modal-content-scrollable'>
        <div style={{paddingTop:5,paddingBottom:10,textAlign:'right'}}>
          <Button
            className='btn btn-grey'
            text='Select all players'
            onClick={()=>setPlayers([...threads])}/>
        </div>
        {
          threads.map((thread,index)=>(
            <PlayerSelect
              selected={players.includes(thread)}
              player={thread.player}
              handleChange={()=>handleChange(thread)}/>
          ))
        }
      </div>
      <div className='modal-send'>
        <Input
          onImage={(event)=>onImageChange(event)}
          onVideo={(event)=>onVideoChange(event)}
          value={message}
          handleChange={(event) => setMessage(event.target.value)}
          onSend={handleSend}/>
      </div>
    </div>
  )
}

export default BulkMessage
