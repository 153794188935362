import React from 'react'
import '../../sass/main.scss'
import Avatar from 'react-avatar';


const RecentActivity = ({activity}) =>{
  const renderText = (a) =>{
    if(a.drill === 1){
      return `completed a ${a.category} drill.`
    }else if(a.session === 1){
      return `completed a session.`
    }
  }

  return(
    <div className='card card-leaderboards'>
      {
        activity.map((activity,index)=>(

            activity.drill === 1 || activity.session === 1
              ?<div style={{display:'flex',marginBottom:20}}>
                <Avatar
                  size={30}
                  round
                  src={activity.player.profile_pic}/>
                <p style={{marginLeft:5,alignSelf:'center'}}className='body1-black bold'>
                  {activity.player.name} {renderText(activity)}
                </p>
              </div>
              : null


        ))
      }
    </div>
  )
}

export default RecentActivity
