import React,{useEffect,useState} from 'react';
import '../sass/main.scss';
import { getDashboard } from '../services/coaches'
import StatCard from '../components/stats/stat-card'
import LeaderboardCard from '../components/stats/leaderboards-card'
import RecentActivity from '../components/stats/recent-activity'
import Spinner from '../components/common/spinner'

const Dashboard = ({}) =>{
  //=================================
  //STATE
  //=================================
  const [loading,setLoading] = useState(true)
  const [stats,setStats] = useState({
    duration:0,
    drills:0,
    sessions:0
  })
  const [durationLeaders,setDurationLeaders] = useState([])
  const [drillLeaders,setDrillLeaders] = useState([])
  const [sessionLeaders,setSessionLeaders] = useState([])
  const [activity,setActivity] = useState([])

  //=================================
  //HOOKS
  //=================================
  useEffect(()=>{
    setLoading(true)
    const fetchData = async()=>{
      const response = await getDashboard()
      const { stats,durationLeaders,drillLeaders,sessionLeaders,activity } = response
      setStats(stats)
      setDurationLeaders(durationLeaders)
      setDrillLeaders(drillLeaders)
      setSessionLeaders(sessionLeaders)
      setActivity(activity)
      setLoading(false)
      window.analytics.track('Stats');
    }
    fetchData()
  },[])

  return (
    <main className='section-container'>
      <div className='all-drills-header'>
        <h1 className='h1-black bold'>Stats</h1>
      </div>
      {
        !loading
          ?<section className='week-container'>
              <h3 className='h3-black bold u-margin-bottom-15'>Overall Stats</h3>
              <div className='grid-three u-margin-bottom-40'>
                <StatCard title={'Minutes'} value={Math.floor(stats.duration/60)}/>
                <StatCard title={'Drills'} value={stats.drills}/>
                <StatCard title={'Sessions'} value={stats.sessions}/>
              </div>
              <h3 className='h3-black bold u-margin-bottom-15'>Leaderboards</h3>
              <div className='grid-three u-margin-bottom-15'>
                <LeaderboardCard title={'Minutes Leaders'} users={durationLeaders}/>
                <LeaderboardCard title={'Drills Leaders'} users={drillLeaders}/>
                <LeaderboardCard title={'Sessions Leaders'} users={sessionLeaders}/>
              </div>
              <h3 className='h3-black bold u-margin-bottom-15'>Recent Activity</h3>
              <RecentActivity activity={activity}/>
            </section>
          :<div style={{marginTop:100}}><Spinner/></div>
      }

    </main>
  )
}

export default Dashboard;
