import React from 'react'
import '../../sass/main.scss'
import { FaSearch } from "react-icons/fa";

const SearchBar = ({handleChange,name,value,placeholder}) =>{
  return (
    <div className='search'>
      <div className='search-icon'>
        <FaSearch size={20} color={'#777'}/>
      </div>
      <input
        type={'text'}
        className={'search-bar'}
        placeholder={placeholder}
        onChange={handleChange}
        name={name}
        value={value}/>
    </div>
  )
}

export default SearchBar
