import React,{useState,useEffect,useRef} from 'react';
import '../sass/main.scss';
import { getContent,uploadImage,uploadVideo,deleteImages,deleteVideos, } from '../services/content'
import Header from '../components/media/header'
import NoItems from '../components/common/noitems'
import ContentCard from '../components/media/content-card'
import { useNavigate } from 'react-router-dom'
import Modal from '../components/modals/modal'
import ImageModal from '../components/modals/image'
import VideoModal from '../components/modals/video'
import Spinner from '../components/common/spinner'
import DeleteModal from '../components/modals/delete'
import { toast } from 'react-toastify';
const LIMIT = 100

const Media = ({}) =>{
  //====================================
  // STATE
  //====================================
  const inputFile = useRef(null)
  const [loading,setLoading] = useState(true)
  const [content,setContent] = useState([])
  const [tab,setTab] = useState(0)
  const [skip,setSkip] = useState(0)
  const [selected,setSelected] = useState([])
  const [imageModalVisible,setImageModalVisible] = useState(false)
  const [videoModalVisible,setVideoModalVisible] = useState(false)
  const [deleteModalVisible,setDeleteModalVisible] = useState(false)
  const [currentImage,setCurrentImage] = useState('')
  const [currentVideo,setCurrentVideo] = useState('')

  //====================================
  // HOOKS
  //====================================
  const navigate = useNavigate()
  useEffect(()=>{
    setLoading(true)
    const fetchData = async() =>{
      window.analytics.track('Media');
      const response = await getContent(skip,tab === 0 ? 'image' : 'video')
      setContent(response)
      setLoading(false)
    }
    fetchData()
  },[tab])


  const onMediaChange = async(event) =>{
    const file = event.target.files[0]
    const data = new FormData()
    data.append('file',file)
    if(tab === 0){
      await uploadImage(data)
    }else if (tab === 1){
      await uploadVideo(data)
    }
  }

  const onSelect = (content) =>{
    const index = selected.indexOf(content)
    if(index === -1){
      setSelected([...selected,content])
    }else{
      let temp = [...selected]
      temp.splice(index,1)
      setSelected(temp)
    }
  }

  const openImage = (item) =>{
    setCurrentImage(item.url)
    setImageModalVisible(true)
  }

  const openVideo = (item) =>{
    setCurrentVideo(item.url)
    setVideoModalVisible(true)
  }

  const onDelete = async() =>{
    let response
    let type = ''
    if(tab === 0){
      response = await deleteImages({content:selected})
      type = 'image'
    }else if(tab === 1){
      response = await deleteVideos({content:selected})
      type = 'video'
    }

    if(response.success){
      toast.success(`Successfully deleted ${type}s.`)
      removeContent()
    }else{
      toast.error(`Can not delete an ${type} that is being used by a drill.`)
    }
  }

  const removeContent = () =>{
    let temp = [...content]
    let index
    selected.map((item)=>{
      index = temp.indexOf(item)
      if(index > -1){
        temp.splice(index,1)
      }
    })
    setContent(temp)
    setSelected([])
  }

  const onSelectTab = (tab) =>{
    setTab(tab)
    setSelected([])
  }

  return (
    <main className='section-container'>
      <Header
        selected={tab}
        onSelect={(tab)=> onSelectTab(tab)}
        onClick={()=>inputFile.current.click()}
        canDelete={selected.length > 0 ? true : false}
        onDelete={()=>setDeleteModalVisible(true)}/>
      <section className='week-container'>
        {
          !loading
           ?content.length > 0
             ? <div className='card u-padding-20 week-container u-margin-top-20 grid-seven' style={{gap:0}}>
               {
                 content.map((item,index)=>(
                   <ContentCard
                     onClick={()=>tab === 0 ? openImage(item) : openVideo(item)}
                     onSelect={()=>onSelect(item)}
                     selected={selected.includes(item)}
                     url={item.type === 'image' ? item.url : item.url.replace('.mp4','.jpg')}
                    />
                 ))
               }
              </div>
             :<NoItems
                 title={'Upload your first media item'}
                 subtitle={'Click the button below to get started'}
                 button={tab === 0 ? 'Upload Image' : 'Upload Video'}
                 onClick={()=>inputFile.current.click()}/>
          :<div style={{marginTop:100}}><Spinner/></div>
        }
      </section>
      <input
        accept={tab === 0 ? "image/*" : "video/*"}
        onInput={onMediaChange}
        type='file'
        name={'content'}
        ref={inputFile}
        style={{display: 'none'}}/>
      <Modal visible={imageModalVisible}>
        <ImageModal
          onCancel={()=>setImageModalVisible(false)}
          url={currentImage}/>
      </Modal>
      <Modal visible={videoModalVisible}>
        <VideoModal
          onCancel={()=>setVideoModalVisible(false)}
          url={currentVideo}/>
      </Modal>
      <Modal visible={deleteModalVisible}>
        <DeleteModal
          title={tab === 0 ? 'Delete Images' : 'Delete Videos'}
          text={'Are you sure you want to delete these items? They will be permanetly deleted.'}
          onDelete={onDelete}
          onCancel={()=>setDeleteModalVisible(false)}/>
      </Modal>
    </main>
  )
}

export default Media
