import React,{useEffect,useState} from 'react';
import '../../sass/main.scss';
import Nav from '../../components/players/navbar'
import TabBar from '../../components/players/tab-bar'
import Spinner from '../../components/common/spinner'
import { getPlayersStats,getPlayersSchedule,updateSchedule } from '../../services/players'
import { getSessions } from '../../services/sessions'
import { getPrograms } from '../../services/programs'
import { useNavigate,useParams } from 'react-router-dom'
import Stats from './stats'
import Info from './info'
import Schedule from './schedule'


const Player = ({}) =>{
  //====================================
  //STATE
  //====================================
  const [loading,setLoading] = useState(false)
  const [tab,setTab] = useState(0)
  const [player,setPlayer] = useState({_id:null,name:'',profile_pic:''})
  const [schedule,setSchedule] = useState([])
  const [prevSchedule,setPrevSchedule] = useState([])
  const [subscription,setSubscription] = useState({})
  const [stats,setStats] = useState({duration:0,drills:0,sessions:0})
  const [foundSessions,setFoundSessions] = useState([])
  const [foundPrograms,setFoundPrograms] = useState([])
  const [coach,setCoach] = useState(null)
  //====================================
  //HOOKS
  //====================================
  const navigate = useNavigate()
  const {playerId,status} = useParams()

  useEffect(()=>{
    const fetchData = async() =>{
      setLoading(true)
      const response = await getPlayersStats(playerId)
      setPrevSchedule(response.schedules)
      setPlayer(response.player)
      setStats(response.stats)
      setSubscription(response.subscription)

      const scheduleResponse = await getPlayersSchedule(playerId)
      const sessionsResponse = await getSessions(0)
      const programsResponse = await getPrograms()
      setSchedule(scheduleResponse.schedules)
      setFoundSessions(sessionsResponse)
      setFoundPrograms(programsResponse)

      const coach = localStorage.getItem('id')
      setCoach(coach)
      setLoading(false)
      window.analytics.track('Player');
    }
    fetchData()
  },[])
  //====================================
  //HANDLERS
  //====================================

  const onSubmit = async() =>{
    await updateSchedule(schedule)
    window.analytics.track('Edit Schedule');
  }

  return (
    <main className='section-container'>
      {
        !loading
          ?<section>
            <Nav
              tab={tab}
              onTabChange={(tab)=>setTab(tab)}
              noAlert
              onBack={'/players/'}
              status={status}
              profilePic={player.profile_pic}
              title={player.name}/>
            {
              tab === 0
                &&
                <Schedule
                  schedule={schedule}
                  foundSessions={foundSessions}
                  foundPrograms={foundPrograms}
                  setSchedule={(array) => setSchedule(array)}
                  playerId={playerId}
                  coach={coach}
                  onSubmit={onSubmit}
                  />
            }
            {
              tab === 1
                &&
                <Stats
                  player={player}
                  stats={stats}
                  schedule={prevSchedule}/>
            }
            {
              tab === 2 && <Info subscription={subscription}/>
            }
           </section>
          :<section style={{marginTop:300}}><Spinner/></section>
      }
    </main>
  )
}

export default Player
