import React,{useEffect,useState} from 'react';
import '../../sass/main.scss';
import DrillForm from '../../forms/drill'
import { useNavigate,useParams } from 'react-router-dom'
import { getDrill,editDrill,deleteDrill} from '../../services/drills'

const EditDrill = ({}) =>{
  //====================================
  //STATE
  //====================================
  const [state,setState] = useState({
    title:'',
    category:'',
    difficulty:'',
    equipment:[],
    space:'',
    single:false,
    timed:false,
    url:'',
    diagram:'',
    instructions:[""],
    setUpInstructions:[""]
  })
  const [loading,setLoading] = useState(true)

  //====================================
  //HOOKS
  //====================================
  const { drillId } = useParams()
  const navigate = useNavigate()

  useEffect(()=>{
    const fetchData = async() =>{
      const { drill } = await getDrill(drillId)
      let newState = {
        ...drill,
        space:getSpace(drill.space),
        single:drill.single ? "Yes" : "No",
        timed:drill.timed ? "Yes" : "No"
      }
      setState(newState)
      setLoading(false)
    }
    fetchData()
  },[])

  useEffect(() => {
   const warningText ='You have unsaved changes - are you sure you wish to leave this page?';
   const handleWindowClose = (e: BeforeUnloadEvent) => {
     e.preventDefault();
     return (e.returnValue = warningText);
   };
   window.addEventListener('beforeunload', handleWindowClose);
   return () => {
     window.removeEventListener('beforeunload', handleWindowClose);
   };
 }, []);
 //====================================
 //HANDLERS
 //====================================
  const onSubmit = async(drill) =>{
    await editDrill(drillId,drill)
    navigate('/drills')
    window.analytics.track('Edited Drill');
  }

  const getSpace = (space) =>{
    if(space === 2){
      return "Garage / Inside House / Small Space"
    }else if(space === 1){
      return "Backyard / Medium Space"
    }else {
      return "Field / Park / Large Space"
    }
  }

  const onDelete = async() =>{
    await deleteDrill(drillId)
    navigate('/drills')
  }


  const { title,category,difficulty,equipment,space,single,timed,url,instructions,setUpInstructions,diagram} = state

  return (
    <main className='section-container'>
      {
        !loading
          &&
          <DrillForm
            onSubmit={(drill)=>onSubmit(drill)}
            onDelete={onDelete}
            _title={title}
            _category={category}
            _difficulty={difficulty}
            _equipment={equipment}
            _space={space}
            _single={single}
            _timed={timed}
            _position={null}
            _url={url}
            _instructions={instructions}
            _setUpInstructions={setUpInstructions}
            _diagram={diagram}
            />
      }
    </main>
  )
}

export default EditDrill
