import React from 'react'
import '../../sass/main.scss'
import Avatar from 'react-avatar'

const PlayerSelected = ({selected,handleChange,player}) =>{
  return(
    <div className='pointer' onClick={handleChange} style={{display:'flex',paddingTop:10,paddingBottom:10}}>
      <Avatar
        src={player.profile_pic}
        round
        size={40}/>
      <div style={{alignSelf:'center',marginLeft:5}}>
        <p className='h3-black bold'>{player.name}</p>
      </div>
      <div style={{flex:1,textAlign:'right',alignSelf:'center'}}>
        <input
            className='checkbox-input'
            type="checkbox"
            checked={selected}
            onChange={handleChange}
          />
      </div>
    </div>
  )
}

export default PlayerSelected
