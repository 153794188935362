import React from 'react'
import '../../sass/main.scss'

const FormSmallInput = ({label,type,handleChange,name,value,placeholder,required,error,maxlength,tab}) =>{
  return (
    <div className='group u-margin-bottom-5'>
      {
        label && <label className='body1-grey bold u-margin-bottom-5'>{label}</label>
      }
      <div className='input-tab'>
      <input
        type={type}
        className={error ? 'form-small-input form-error' : 'form-small-input'}
        placeholder={placeholder}
        onChange={handleChange}
        name={name}
        maxLength={maxlength}
        value={value}
        required={required}/>
      {
        tab && <span className='form-input-tab'>{tab}</span>
      }
      </div>
      {error && <p>{error}</p>}
    </div>
  )
}

export default FormSmallInput
