import React from 'react'
import '../../sass/main.scss'
import Select from 'react-select'

const FormSelect = ({label,options,className,value,onValueChange}) =>{
  return (
    <div className={className ? className : 'group'}>
      {
        label && <label className='h3-black bold u-margin-bottom-15'>{label}</label>
      }
      <Select
        styles={customStyles}
        value={value}
        onChange={onValueChange}
        options={options} />
    </div>
  )
}

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    fontSize:16,
    borderRadius:10,
    border:'1px solid #CACACA',
    padding:5
  }),

  option: (provided, state) => ({
    ...provided,
    fontSize:16,
  }),
}

export default FormSelect
