import React from 'react'
import '../../sass/main.scss'
import Avatar from 'react-avatar';

const LeaderboardsCard = ({title,value,users}) =>{
  return(
    <div className='card card-leaderboards'>
      <h3 className='h3-black bold u-margin-bottom-15 u-margin-right-5'>{title}</h3>
      {
        users.map((user,index)=>(
          <div style={{display:'flex',marginBottom:10}}>
            <Avatar round size="30" src={user.profile_pic} />
            <h3 style={{alignSelf:'center',marginLeft:5}}>{user.name}</h3>
            <div style={{textAlign:'right',flex:1}}>
              <h1>{title === 'Minutes Leaders' ? Math.floor(user.count/60) : user.count}</h1>
            </div>
          </div>
        ))
      }

    </div>
  )
}

export default LeaderboardsCard
